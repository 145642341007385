@import '~normalize.css';
@import 'mixins';
@import 'font';

:root {
    --view-port-height: 100%;
    --content-margin-top: 0;
    --bottom-bar-height: 0;
    --calc-modal-title-height: 0;
    --header-search-mobile-height: 0;
    --header-height: 0;
    --header-height-adaptive: 0;
    --checkout-header-height: 0;
    --checkout-tab-bar-height: 0;
    --header-color: #1c1c1c;
    --header-logo-color: #221f1f;
    --header-hover-color: #a58e7d;
    --header-search-color: #6f6f6f;
    --header-search-border-color: #e1e1e1;
    --header-search-hover-border-color: rgb(#a58e7d 50%);
    --header-search-filled-border-color: #e1e1e1;
    --header-search-close-button-color: #1c1c1c;
    --header-search-text-color: #1c1c1c;
    --header-search-mobile-background: #fff;
    --header-search-mobile-color: #1c1c1c;
    --header-count-background: #a58e7d;
    --header-count-color: #fff;
    --header-border-color: rgb(28 28 28 / 10%);
    --slider-navigation-color: #0b1742;
    --slider-active-color: #fff;
    --p-xxxxs-2: 2px;
    --p-xxxs: 4px;
    --p-xxxs-4-2: 4px;
    --p-xxs: 8px;
    --p-xxs-8-4: 8px;
    --p-xs: 16px;
    --p-xs-12-8: 12px;
    --p-xs-16: 16px;
    --p-sm: 24px;
    --p-sm-24: 24px;
    --p-md: 32px;
    --p-md-32: 32px;
    --p-md-40-16: 40px;
    --p-lg: 48px;
    --p-xl: 64px;
    --p-xxl: 80px;
    --p-xxxl: 128px;
    --r-xxs: 2px;
    --r-xs: 4px;
    --r-sm: 8px;
    --r-lg: 24px;
    --r-md: 16px;
    --r-xl: 32px;

    @media #{$screen-mobile} {
        --p-xxxxs-2: 2px;
        --p-xxxs: 4px;
        --p-xxxs-4-2: 2px;
        --p-xxs: 8px;
        --p-xxs-8-4: 4px;
        --p-xs: 8px;
        --p-xs-12-8: 8px;
        --p-xs-16: 16px;
        --p-sm: 16px;
        --p-sm-24: 24px;
        --p-md: 24px;
        --p-md-32: 32px;
        --p-md-40-16: 16px;
        --p-lg: 32px;
        --p-xl: 48px;
        --p-xxl: 64px;
        --p-xxxl: 80px;
    }
}

.slot {
    display: contents;
}

[data-skeleton-wrapper] {
    pointer-events: none;
    user-select: none;

    [data-skeleton-item] {
        @include skeleton-style-item;
    }

    [data-skeleton] {
        @include skeleton;
    }
}

[data-skeleton-item-fadein-effect='true'] {
    [data-skeleton-item] {
        @include skeleton-style-before-animated;
    }
}

* {
    box-sizing: border-box;
    touch-action: pan-x pan-y;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

html {
    scroll-padding-top: var(--header-height);
}

html,
body,
#root {
    height: 100%;
    margin: 0;
    width: 100%;
}

body {
    @include scrollbar;

    color: $c-default-gray-100;
    overflow-x: hidden;
}

.scroll-disabled {
    overflow-y: scroll;
    position: fixed;
}

.scroll-disabled-fixed {
    inline-size: 100%;
    overflow-y: scroll;
    position: fixed;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: unset;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
    margin: 0;
    padding: 0;
}

p {
    @include f_p;
}

a {
    color: $c-primary-100;
    cursor: pointer;
    text-decoration: none;
}

img {
    display: inline-block;
    font-size: 0;
    line-height: 0;
    max-width: 100%;
}

label {
    cursor: pointer;
    display: block;
    margin: 0;
}

input,
textarea {
    @include prop(-webkit-appearance, none);
}

button {
    background-color: unset;
    border: none;
    padding: 0;
}

/* stylelint-disable */
/* Убирает кнопку очистки инпуста с type=search */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Убирает кнопку с глазом у инпуста с type=password в EDGE */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0
}

/* stylelint-enable */