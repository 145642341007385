@import 'src/styles/mixins';

.root {
    background-color: $c-default-gray-10;
    border-radius: var(--r-lg);
    overflow: hidden;
    width: 100%;

    &.isShouldPay {
        border: 1px solid $c-status-error-20;
    }

    &.isPointerEventsNone {
        pointer-events: none;
    }
}

.content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 var(--p-sm) var(--p-md);
    position: relative;
    width: 100%;

    @media #{$screen-mobile} {
        align-items: flex-start;
        flex-direction: column;
    }
}

.paymentStatusDesktop {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: var(--p-xxxs);

    @media #{$screen-mobile} {
        display: none;
    }
}

.paymentStatusMobile {
    @include show-mobile-hide-desktop(flex);

    border-top: 1px solid $c-background-footer;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 16px;
    width: 100%;

    .paymentStatusInner {
        align-items: center;
        display: flex;
        justify-content: space-between;
        row-gap: 4px;
    }

    .paymentStatusAction {
        margin-top: 16px;
    }
}
