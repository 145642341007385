@import 'src/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    overflow: auto;
    padding: var(--p-xxs) calc(var(--p-md) - 4px) var(--p-xxs) var(--p-md);

    @media #{$screen-mobile} {
        flex-grow: 1;
        margin-right: 2px;
        padding: var(--p-xs) calc(var(--p-sm) - 2px) var(--p-xs) var(--p-sm);
    }
}
