@import 'src/styles/mixins';

.root {
    &:global(.segmented-radio-component) {
        align-items: center;
        background-color: $c-default-gray-10;
        border-radius: var(--r-md);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: var(--p-sm) var(--p-xs-16);
        transition: background-color $animation, outline $animation,;
    }

    &:global(.segmented-radio-component.checked) {
        background-color: $c-primary-5;
        outline: 1px solid $c-secondary-100;
    }

    &:global(.segmented-radio-component.error) {
        background-color: $c-default-gray-10;
        outline: 1px solid $c-status-error-50;
    }

    &.checked:hover,
    &.error:hover {
        background-color: $c-default-white-100;
    }

    &:global(.segmented-radio-component.disabled) {
        cursor: auto;
    }

    & :global(.radio-component > label) {
        outline: none;
    }

    @media #{$screen-mobile} {
        align-items: flex-start;
    }
}
