@import 'src/styles/mixins';

.root {
    border-bottom: 1px solid $c-secondary-30;
    display: flex;
    flex-direction: column;
    gap: var(--p-sm);
    padding-bottom: var(--p-sm);
}

.list {
    display: grid;
    gap: var(--p-xs-16);
    grid-template-columns: repeat(4, 1fr);
}

.item {
    aspect-ratio: 1;
    border: 1px solid $c-secondary-15;
    border-radius: var(--r-md);
    overflow: hidden;

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

.button {
    background-color: $c-default-white-100;
    border: 1px solid $c-secondary-50;

    button {
        cursor: pointer;
        height: 100%;
        width: 100%;
    }
}
