@import 'src/styles/mixins';

.root {
    height: 100%;
    position: relative;
    width: 100%;
}

.img {
    height: inherit;
    width: inherit;
}

.play {
    @include fluid-prop-sm(width, 70px, 100px);
    @include fluid-prop-sm(height, 70px, 100px);

    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
