@import 'src/styles/mixins';

.root {
    align-items: center;
    display: flex;
    flex: 1 0 auto;

    @include fluid-prop-sm(gap, 4px, 8px);

    svg {
        @include fluid-prop-square-sizes-sm(16px, 20px);
    }
}
