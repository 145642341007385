@import 'src/styles/mixins';

.chevron {
    color: $c-default-gray-100;
    flex-shrink: 0;
    height: 24px;
    margin-left: auto;
    transition: all $animation;
    width: 24px;

    @media #{$screen-mobile} {
        color: $c-default-gray-60;
        height: 20px;
        width: 20px;
    }
}
