@import 'src/styles/mixins';

.root {
    align-items: center;
    color: var(--header-color);
    cursor: pointer;
    display: flex;
    transition: color $animation;

    svg {
        height: 20px;
        width: 20px;
    }

    &:hover {
        color: var(--header-hover-color);
    }
}
