@import 'src/styles/mixins';
@import 'src/styles/font';

.root {
    border-bottom: 1px solid $c-secondary-15;
    padding-bottom: var(--p-sm);
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.switcher {
    @include f_button-s;

    cursor: pointer;
}

.form {
    padding-top: 8px;
    position: relative;
}

.input label {
    padding-right: 165px;

    @media #{$screen-mobile} {
        padding-right: 136px;
    }
}

.submitButton {
    position: absolute;
    right: 10px;
    top: 18px;
    z-index: 2;

    @media #{$screen-mobile} {
        right: 4px;
        top: 12px;
    }
}
