@import 'prop';

@mixin ellipsis($count-lines) {
    @if $count-lines == 1 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @if $count-lines > 1 {
        overflow: hidden;

        @include prop(-webkit-box-orient, vertical);
        @include prop(-webkit-line-clamp, $count-lines);
        @include prop(display, -webkit-box);
    }
}
