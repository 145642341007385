@import 'src/styles/mixins';

.root {
    border-radius: var(--r-xl);
    color: $c-primary-100;
    display: inline-block;
    flex-shrink: 0;
    padding: var(--p-xxs) var(--p-xs-16);
}

.processedBg {
    background-color: $c-status-info-50;
}

.completedBg {
    background-color: $c-status-success-50;
    color: $c-default-white-100;
}

.cancelledBg {
    background-color: $c-status-error-50;
    color: $c-default-white-100;
}

.assemblyBg {
    background-color: $c-status-info-20;
}

.deliveryBg {
    background-color: $c-status-warning-50;
}

.readyForPickupBg {
    background-color: $c-status-success-20;
}

.defaultBg {
    background-color: $c-status-warning-20;
}
