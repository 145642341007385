@import 'src/styles/mixins';

.root {
    margin-bottom: 32px;

    @media #{$screen-mobile} {
        margin-bottom: 24px;
    }

    &.withContainer {
        @include container;
    }
}

.backButton {
    margin-bottom: var(--p-xs-16);
}

.title {
    @include ellipsis(2);

    @media #{$screen-mobile} {
        @include ellipsis(3);
    }
}
