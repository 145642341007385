@import 'src/styles/mixins';

.notification {
    @media #{$screen-mobile} {
        bottom: 90px;
        left: 16px;
        width: calc(100% - 32px);
    }

    &.Toastify__toast {
        border-radius: var(--r-md);
        box-shadow: 0 2px 2px 0 $c-shadow-tooltip;
        cursor: auto;
        min-height: auto;
        padding: 0;

        .Toastify__toast-body {
            padding: 0;
        }
    }

    &.Toastify__toast-body {
        padding: 0;
    }
}

.Toastify__toast-container {
    max-width: 420px;
    min-width: 384px;
}
