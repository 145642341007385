@import 'src/styles/mixins';

.filterCollapse {
    margin-top: 18px;
    text-align: center;
    width: 100%;

    @media #{$screen-mobile} {
        margin-top: 26px;
    }
}

.filterCollapseCheckbox {
    display: none;
}

.filterCollapseCheckbox:checked ~ .filterCollapseLabel {
    display: none;
}

.filterCollapseLabel {
    align-items: center;
    cursor: pointer;
    display: flex;
}

.filterCollapseText {
    color: $c-secondary-100;
    margin-right: 8px;
    transition: all $animation;
}

.filterStyleCloseIcon {
    line-height: 0;
    transform: rotate(180deg);
}

.filterCollapseBtnClose {
    bottom: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.icon {
    color: $c-secondary-100;
    height: 20px;
    transition: all $animation;
    width: 20px;
}
