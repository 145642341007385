@import 'src/styles/mixins';
@import 'src/styles/font';

.sidebar {
    display: flex;
    flex-direction: column;
    gap: var(--p-lg);
    width: 100%;

    @media #{$screen-mobile} {
        margin-bottom: 16px;
        max-width: none;
    }
}

.mainContent {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-16);
}

.desktopActions {
    @include hide-mobile-show-desktop;
}

.additionalContent {
    display: flex;
    flex-direction: column;
    gap: var(--p-md);
}

.availability {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs);
}

.availabilityInfo {
    color: $c-default-gray-60;
    display: flex;
    gap: var(--p-xxs);

    svg {
        height: 24px;
        width: 24px;
    }
}

.actionsAdaptiveContainer {
    background-color: $c-default-white-100;
    bottom: var(--bottom-bar-height);
    box-shadow: 0 -2px 10px 0 $c-shadow-tabbar;
    display: block;
    left: 0;
    position: fixed;
    transition: bottom $animation, box-shadow $animation;
    width: 100%;
    z-index: 4;
}
