@import 'src/styles/mixins';

.alphabet {
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;
    overflow: auto;
    transition: background-color $animation;

    @media #{$screen-tablet} {
        margin: 0;
        padding: 0;
    }

    @media #{$screen-desktop} {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.alphabetItem {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 28px;
    min-width: 28px;
    padding: 2px;
}

.alphabetLetter {
    transition: color $animation;

    &:hover {
        color: $c-default-gray-60;
    }
}

.active {
    background-color: $c-primary-10;
}
