@import 'src/styles/mixins';
@import 'src/styles/font';

.visualProductCard {
    cursor: pointer;
    position: relative;
    width: 282px;

    @media #{$screen-mobile} {
        width: 100%;
    }

    @media #{$screen-xl} {
        width: 322px;
    }

    .visualProductCardShieldList {
        margin-top: 8px;
    }

    .visualProductCardFocusFrame {
        display: none;
    }

    &:hover {
        z-index: 4;

        .visualProductCardFrame {
            display: block;

            @media #{$screen-mobile} {
                display: none;
            }

            .visualProductCardActions {
                bottom: 16px;
                display: flex;
                left: 16px;
                position: absolute;
                width: calc(100% - 32px);
            }
        }
    }
}

[data-skeleton-wrapper] {
    .visualProductCardImageList {
        @media #{$screen-xl} {
            height: 322px;

            &[data-skeleton-item] {
                min-height: 322px;
            }
        }

        &[data-skeleton-item] {
            min-height: 282px;
        }
    }
}

.visualProductCardImageList {
    height: 282px;
    width: 100%;

    @media #{$screen-mobile} {
        height: auto;
    }

    @media #{$screen-xl} {
        height: 322px;
    }
}

.visualProductCardInfoContainer {
    display: flex;
    flex-direction: column;

    @include fluid-prop-sm(margin-top, 8px, 12px);
}

.visualProductCardArticle {
    color: $c-default-gray-60;
    margin-bottom: 4px;
}

.visualProductCardTitle {
    min-height: 48px;
    transition: color $animation-in-out;
    word-break: break-all;

    @include ellipsis(2);

    @media #{$screen-mobile} {
        min-height: auto;
    }

    &:hover {
        color: $c-primary-50;
    }
}

.visualProductCardPriceContainer {
    @include fluid-prop-margin-top-bottom-sm(8px, 12px);
}

.visualProductCardAvailability {
    margin-top: auto;
}

.visualProductCardActions {
    display: none;
    margin-top: 10px;

    @media #{$screen-mobile} {
        display: flex;
        margin-top: 0;
    }

    button {
        @include f_button-l;
    }
}

.visualProductCardFrame {
    background-color: $c-default-white-100;
    border-radius: 4px;
    box-shadow: 0 2px 25px rgb(83 113 149 / 30%);
    display: none;
    height: calc(100% + 24px * 4);
    left: -16px;
    position: absolute;
    top: -20px;
    width: calc(100% + 16px * 2);
    z-index: 1;
}

.visualProductCardFrameContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    z-index: 2;

    a > {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.visualProductSubCategoryContainer {
    min-height: 28px;

    @media #{$screen-mobile} {
        display: none;
    }
}

.visualProductSubCategory {
    align-self: flex-start;
    flex-shrink: 0;
    margin-top: 4px;

    @media #{$screen-mobile} {
        display: none;
    }
}
