@import 'src/styles/mixins';

.fixed {
    background-color: $c-background-page;
    box-shadow: 0 0 0 0 $c-shadow-header;
    left: 0;
    position: fixed;
    top: 0;
    transition: box-shadow $animation;
    width: 100%;
    z-index: 6;

    &.isNeedShadow {
        &.isNotAtTop {
            box-shadow: 0 2px 4px 0 $c-shadow-header;
        }
    }

    @media #{$screen-laptop} {
        display: none;
    }

    &.hideHeaderOnMobile {
        display: none;
    }
}

.header {
    @include container;

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    width: 100%;

    &.dark {
        --header-color: #fff;
        --header-logo-color: #fff;
        --header-search-mobile-background: #f0f3f5;
        --header-search-mobile-color: #0b1742;
        --header-border-color: rgb(255 255 255 / 10%);

        &.isNotAtTop {
            --header-color: #1c1c1c;
            --header-search-mobile-background: #0b1742;
            --header-logo-color: #0b1742;
            --header-search-mobile-color: #fff;
            --header-border-color: rgb(28 28 28 / 10%);
        }

        & .logo {
            svg {
                color: var(--header-color);
            }
        }
    }

    .logo,
    .search {
        cursor: pointer;
        display: flex;
    }

    .search {
        align-items: center;
        height: 24px;
        justify-content: center;
        width: 24px;
    }

    .logo svg {
        color: var(--header-logo-color);
        height: 23px;
        transition: color $animation;
        width: auto;
    }

    .search svg {
        color: var(--header-search-mobile-color);
        height: 24px;
        transition: color $animation;
        width: 24px;
    }
}

.bottomHeaderContainer {
    background-color: $c-background-header;
}

.bottomHeader {
    @include container;

    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
}
