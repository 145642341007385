@import 'src/styles/mixins';

.root {
    &:global(.segmented-radio-component) {
        @include fluid-prop-sm(padding, 12px, 16px);

        align-items: flex-start;
        background-color: $c-default-gray-10;
        border-radius: var(--r-xl);
        display: flex;
        flex-direction: column;
        gap: var(--r-xxxs);
        justify-content: flex-start;
        transition: background-color $animation, outline $animation,;
    }

    &:global(.segmented-radio-component.checked) {
        background-color: $c-primary-5;
        outline: 1px solid $c-secondary-100;
    }

    &:global(.segmented-radio-component.error) {
        background-color: $c-default-gray-10;
        outline: 1px solid $c-status-error-50;
    }

    &.checked:hover,
    &.error:hover {
        background-color: $c-default-white-100;
    }

    &:global(.segmented-radio-component.disabled) {
        cursor: auto;
    }

    & :global(.radio-component > label) {
        outline: none;
    }
}

.note {
    flex-shrink: 0;
    padding-left: 32px;
    width: 100%;
}
