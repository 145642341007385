@import 'src/styles/mixins';

.root {
    border-bottom: 1px solid $c-additional-graphics-elements-divider;
    display: flex;
    gap: var(--p-md);
    justify-content: stretch;
    padding: 16px 0;
    position: relative;

    @media #{$screen-mobile} {
        flex-direction: column;
    }
}

.mainContainer {
    display: flex;
    gap: var(--p-xs);
}

.imgBlock {
    @media #{$screen-mobile} {
        height: 64px;
        width: 64px;
    }
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.shieldsList {
    gap: 4px;
}

.mainInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 255px;

    @media #{$screen-mobile} {
        max-width: 255px;
        width: auto;
    }
}

.title {
    @include ellipsis(2);
}

.price {
    display: flex;
    gap: 2px;
}

.finalOffer {
    & :global(.price-n-bonuses-main) {
        flex-flow: nowrap;
        flex-flow: row-reverse;
        gap: 8px;
    }
}

.actionsWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-end;
    margin-top: 4.5px;
    width: 100%;
}

.actions {
    align-items: flex-end;
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 1;
    gap: 24px;
    justify-content: space-between;

    @media #{$screen-mobile} {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        gap: 6px;
        justify-content: space-between;
    }

    & :global(.counter-component) {
        max-width: 120px;
    }
}

.controls {
    display: flex;
    gap: var(--p-xs);
    position: absolute;
    right: 0;
    top: 0;

    @media #{$screen-mobile} {
        flex-direction: column;
        margin-left: auto;
        position: static;
    }
}
