@import 'src/styles/mixins';
@import 'src/styles/font';

.label {
    display: inline-block;
    position: relative;
    width: 100%;

    .labelCross {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity $animation-in-out;

        svg {
            color: $c-default-gray-70;
            display: block;
            height: 24px;
            transition: color $animation-in-out;
            width: 24px;

            &:hover {
                color: $c-default-gray-70;
            }
        }
    }
}

.searchIcon {
    align-items: center;
    color: $c-default-gray-100;
    cursor: auto;
    display: flex;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;

    svg {
        height: 24px;
        width: 24px;
    }
}

.input {
    appearance: none;
    background-color: $c-default-white-100;
    border: none;
    border: 1px solid $c-default-gray-30;
    color: $c-default-gray-100;
    outline: none;
    padding: var(--p-xxs) var(--p-xs-16) var(--p-xxs) var(--p-sm);
    transition: color $animation, background-color $animation;
    width: 100%;

    &.large {
        border-radius: var(--r-xl);
        height: 60px;
    }

    &.medium {
        border-radius: var(--r-lg);
        height: 44px;
    }

    @include f_p('lg');

    &::placeholder {
        color: $c-default-gray-60;
        transition: color $animation;
    }

    &::input-placeholder {
        color: $c-default-gray-60;
        transition: color $animation;
    }

    &:focus {
        &::placeholder {
            color: transparent;
        }

        &::input-placeholder {
            color: transparent;
        }
    }
}
