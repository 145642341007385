@import 'src/styles/mixins';

.root {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 24px;
    padding: var(--p-sm);
    position: relative;

    @media #{$screen-mobile} {
        margin-bottom: 16px;
    }

    &::before {
        background-color: $c-primary-10;
        bottom: 0;
        content: '';
        height: 1px;
        left: 20px;
        position: absolute;
        right: 20px;
    }

    @include fluid-prop-padding-left-right-sm(16px, 20px);
}

.leftSide {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
}

.leftSideTitle {
    align-items: center;
    display: flex;
    gap: 16px;

    @media #{$screen-mobile} {
        justify-content: space-between;
    }
}

.rightSide {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: 24px;
    text-align: right;

    @media #{$screen-mobile} {
        display: none;
    }
}

.rightSideInner {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.price {
    font-size: 24px;
}
