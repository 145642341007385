$max-container-width-lg: 1200px;
$max-container-width-xl: 1360px;
$padding-on-border: 16px;

@mixin margin-negative-container-mobile() {
    @media #{$screen-mobile} {
        margin-left: -$padding-on-border;
        margin-right: -$padding-on-border;
    }
}

@mixin padding-container-mobile() {
    @media #{$screen-mobile} {
        padding-left: $padding-on-border;
        padding-right: $padding-on-border;
    }
}

@mixin mobile-negative-margin-with-padding() {
    @include margin-negative-container-mobile;
    @include padding-container-mobile;
}

@mixin container($mode: 'default') {
    margin-left: auto;
    margin-right: auto;
    max-width: $max-container-width-lg;
    width: 100%;

    @if $mode != 'withoutMobilePadding' {
        @include padding-container-mobile;
    }

    @media #{$screen-xl} {
        max-width: $max-container-width-xl;
        padding-left: 0;
        padding-right: 0;
    }
}

.profile-page {
    border-radius: 4px;
    max-width: 710px;
    min-height: 400px;
    position: relative;
    width: 100%;

    @media #{$screen-mobile} {
        max-width: unset;
    }
}

/** https://blog.hubspot.com/website/hide-scrollbar-css */
@mixin hide-scrollbars() {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
}

@mixin aspect-ratio($width, $height) {
    height: 0;
    overflow: hidden;
    padding-top: ($height / $width) * 100%;
}

@mixin scrollbar() {
    scrollbar-color: $c-default-gray-40 $c-default-gray-20;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        background-color: $c-default-gray-20;
        border-radius: 4px;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $c-default-gray-40;
        border-radius: 4px;
    }
}
