@import 'src/styles/mixins';

.root {
    align-items: center;
    background-color: $c-status-error-20;
    display: flex;
    gap: 16px;
    padding: var(--p-xs) var(--p-sm);

    @media #{$screen-mobile} {
        flex-direction: column;
    }
}
