@import 'src/styles/mixins';

.areaContent {
    margin-top: 25px;
}

.areaSwitch {
    align-items: center;
    display: flex;
    gap: 16px;
}

.subHeader {
    flex-grow: 1;
}

.switcher {
    flex-shrink: 0;
}

.container {
    @include container;
}

.header {
    margin-bottom: 16px;

    @media #{$screen-mobile} {
        margin-bottom: 0;
    }
}

.brandsSliderContainer {
    @include fluid-prop-sm(margin-bottom, 48px, 56px);
}

.alphabetContainer {
    @include container('withoutMobilePadding');
}

.alphabet {
    @include padding-container-mobile;

    background: $c-default-white-100;
}

.alphabetSection {
    background: $c-default-white-100;
}

.stickyHeader {
    background: $c-default-white-100;
    padding-bottom: 16px;
    padding-top: 16px;
    position: sticky;
    top: var(--header-height, 1px);
    z-index: 5;

    @media #{$screen-mobile} {
        top: 0;
    }
}

.isNotAtTop {
    box-shadow: 0 2px 8px 0 $c-shadow-a4;
}
