@import 'src/styles/mixins';

.BackButton {
    cursor: pointer;
    display: flex;

    &.onlyAdaptive {
        display: none;

        @media #{$screen-mobile} {
            display: flex;
        }
    }

    &.general {
        color: $c-default-gray-100;
    }

    &.ghost {
        color: $c-default-gray-50;
    }

    &.onlyIcon {
        width: max-content;

        svg {
            height: 24px;
            width: 24px;
        }
    }
}
