@import 'src/styles/mixins';

.loader {
    animation: $animation-loader;
    border: 6px solid $c-default-black-100;
    border-radius: 50%;
    border-top-color: $c-default-white-100;
    height: 100px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
}

.compact {
    border: 4px solid;
    height: 20px;
    position: static;
    width: 20px;

    @media #{$screen-mobile} {
        border: 3px solid;
        height: 16px;
        width: 16px;
    }

    &.secondary {
        border-color: $c-default-gray-20;
        border-top-color: $c-default-gray-80;
    }
}

.logo {
    border-color: $c-default-gray-20;
    border-top-color: $c-additional-gray-blue-50;
    height: 30px;
    position: absolute;
    width: 30px;
}

.primary {
    border-color: $c-default-white-a10;
    border-top-color: $c-default-white-100;
}

.secondary {
    border-color: $c-default-white-a10;
    border-top-color: $c-primary-80;
}

.logoLoader {
    background-color: $c-default-white-100;
    height: 100%;
    left: 0;
    max-height: 100vh;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 6;

    &.loaded {
        background-color: $c-default-white-a10;
        transition: background-color $animation;

        &.transparent {
            background-color: $c-default-white-a50;
        }
    }

    &.transparent {
        background-color: $c-default-white-a50;
    }

    &.fullyTransparent {
        background-color: transparent;
    }

    &.disabled {
        display: none;
    }

    svg {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.logoLoaderFixed {
    position: fixed;
}
