$grid-columns: 12;
$grid-gap-sm: 16px;
$grid-gap: 24px;

$grid-offset-sm: 8px;
$grid-offset-lg: 12px;

$grid-offset-box-sm: 16px;
$grid-offset-box-md: 40px;
$grid-offset-box-lg: 40px;

$grid-width: 1200px;
$grid-width-xl: 1360px;
