@import 'src/styles/mixins';

.showMoreIcon {
    @media #{$screen-mobile} {
        align-self: flex-start;

        svg {
            height: 24px;
            width: 24px;
        }
    }
}
