@import 'src/styles/mixins';

.headerMenuElementsList {
    display: flex;
    gap: 12px;
}

.headerMenuElementsItem {
    position: relative;

    .link {
        color: var(--header-color);
        transition: all $animation;

        &:hover {
            color: var(--header-hover-color);
        }
    }

    svg {
        height: 24px;
        width: 24px;
    }
}

.text {
    display: none;
}

.headerUserMenuPopup,
.headerAuthPopup {
    background-color: $c-default-white-100;
    border-radius: var(--r-lg);
    box-shadow: 0 2px 25px 0 $c-shadow-large-tooltip;
    right: 0;
    top: 45px;
}

.headerUserMenuPopup {
    min-width: 306px;
    overflow: hidden;
    padding: var(--p-xs) 0;
}

.cartPopup {
    right: 0;
    top: 45px;
}

.cartPopupHidden {
    display: none;
}

.headerAuthPopup {
    max-width: 440px;
    padding: var(--p-xs) 24px;
}
