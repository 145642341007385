@import 'src/styles/mixins';

.galleryMainSlider {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    :global(.swiper-button-prev),
    :global(.swiper-button-next) {
        display: none;
    }

    :global(.swiper-slide) {
        grid-template-rows: 100%;
    }

    &:hover {
        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
            display: block;

            @media #{$screen-mobile} {
                display: none;
            }
        }

        :global(.swiper-button-disabled) {
            display: none;
        }
    }

    :global(.swiper-button-disabled) {
        display: none;
    }

    :global(.swiper-pagination) {
        display: none;
    }

    &.basic {
        width: 100%;

        @media #{$screen-mobile} {
            height: 100%;
            width: 360px;

            :global(.swiper-pagination) {
                bottom: 0;
                display: flex;
            }
        }

        :global(.swiper-wrapper) {
            height: 360px;
        }
    }

    &.technical {
        height: 300px;
        width: 100%;

        @media #{$screen-mobile} {
            height: 254px;
            width: 212px;

            :global(.swiper-pagination) {
                bottom: 32px;
                display: flex;
            }
        }
    }

    &.fullscreen {
        width: calc(100% + 32px);

        @media #{$screen-mobile} {
            margin: 0 -16px;
        }

        :global(.swiper-wrapper) {
            height: 100%;
        }
    }

    .grabCursor {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }
}
