@import 'adaptive';
@import 'grid';
@import 'prop';

$p-xxxs: 4px;
$p-xxxs-4-2: 4px;
$p-xxs: 8px;
$p-xxs-8-4: 8px;
$p-xs: 16px;
$p-xs-12-8: 12px;
$p-sm: 24px;
$p-md: 32px;
$p-md-40-16: 40px;
$p-lg: 48px;
$p-xl: 64px;
$p-xxl: 80px;
$p-xxxl: 128px;
$p-xxxs-m: 4px;
$p-xxxs-4-2-m: 2px;
$p-xxs-m: 8px;
$p-xxs-8-4-m: 4px;
$p-xs-m: 8px;
$p-xs-12-8-m: 8px;
$p-sm-m: 16px;
$p-md-m: 24px;
$p-md-40-16-m: 16px;
$p-lg-m: 32px;
$p-xl-m: 48px;
$p-xxl-m: 64px;
$p-xxxl-m: 80px;

@mixin fluid-prop($prop, $p-min, $w-min, $p-max, $w-max) {
    $p-k: (($p-max - $p-min) / ($w-max - $w-min));
    $p-b: $p-min - $p-k * $w-min;
    $p-x: $p-k * 100vw;

    $result: calc(#{$p-x} + #{$p-b});

    @media (min-width: $w-min) and (max-width: $w-max) {
        @include prop($prop, $result);
    }
}

@mixin fluid-prop-2x($prop, $p-1, $p-2, $s-1, $s-2) {
    @include fluid-prop($prop, $p-1, $s-1, $p-2, ($s-2 - 1px));
    @include prop($prop, $p-2);

    @media (max-width: ($s-1 - 1px)) {
        @include prop($prop, $p-1);
    }
}

@mixin fluid-prop-3x($prop, $p-1, $p-2, $p-3, $s-1, $s-2, $s-3) {
    @include fluid-prop($prop, $p-1, $s-1, $p-2, ($s-2 - 1px));
    @include fluid-prop($prop, $p-2, $s-2, $p-3, ($s-3 - 1px));

    @media (max-width: ($s-1 - 1px)) {
        @include prop($prop, $p-1);
    }
}

/* @mixin fluid-prop-sm($prop, $p-1, $p-2){
    @include fluid-prop-2x($prop, $p-1, $p-2, $size-sm-min, $size-md-min);
} */

@mixin fluid-prop-sm($prop, $p-1, $p-2) {
    @include fluid-prop-2x($prop, $p-1, $p-2, $size-sm-min, $size-lg-min);
}

@mixin fluid-prop-sm-md-lg($prop, $p-1, $p-2, $p-3) {
    @include fluid-prop-3x($prop, $p-1, $p-2, $p-3, $size-sm-min, $size-md-min, $size-lg-min);
}

@mixin fluid-prop-tablet-sm($prop, $p-1, $p-2) {
    @include fluid-prop-2x($prop, $p-1, $p-2, $size-sm-min, $size-tablet-sm-min);
}

@mixin fluid-prop-padding-top-bottom-sm($p-1, $p-2) {
    @include fluid-prop-sm(padding-top, $p-1, $p-2);
    @include fluid-prop-sm(padding-bottom, $p-1, $p-2);
}

@mixin fluid-prop-padding-left-right-sm($p-1, $p-2) {
    @include fluid-prop-sm(padding-left, $p-1, $p-2);
    @include fluid-prop-sm(padding-right, $p-1, $p-2);
}

@mixin fluid-prop-margin-top-bottom-sm($p-1, $p-2) {
    @include fluid-prop-sm(margin-top, $p-1, $p-2);
    @include fluid-prop-sm(margin-bottom, $p-1, $p-2);
}

@mixin fluid-prop-margin-left-right-sm($p-1, $p-2) {
    @include fluid-prop-sm(margin-left, $p-1, $p-2);
    @include fluid-prop-sm(margin-right, $p-1, $p-2);
}

@mixin fluid-prop-square-sizes-sm($p-1, $p-2) {
    @include fluid-prop-sm(width, $p-1, $p-2);
    @include fluid-prop-sm(height, $p-1, $p-2);
}

@mixin fluid-props-sm-p-xxxs($props) {
    @include fluid-prop-sm($props, $p-xxxs-m, $p-xxxs);
}

@mixin fluid-props-sm-p-xxxs-4-2($props) {
    @include fluid-prop-sm($props, $p-xxxs-4-2-m, $p-xxxs-4-2);
}

@mixin fluid-props-sm-p-xxs($props) {
    @include fluid-prop-sm($props, $p-xxs-m, $p-xxs);
}

@mixin fluid-props-sm-p-xxs-8-4($props) {
    @include fluid-prop-sm($props, $p-xxs-8-4-m, $p-xxs-8-4);
}

@mixin fluid-props-sm-p-xs($props) {
    @include fluid-prop-sm($props, $p-xs-m, $p-xs);
}

@mixin fluid-props-sm-p-xs-12-8($props) {
    @include fluid-prop-sm($props, $p-xs-12-8-m, $p-xs-12-8);
}

@mixin fluid-props-sm-p-sm($props) {
    @include fluid-prop-sm($props, $p-sm-m, $p-sm);
}

@mixin fluid-props-sm-p-md($props) {
    @include fluid-prop-sm($props, $p-md-m, $p-md);
}

@mixin fluid-props-sm-p-md-40-16($props) {
    @include fluid-prop-sm($props, $p-md-40-16-m, $p-md-40-16);
}

@mixin fluid-props-sm-p-lg($props) {
    @include fluid-prop-sm($props, $p-lg-m, $p-lg);
}

@mixin fluid-props-sm-p-xl($props) {
    @include fluid-prop-sm($props, $p-xl-m, $p-xl);
}

@mixin fluid-props-sm-p-xxl($props) {
    @include fluid-prop-sm($props, $p-xxl-m, $p-xxl);
}

@mixin fluid-props-sm-p-xxxl($props) {
    @include fluid-prop-sm($props, $p-xxxl-m, $p-xxxl);
}
