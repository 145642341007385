@import 'src/styles/mixins';

.switch {
    position: relative;
}

.checkbox {
    display: none;

    &:checked + .inner {
        color: $c-primary-50;

        &:hover {
            color: $c-primary-70;
        }
    }

    &:not(:checked) + .inner {
        color: $c-primary-30;

        &:hover {
            color: $c-primary-70;
        }
    }
}

.label {
    cursor: pointer;
    position: relative;

    & + &::before {
        background-color: $c-primary-30;
        content: '';
        height: 24px;
        left: -4.5px;
        position: absolute;
        top: calc(50% - 12px);
        width: 1px;
    }
}

.container {
    align-items: center;
    border-radius: 4px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    overflow: hidden;
    padding: 2px;
    position: relative;
}

.inner {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    height: 48px;
    justify-content: center;
    padding: 12px;
    transition: color $animation;
    width: 48px;

    svg {
        height: 24px;
        width: 24px;
    }

    @media #{$screen-mobile} {
        height: 40px;
        padding: 8px;
        width: 40px;
    }
}
