@import 'src/styles/mixins';

.gallerySlider {
    grid-template-rows: 100%;
    position: relative;
    width: 100%;

    &.active {
        display: block;
    }

    &.basic,
    &.fullscreen {
        .galleryButtonSlider {
            height: 100%;
            width: 100%;
        }
    }

    &.basic {
        height: 588px;

        .sliderBlock {
            border-radius: var(--r-xl);
            overflow: hidden;
            position: relative;
        }

        .galleryThumbs {
            height: calc(100% + 60px);
            margin-top: -30px;
        }

        @media #{$screen-mobile} {
            height: 372px;

            .galleryButtonSlider {
                display: none;
            }

            .sliderBlock {
                border-radius: 0;
                overflow: hidden;
                position: relative;
            }
        }

        .gallerySliderShieldList {
            left: 24px;
            position: absolute;
            top: 24px;

            @media #{$screen-mobile} {
                left: 16px;
                top: 16px;
            }
        }
    }

    &.technical {
        grid-template-rows: auto min-content;
        row-gap: 24px;

        @media #{$screen-mobile} {
            display: block;
            height: 254px;

            .galleryButtonSlider {
                display: none;
            }
        }

        .gallerySliderShieldList {
            left: 8px;
            width: calc(100% - 8px);

            @media #{$screen-mobile} {
                left: 16px;
                top: 16px;
                width: calc(100% - 16px);
            }
        }
    }

    &.fullscreen {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 24px;

        .galleryThumbs {
            height: 80px;
            width: 400px;
        }

        .galleryMainSlider {
            grid-column: 1;
            grid-row: 1;
        }

        .galleryButtonSlider {
            grid-column: 1;
            grid-row: 2;
            margin-top: auto;
            width: 500px;
        }

        .gallerySliderShieldList {
            display: none;
        }
    }
}
