.root {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs);
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    button {
        border-radius: 50%;
        cursor: pointer;
        height: 40px;
        overflow: hidden;
        width: 40px;

        svg {
            height: 40px;
            transform: scale(1.05);
            width: 40px;
        }

        &:disabled {
            pointer-events: none;
        }
    }
}
