@import 'src/styles/mixins';

.root {
    border-bottom: 1px solid $c-secondary-15;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    row-gap: 8px;
}

.optionsItem {
    display: flex;
    justify-content: space-between;
}
