@import 'src/styles/mixins';

.button {
    align-items: center;
    border-radius: var(--r-xl);
    column-gap: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all $animation-in-out;

    &:disabled,
    &.isLoading {
        cursor: auto;
        pointer-events: none;
    }

    &.primary {
        background-color: $c-primary-100;
        border: 1px solid $c-primary-100;
        color: $c-default-white-100;
        transition: color $animation, border-color $animation, outline-color $animation, background-color $animation;

        &:hover {
            background-color: $c-primary-80;
            border: 1px solid $c-primary-80;
        }

        &:active {
            background-color: $c-primary-70;
            border: 1px solid $c-primary-70;
        }

        &:focus-visible {
            outline: 2px solid $c-default-gray-70;
        }

        &:disabled {
            background-color: $c-primary-30;
            border-color: $c-primary-30;
            color: $c-default-white-a90;
        }

        &.isLoading {
            background-color: $c-primary-100;
            border-color: $c-primary-100;
            color: $c-default-white-100;
        }

        &.secondaryColor {
            background-color: $c-secondary-100;
            border: 1px solid $c-secondary-100;
            color: $c-default-white-100;

            &:hover {
                background-color: $c-secondary-80;
                border: 1px solid $c-secondary-80;
            }

            &:active {
                background-color: $c-secondary-70;
                border: 1px solid $c-secondary-70;
            }

            &:focus-visible {
                outline: 2px solid $c-default-gray-100;
            }

            &:disabled {
                background-color: $c-secondary-30;
                border-color: $c-secondary-30;
                color: $c-default-white-a90;
            }

            &.isLoading {
                background-color: $c-secondary-100;
                border: 1px solid $c-secondary-100;
                color: $c-default-white-100;
            }
        }
    }

    &.secondary {
        background-color: inherit;
        border: 1px solid $c-primary-100;
        color: $c-primary-100;
        transition: all $animation;

        &:focus-visible {
            border-color: $c-primary-100;
            outline: 1px solid $c-default-gray-100;
        }

        &:hover {
            background-color: $c-primary-100;
            border-color: $c-primary-100;
            color: $c-default-white-100;
        }

        &:active {
            background-color: $c-primary-80;
            border-color: $c-primary-80;
            color: $c-default-white-100;
        }

        &:disabled {
            border-color: $c-primary-30;
            color: $c-primary-30;
        }

        &.isLoading {
            border-color: $c-primary-100;
            color: $c-primary-100;
        }

        &.secondaryColor {
            background-color: $c-default-gray-10;
            border: 1px solid $c-default-gray-10;
            color: $c-default-gray-100;

            &:hover {
                background-color: $c-default-gray-20;
                border: 1px solid $c-default-gray-20;
            }

            &:active {
                background-color: $c-default-gray-30;
                border: 1px solid $c-default-gray-30;
            }

            &:focus-visible {
                border-color: $c-default-gray-100;
            }

            &:disabled {
                background-color: $c-default-gray-10;
                border: 1px solid $c-default-gray-10;
                color: $c-default-gray-40;
            }

            &.isLoading {
                background-color: $c-default-gray-10;
                border: 1px solid $c-default-gray-10;
                color: $c-default-gray-100;
            }
        }
    }

    &.ghost {
        color: $c-default-gray-50;
        transition: color $animation;

        &:hover {
            color: $c-primary-100;
        }

        &:active {
            color: $c-default-gray-70;
        }

        &:focus-visible {
            outline: 1px solid $c-secondary-100;
        }

        &:disabled {
            color: $c-default-gray-30;
        }

        &.isLoading {
            color: $c-default-gray-50;
        }
    }

    &.floating {
        background-color: $c-primary-100;
        border: 1px solid $c-primary-100;
        box-shadow: 0 2px 25px 0 $c-shadow-large-tooltip;
        color: $c-default-white-100;
        transition: color $animation, border-color $animation, outline-color $animation, background-color $animation;

        &:hover {
            background-color: $c-primary-80;
            border: 1px solid $c-primary-80;
        }

        &:active {
            background-color: $c-primary-70;
            border: 1px solid $c-primary-70;
        }

        &:focus-visible {
            outline: 2px solid $c-default-gray-70;
        }

        &:disabled {
            background-color: $c-primary-30;
            border-color: $c-primary-30;
            color: $c-default-white-a90;
        }

        &.isLoading {
            background-color: $c-primary-100;
            border-color: $c-primary-100;
            color: $c-default-white-100;
        }
    }

    &.large {
        height: 48px;
        padding: 0  var(--p-md);

        svg {
            height: 24px;
            width: 24px;
        }

        &.isAdaptive {
            @media #{$screen-mobile} {
                height: 40px;
                padding: 0 var(--p-md);

                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    &.medium {
        height: 40px;
        padding: 0  var(--p-md);

        svg {
            height: 20px;
            width: 20px;
        }

        &.isAdaptive {
            @media #{$screen-mobile} {
                padding: 0 var(--p-md);
            }
        }
    }

    &.small {
        height: 32px;
        padding: 0 var(--p-sm);

        svg {
            height: 20px;
            width: 20px;
        }

        &.isAdaptive {
            @media #{$screen-mobile} {
                padding: 0 var(--p-sm);
            }
        }
    }

    &.isWithoutPaddings {
        padding: 0;

        &.isAdaptive {
            @media #{$screen-mobile} {
                padding: 0;
            }
        }
    }

    &.isStretched {
        justify-content: center;
        width: 100%;
    }

    &.isSquare {
        aspect-ratio: 1 / 1;
        justify-content: center;
        padding: 0;

        &.isAdaptive {
            @media #{$screen-mobile} {
                padding: 0;
            }
        }
    }

    &.isContentSize {
        height: auto;
        padding: 0;

        @media #{$screen-mobile} {
            padding: 0;
        }
    }

    svg {
        flex-shrink: 0;
    }
}

.text {
    align-items: center;
    column-gap: 8px;
    display: grid;
    grid-auto-flow: column;
    white-space: nowrap;

    .isHideTextAdaptive & {
        @include hide-mobile-show-desktop(grid);
    }

    &.isNotDisplayed {
        display: none;
    }
}
