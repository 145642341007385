@import 'src/styles/mixins';
@import 'src/styles/font';

.form {
    align-items: center;
    display: flex;
    position: relative;

    &.isActive {
        left: 0;
        position: absolute;
        width: 100%;
    }
}

.label {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 100%;

    .labelCross {
        cursor: pointer;
        opacity: 0;
        position: absolute;
        right: 24px;
        top: 10px;
        transition: opacity $animation-in-out;

        svg {
            color: $c-default-gray-100;
            display: block;
            height: 24px;
            width: 24px;
        }

        &.labelCrossVisible {
            opacity: 1;
        }
    }
}

.searchButton {
    align-items: center;
    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;
    height: 24px;
    position: absolute;
    right: 24px;
    top: 10px;
    width: 24px;

    &.isPanelVisible {
        display: none;
    }

    &.isActive.isPanelVisible {
        display: flex;
        left: auto;
        right: 16px;
    }

    svg {
        height: 24px;
        width: 24px;
    }
}

.clearButton {
    border-right: 2px solid $c-default-gray-50;
    cursor: pointer;
    display: block;
    height: 12px;
    margin-right: 46px;
    padding: 12px 19px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 12px;

    &::before {
        background-color: $c-default-gray-70;
        content: '';
        height: 2px;
        left: 11px;
        position: absolute;
        top: 11px;
        transform: rotate(-45deg);
        width: 16px;
    }

    &::after {
        background-color: $c-default-gray-70;
        content: '';
        height: 2px;
        left: 11px;
        position: absolute;
        top: 11px;
        transform: rotate(45deg);
        width: 16px;
    }
}

.input {
    appearance: none;
    border: 1px solid $c-default-gray-30;
    border-radius: var(--r-lg);
    color: $c-default-gray-100;
    height: 44px;
    outline: none;
    padding: var(--p-xxs) var(--p-sm);
    transition: color $animation, background-color $animation, border $animation;
    width: 100%;
    z-index: 2;

    @include f_p('lg');

    &::placeholder {
        color: $c-default-gray-50;
        transition: color $animation;
    }

    &::input-placeholder {
        color: $c-default-gray-50;
        transition: color $animation;
    }

    &:hover {
        border-color: $c-secondary-50;
        transition: border $animation;

        &::placeholder {
            color: $c-default-gray-70;
        }
    }

    &:focus {
        border-color: $c-secondary-50;

        &::placeholder {
            color: transparent;
        }

        &::input-placeholder {
            color: transparent;
        }
    }
}

.searchLoaderIcon {
    align-content: center;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 24px;
    top: 10px;

    svg {
        animation: $animation-loader;
        flex-shrink: 0;
        height: 24px;
        width: 24px;
    }
}

.overlay {
    background-color: $c-default-gray-a50;
    height: 100vh;
    left: 0;
    position: absolute;
    top: var(--header-height);
    width: 100vw;
    z-index: 0;

    @media #{$screen-mobile} {
        display: none;
    }
}
