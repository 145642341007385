@import 'src/styles/mixins';

.icon {
    transition: transform $animation;

    &.rotateIcon {
        transform: rotate(180deg);
    }
}

.visibleList,
.hiddenList {
    row-gap: 16px;
}

.hiddenList {
    padding-top: 16px;
}

.button {
    margin-top: 16px;
}
