@import 'src/styles/mixins';

.root {
    background-color: $c-secondary-15;
    margin-top: 48px;

    @media #{$screen-mobile} {
        margin-top: 32px;
    }
}
