@import 'src/styles/mixins';

.root,
.content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}

.content {
    @include fluid-prop-sm(row-gap, 8px, 16px);
}
