.yandexMapContainer {
    border-radius: var(--r-lg);
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    :global {
        .ymaps-2-1-79-map-copyrights-promo,
        .ymaps-2-1-79-copyright__content {
            display: none !important;
        }
    }
}

.CentredMarker {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    svg {
        height: 40px;
        width: 40px;
    }
}
