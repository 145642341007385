@import 'src/styles/mixins';

.list {
    background-color: $c-default-white-100;
    border-radius: var(--r-sm);
    box-shadow: 0 2px 25px 0 $c-shadow-large-tooltip;
    left: 0;
    max-height: 250px;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 60px;
    z-index: 1;
}
