@import 'src/styles/mixins';

.root {
    border-radius: var(--r-lg);
    padding: var(--p-sm);

    &.withBorder {
        border: 1px solid $c-default-gray-30;
    }
}
