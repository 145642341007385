@import 'src/styles/mixins';

.areaContent {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.container {
    @include container;
}
