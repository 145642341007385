@import 'src/styles/mixins';

.root {
    @include container;

    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: var(--p-xl);
    margin-top: 16px;
}
