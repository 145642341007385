@import 'src/styles/mixins';
@import 'src/styles/font';

.root {
    @include container('withoutMobilePadding');
}

.desktop {
    @include hide-mobile-show-desktop;
}

.mobile {
    @include show-mobile-hide-desktop;
}

.button {
    @include fluid-prop-sm(margin-top, 32px, 40px);
}

.buttonWrap {
    @include container;

    margin: 0 auto;

    @media #{$screen-desktop} {
        max-width: 160px;
    }
}

.text {
    @include f_button-l;

    @media #{$screen-mobile} {
        @include f_button-m;
    }
}

.brandCard {
    border: 1px solid $c-additional-graphics-elements-divider;
    height: 90px;
    transition: border-color $animation;
    width: 180px;

    &:hover {
        border-color: $c-primary-30;
    }

    @media #{$screen-mobile} {
        height: 56px;
        width: 116px;
    }
}

.mobileSlide {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
