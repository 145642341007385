@import 'src/styles/mixins';

.content {
    position: relative;
}

.main {
    border: 1px solid $c-additional-graphics-elements-divider;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: var(--p-sm);
    padding: var(--p-sm);
}

.mainInfo {
    border-bottom: 1px solid $c-additional-graphics-elements-divider;
    display: flex;
    flex-direction: column;
    gap: var(--p-sm-24);
    padding-bottom: var(--p-sm);
}

.aside {
    align-self: flex-start;
    position: sticky;
    top: calc(var(--header-height) + 16px);

    &.isSkeleton {
        top: 0;
    }

    @media #{$screen-mobile} {
        position: static;

        &.adaptiveReverse {
            order: -1;
        }
    }
}
