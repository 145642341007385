@import 'src/styles/mixins';

.container {
    @include fluid-prop-padding-left-right-sm(16px, 20px);

    align-items: center;
    background-color: $c-status-info-10;
    border-radius: 4px;
    display: flex;
    gap: 24px;
    max-width: 812px;
    padding-bottom: 16px;
    padding-top: 16px;
    width: 100%;

    @media #{$screen-mobile} {
        align-items: initial;
        flex-direction: column;
        gap: 12px;
    }
}
