@import 'src/styles/mixins';

.root.isNotTop {
    padding: 0 0 16px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
