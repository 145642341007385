@import 'src/styles/font';

.h1 {
    @include f_h1;
}

.h2 {
    @include f_h2;
}

.h3 {
    @include f_h3;
}

.h4 {
    @include f_h4;
}

.h5 {
    @include f_h5;
}

.h6 {
    @include f_h6;
}

.h7 {
    @include f_h7;
}

.p {
    @include f_p;

    &.small {
        @include f_p('xs');
    }

    &.large {
        @include f_p('lg');
    }
}

.p-strong {
    @include f_p_strong;
}

.elements-strong {
    @include f_elements_strong;
}

.elements-p {
    @include f_elements_p;
}

.p-u {
    @include f_p-u;
}

.p-regular {
    @include f_p-regular;
}

.p-bold-strong {
    @include f_p-bold-strong;
}

.p-small {
    @include f_p-small;
}

.p-small-strong {
    @include f_p-small-strong;
}

.link {
    @include f_link;
}

.button-l {
    @include f_button-l;
}

.button-m {
    @include f_button-m;
}

.button-s {
    @include f_button-s;
}

.price-l {
    @include f_price-l;
}

.price-preview-l {
    @include f_price-preview-l;
}

.price-preview-m {
    @include f_price-preview-m;
}

.price-preview-s {
    @include f_price-preview-s;
}

.old-price-preview-l {
    @include f_old-price-preview-l;
}

.old-price-preview-m {
    @include f_old-price-preview-m;
}

.old-price-preview-s {
    @include f_old-price-preview-s;
}

.price-preview-cart {
    @include f_price-preview-cart;
}

.old-price-cart {
    @include f_old-price-cart;
}

// unused fonts

.h1-bold {
    @include f_h1_bold;
}

.h2-bold {
    @include f_h2_bold;
}

.h3-bold {
    @include f_h3_bold;
}

.h4-bold {
    @include f_h4_bold;
}

.h5-bold {
    @include f_h5_bold;
}

.h6-bold {
    @include f_h6_bold;
}

.h7-bold {
    @include f_h7_bold;
}

.p-medium {
    @include f_p-medium;

    &.small {
        @include f_p-medium('xs');
    }

    &.large {
        @include f_p-medium('lg');
    }
}

.p-medium-strong {
    @include f_p-medium-strong;
}

.label {
    @include f_label;
}

.badge {
    @include f_badge;
}

.success50 {
    color: $c-status-success-50;
}

.error50 {
    color: $c-status-error-50;
}

.error10 {
    color: $c-status-error-10;
}

.warning50 {
    color: $c-status-warning-50;
}

.warning10 {
    color: $c-status-warning-10;
}

.info50 {
    color: $c-status-info-50;
}

.primary100 {
    color: $c-primary-100;
}

.primary80 {
    color: $c-primary-80;
}

.primary70 {
    color: $c-primary-70;
}

.primary60 {
    color: $c-primary-60;
}

.primary50 {
    color: $c-primary-50;
}

.primary30 {
    color: $c-primary-30;
}

.primary30a20 {
    color: $c-primary-30-a20;
}

.primary20 {
    color: $c-primary-20;
}

.primary10 {
    color: $c-primary-10;
}

.secondary100 {
    color: $c-secondary-100;
}

.secondary80 {
    color: $c-secondary-80;
}

.secondary70 {
    color: $c-secondary-70;
}

.gray100 {
    color: $c-default-gray-100;
}

.gray80 {
    color: $c-default-gray-80;
}

.gray70 {
    color: $c-default-gray-70;
}

.gray65 {
    color: $c-default-gray-a65;
}

.gray60 {
    color: $c-default-gray-60;
}

.gray50 {
    color: $c-default-gray-50;
}

.gray40 {
    color: $c-default-gray-40;
}

.gray30 {
    color: $c-default-gray-30;
}

.gray20 {
    color: $c-default-gray-20;
}

.black100 {
    color: $c-default-black-100;
}

.black-a50 {
    color: $c-default-black-a50;
}

.black-a10 {
    color: $c-default-black-a10;
}

.white100 {
    color: $c-default-white-100;
}

.white-a90 {
    color: $c-default-white-a90;
}

.white-a70 {
    color: $c-default-white-a70;
}

.white-a50 {
    color: $c-default-white-a50;
}

.white-a10 {
    color: $c-default-white-a10;
}

.yellow80 {
    color: $c-additional-yellow-80;
}

.yellow100 {
    color: $c-additional-yellow-100;
}

.gray-blue80 {
    color: $c-additional-gray-blue-80;
}

.gray-blue60 {
    color: $c-additional-gray-blue-60;
}

.gray-blue50 {
    color: $c-additional-gray-blue-50;
}

.gray-blue30 {
    color: $c-additional-gray-blue-30;
}

.gray-blue20 {
    color: $c-additional-gray-blue-20;
}

.light-gray-blue90 {
    color: $c-additional-light-blue-90;
}

.light-gray-blue80 {
    color: $c-additional-gray-blue-80;
}

.shadow-gray-blue-a3 {
    color: $c-additional-shadow-blue-a3;
}

.gray-a50 {
    color: $c-default-gray-a50;
}

.price-old {
    color: $c-additional-price-old;
}

.basic-price {
    color: $c-additional-price-basic;
}

.current-color {
    color: currentcolor;
}

.left {
    text-align: left;
}

.end {
    text-align: end;
}

.start {
    text-align: start;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.justify {
    text-align: justify;
}

.match-parent {
    text-align: match-parent;
}

.justify-all {
    text-align: justify-all;
}
