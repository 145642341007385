@import 'src/styles/mixins';

.titleContainer {
    align-items: baseline;
    display: flex;
}

.title {
    align-items: flex-end;
    display: flex;
    gap: 32px;
}
