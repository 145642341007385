@import 'src/styles/mixins';

.root {
    border: 1px solid $c-default-gray-20;
    border-radius: var(--r-md);
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-16);
    padding: var(--p-sm);

    a {
        align-self: self-start;
    }
}
