@import 'src/styles/mixins';

.root {
    align-items: center;
    display: flex;
    gap: var(--p-sm);
    justify-content: space-between;

    @media #{$screen-mobile} {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.title {
    align-items: center;
    display: flex;
    gap: var(--p-xxs-8-4);

    svg {
        height: 20px;
        width: 20px;
    }

    @media #{$screen-mobile} {
        svg {
            height: 16px;
            width: 16px;
        }
    }
}
