@import 'src/styles/mixins';

.root {
    background-color: $c-secondary-15;
    border-radius: var(--r-md);

    @include fluid-prop-padding-top-bottom-sm(24px, 32px);
    @include fluid-prop-padding-left-right-sm(16px,24px);
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--p-sm);
}

.main {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.submit {
    @include hide-mobile-show-desktop(flex); }
