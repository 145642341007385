@import 'src/styles/mixins';

.root {
    @include container;
    @include fluid-prop-sm(padding-bottom, 0, 40px);
    @include fluid-prop-sm(padding-top, 56px, 120px);
}

.button {
    width: 100%;

    @media #{$screen-desktop} {
        width: auto;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    text-align: center;

    @media #{$screen-desktop} {
        align-items: flex-start;
        margin-top: 0;
        text-align: left;
    }
}

.helper {
    display: none;

    @media #{$screen-desktop} {
        display: block;
    }
}

.image {
    @include fluid-prop-sm(width, 207px, 240px);
}

.picture {
    align-items: center;
    display: flex;
    justify-content: center;
    line-height: 0;
    padding: 24px;

    @media #{$screen-desktop} {
        padding: 0;
    }
}

.title {
    font-weight: 700;
}
