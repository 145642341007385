@import 'src/styles/mixins';

.navigation {
    background-color: $c-default-white-100;
    border-radius: 50%;
    box-shadow: 0 2px 12px 0 $c-shadow-slider-buttons;
    color: $c-default-gray-100;
    cursor: pointer;
    height: 48px;
    opacity: 0;
    position: absolute;
    top: calc(50% - 24px);
    transition: opacity $animation, visibility $animation, color $animation;
    visibility: hidden;
    width: 48px;
    z-index: 1;

    @media #{$screen-mobile} {
        display: none;
    }

    svg {
        height: 24px;
        width: 24px;
    }

    &:hover {
        color: $c-secondary-100;
        transition: color $animation;
    }

    &:active {
        color: $c-secondary-70;
        transition: color $animation;
    }

    &:disabled {
        background-color: $c-default-white-a70;
        color: $c-default-gray-50;
        cursor: auto;
    }

    &.prev {
        left: -24px;

        & svg {
            rotate: -180deg;
        }
    }

    &.next {
        right: -24px;
    }
}
