@import 'src/styles/mixins';

.root {
    align-items: flex-start;
    background-color: $c-default-white-100;
    border-radius: 4px;
    box-shadow: 0 2px 25px 0 $c-additional-shadow-blue-a3;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 7px 0 0;
    overflow: hidden;
    padding: 0;

    li {
        width: 100%;
    }

    .link {
        color: $c-default-gray-80;
        display: block;
        padding: 12px;
        width: 100%;

        &:hover {
            background-color: $c-primary-10;
        }
    }
}
