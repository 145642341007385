@import 'src/styles/mixins';
@import 'src/styles/font';

.sliderTitleWithCount {
    @include container;
    @include fluid-prop-sm(margin-bottom, 10px, 24px);

    align-items: center;
    display: flex;
}

.sliderTitleWithCountTitle {
    transition: color $animation-in-out;

    &:hover {
        color: $c-primary-50;
    }
}

.content {
    align-items: center;
    column-gap: 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.anchorLink {
    flex-shrink: 0;
}

.plural {
    @include hide-mobile-show-desktop;
}
