@import 'src/styles/mixins';
@import 'src/styles/font';

.input {
    align-items: center;
    background-color: $c-default-white-100;
    border-radius: var(--r-xl);
    box-sizing: border-box;
    display: flex;
    height: 60px;
    padding: var(--p-xxs) var(--p-xs-16) var(--p-xxs) var(--p-sm);
    position: relative;
    width: 100%;
    z-index: 0;

    @media #{$screen-mobile} {
        height: 48px;
    }

    &:hover {
        & .inputLabel {
            color: $c-default-gray-100;
        }
    }

    &.isDisabled {
        pointer-events: none;
    }
}

.inputFrame {
    border: 1px solid transparent;
    border-radius: var(--r-xl);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: border-color $animation;
    width: 100%;

    &.isBorder {
        border: 1px solid $c-default-gray-30;
    }
}

.inputFrameError {
    border: 1px solid $c-status-error-50;
}

.inputLabel {
    color: $c-default-gray-60;
    cursor: pointer;
    min-height: 24px;
    position: absolute;
    top: 18px;
    transition: all $animation;
    width: calc(100% - 24px);
    z-index: 1;

    @include f_p('auto');
    @include ellipsis(1);

    @media #{$screen-mobile} {
        top: 13px;
    }
}

.inputOriginal {
    border: none;
    color: $c-default-gray-100;
    padding: 0;
    transition: bottom $animation;
    width: 100%;
    z-index: 1;

    @include f_p('auto');

    &::placeholder {
        opacity: 0;
    }

    &:disabled {
        background-color: $c-default-gray-20;
        color: $c-default-gray-40;

        // Safari
        opacity: 1;
        -webkit-text-fill-color: $c-default-gray-50;

        & + .inputFrame {
            background-color: $c-default-gray-20;
            border-color: $c-default-gray-20;
            pointer-events: none;
        }

        & ~ .inputLabel {
            color: $c-default-gray-40;
            pointer-events: none;
        }

        & ~ .inputExtraAction {
            color: $c-default-gray-50;
        }

        & ~ .notFadingPlaceholder {
            color: $c-default-gray-50;
        }

        & ~ .inputLoader {
            border-top-color: $c-default-gray-80;
        }
    }

    &:focus {
        bottom: -7px;
        outline: none;
        position: relative;

        &::placeholder {
            opacity: 1;
        }

        & + .inputFrame {
            border: 1px solid rgb($c-secondary-100, 70%);
        }

        & ~ .inputExtraAction {
            color: $c-default-gray-80;
        }

        & ~ .inputLabel {
            cursor: text;
            height: 16px;
            top: 5px;

            @include f_p-medium('auto');
        }
    }

    &.transparent {
        background-color: transparent;
    }

    & ~ .notFadingPlaceholder {
        color: $c-default-gray-100;
        opacity: 0;
        position: absolute;
        top: 25px;
        z-index: 0;

        @media screen and (max-width: $size-sm-max) {
            top: 18px;
        }

        @media #{$screen-tablet-xl} {
            top: 20px;
        }

        @media #{$screen-phone-xl} {
            top: 20px;
        }

        .notFadingPlaceholderTransparent {
            color: transparent;
        }
    }
}

.inputOriginalFilled {
    bottom: -7px;
    position: relative;

    &::placeholder {
        opacity: 1;
    }

    & + .inputFrame {
        border-color: $c-default-gray-30;
    }

    & + .inputFrameError {
        border-color: $c-status-error-50;
    }

    & ~ .inputLabel {
        height: 16px;
        top: 5px;

        @include f_p-medium('auto');
    }

    & ~ .inputExtraAction {
        color: $c-default-gray-60;
    }

    & ~ .notFadingPlaceholder {
        opacity: 1;
    }
}

.inputOriginalNoLabel {
    position: static;

    &::placeholder {
        opacity: 1;
    }

    &:focus {
        position: static;
    }
}

.inputOriginalHasExtra {
    width: calc(100% - 48px);
}

.inputLabelHasExtra {
    width: calc(100% - 48px);
}

.inputExtraAction {
    align-items: center;
    color: $c-default-gray-60;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-left: auto;
    transition: color $animation;
    width: 24px;
    z-index: 1;

    &:focus {
        color: $c-default-gray-80;
    }

    @media #{$screen-mobile} {
        height: 20px;
        width: 20px;
    }

    svg {
        height: 100%;
        width: 100%;
    }
}

.inputLoader {
    border-color: $c-default-gray-a10;
    border-top-color: $c-status-info-50;
}
