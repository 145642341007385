@import 'src/styles/mixins';

.root {
    align-items: center;
    background-color: $c-primary-5;
    border-radius: var(--r-md);
    column-gap: 32px;
    display: flex;
    padding: 16px 20px;

    @media #{$screen-mobile} {
        column-gap: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: var(--p-sm);
}

.icon {
    height: 16px;
    width: 16px;
}

.infoContent {
    display: inline-block;
    max-width: 265px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include fluid-prop-sm(width, 170px, 265px);
}
