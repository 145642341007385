@import 'adaptive';
@import 'fluid-prop';

/* @mixin font-fluid-sm($f-sm, $l-sm, $f-md, $l-md){
    @include fluid-prop-sm(font-size, $f-sm, $f-md);
    @include fluid-prop-sm(line-height, $l-sm, $l-md);
} */

@mixin font-fluid-sm($f-sm, $l-sm, $f-lg, $l-lg) {
    @include fluid-prop-sm(font-size, $f-sm, $f-lg);
    @include fluid-prop-sm(line-height, $l-sm, $l-lg);
}

@mixin font-fluid-sm-int($f-sm, $l-sm-int, $f-lg, $l-lg-int) {
    @include fluid-prop-sm(font-size, $f-sm, $f-lg);

    line-height: $l-lg-int;

    @media #{$screen-mobile} {
        line-height: $l-sm-int;
    }
}
