@import 'color';
@import 'animation';

@mixin skeleton-style-after() {
    // animation: $animation-skeleton;
    background-image:
        linear-gradient(
            90deg,
            rgba($c-default-white-100, 0) 0,
            rgba($c-default-white-100, 0.2) 20%,
            rgba($c-default-white-100, 0.5) 60%,
            rgba($c-default-white-100, 0)
        );
    bottom: 0;

    // content: '';
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 2;
}

@mixin skeleton-style() {
    background-color: $c-additional-gray-blue-20;
    border-radius: 4px;
    color: $c-additional-gray-blue-20;
    overflow: hidden;
    position: relative;

    &::after {
        @include skeleton-style-after;
    }
}

@mixin skeleton() {
    pointer-events: none;

    div:not(:has(*)),
    h1:not(:has(*)),
    h2:not(:has(*)),
    h3:not(:has(*)),
    button:not(:has(*)),
    svg {
        @include skeleton-style;
    }
}

@mixin skeleton-style-item() {
    border-color: transparent;
    overflow: hidden;
    position: relative;

    &::before {
        @include skeleton-style-before;
    }

    &::after {
        @include skeleton-style-after;
    }
}

@mixin skeleton-style-before-animated() {
    &::before {
        animation-duration: $animation-duration;
        animation-name: fade-in;
        opacity: 1;
    }
}

@mixin skeleton-style-before() {
    background: $c-gradient-gray-horizontal;
    border-radius: 4px;
    content: '';
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transform: scale(1.01);
    width: 100%;
    z-index: 2;
}
