@import 'src/styles/mixins';

.filter {
    @include fluid-prop-padding-top-bottom-sm(8px, 6px);

    background-color: $c-secondary-30;
    border-radius: var(--r-md);
    gap: 10px;
    height: 32px;
    padding: 0 var(--p-xs);
    transition: background-color $animation;

    svg,
    .name,
    .description {
        transition: color $animation;
    }

    svg {
        color: $c-default-gray-100;
    }

    .content {
        display: flex;
        gap: 4px;
        width: 100%;
    }

    .name {
        color: $c-default-gray-a50;
    }

    .description {
        color: $c-default-gray-100;
    }

    &:hover {
        background-color: $c-secondary-80;

        .name {
            color: $c-default-white-a50;
        }

        .description {
            color: $c-default-white-100;
        }

        svg {
            color: $c-default-white-100;
        }
    }
}
