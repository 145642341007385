@import 'src/styles/mixins';

.root {
    &:global(.segmented-radio-component) {
        @include fluid-prop-sm(padding, 12px, 16px);

        align-items: center;
        background-color: $c-default-gray-10;
        border-radius: var(--r-xl);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        transition: background-color $animation, outline $animation,;
    }

    &:global(.segmented-radio-component.checked) {
        background-color: $c-primary-5;
        outline: 1px solid $c-secondary-100;
    }

    &:global(.segmented-radio-component.error) {
        background-color: $c-default-gray-10;
        outline: 1px solid $c-status-error-50;
    }

    &.checked:hover,
    &.error:hover {
        background-color: $c-default-white-100;
    }

    &:global(.segmented-radio-component.disabled) {
        cursor: auto;
    }

    & :global(.radio-component > label) {
        outline: none;
    }
}

.icon {
    flex-shrink: 0;
    height: 24px;
    min-width: 24px;

    &.disabled {
        filter: opacity(0.4);
    }
}
