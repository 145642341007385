@import 'src/styles/mixins';

.galleryMainSlide {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    position: relative;

    img {
        display: block;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &.basic {
        height: 588px;
        width: 100%;

        @media #{$screen-mobile} {
            border-radius: var(--r-xl);
            height: 360px;
            overflow: hidden;
            width: 360px;
        }

        img {
            cursor: zoom-in;
            height: 100%;
        }
    }

    &.technical {
        height: 300px;
        width: 300px;

        @media #{$screen-mobile} {
            height: 212px;
            width: 212px;
        }
    }

    &.fullscreen {
        aspect-ratio: 1 / 1;
        height: 100%;
        width: 100%;

        img {
            object-fit: contain;

            @media #{$screen-mobile} {
                transform: scale(1);
            }
        }

        @media #{$screen-mobile} {
            width: 100%;

            .wrapper,
            .content {
                height: 100%;
            }

            .wrapper {
                margin: 0 auto;
            }

            img {
                object-fit: contain;
            }
        }

        .video {
            height: calc(100% - 128px);
            max-height: 671px;
            width: 100%;
        }
    }
}
