@import 'src/styles/mixins';

.tagContainer {
    position: relative;
    width: max-content;
}

.tag {
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    display: inline-grid;
    grid-auto-flow: column;
    transition: color $animation, background-color $animation, border $animation;

    svg {
        color: $c-default-gray-100;
        height: 16px;
        width: 16px;
    }
}
