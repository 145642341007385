@import 'src/styles/mixins';

.root {
    .mainTitle {
        margin-bottom: 16px;
    }

    .mainDescription {
        color: $c-common-black;
        margin-bottom: 24px;
    }

    .advantagesList {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 24px;

        .advantagesItem {
            align-items: center;
            display: flex;
            gap: 16px;

            .advantagesIcon {
                align-items: center;
                background-color: $c-secondary-15;
                border-radius: var(--r-xl);
                color: $c-secondary-100;
                display: flex;
                height: 48px;
                justify-content: center;
                min-width: 48px;

                svg {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}
