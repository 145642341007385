@import 'src/styles/mixins';

.container {
    @include fluid-prop-sm('width', 220px, 282px);
    @include fluid-prop-sm('padding-left', 24px, 32px);
    @include fluid-prop-sm('padding-right', 24px, 32px);
    @include fluid-prop-sm('padding-bottom', 32px, 52px);

    background-color: $c-additional-gray-blue-20;
    border-radius: 4px;
    cursor: pointer;
    min-height: 100%;
    padding-top: 32px;
}

.image {
    height: 48px;
    width: 48px;
}

.title {
    margin-bottom: 8px;
    margin-top: 4px;
}
