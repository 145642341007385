@import 'src/styles/mixins';
@import 'src/styles/font';

.root {
    display: flex;
    flex-direction: column;
    gap: var(--p-xxxs-4-2);
}

.listContainer {
    display: flex;
    flex-direction: column;
    gap: var(--p-xxxs-4-2);
}

.list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: var(--p-xs);
}

.item {
    display: flex;
    justify-content: space-between;
}

.switcher {
    align-self: flex-end;
    color: $c-primary-80;
    cursor: pointer;

    @include f_p-medium;
}
