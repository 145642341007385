@import 'src/styles/mixins';
@import 'src/styles/font';

.dateSelect {
    :global(.react-select__control) {
        background-color: $c-default-gray-10;
        border-color: transparent;
        border-width: 0;
        box-shadow: none;
        cursor: pointer;
        min-height: 32px;

        &:hover {
            border-color: transparent;
        }
    }

    :global {
        .react-select__control--menu-is-open .react-select__single-value,
        .react-select__control--menu-is-open .react-select__dropdown-indicator {
            color: $c-default-gray-70;
        }
    }

    :global(.react-select__control--menu-is-open .react-select__dropdown-indicator) {
        transform: rotate(180deg);
    }

    :global(.react-select__menu) {
        border-radius: var(--r-sm);
        box-shadow: 0 2px 15px 0 $c-additional-shadow-blue-a4;
        width: auto;
        z-index: 10;
    }

    :global(.react-select__menu-list) {
        @include scrollbar;

        margin-bottom: 4px;
        margin-right: 4px;
        margin-top: 4px;
        padding-bottom: 0;
        padding-right: 4px;
        padding-top: 0;
    }

    :global(.react-select__option) {
        @include f-p-medium;

        color: $c-default-gray-100;
        cursor: pointer;
        min-width: 92px;
        padding: var(--p-xxs);
        text-align: left;
    }

    :global(.react-select__option--is-focused) {
        background-color: $c-additional-custom-hovers-on-component;
        color: $c-default-gray-80;
    }

    :global(.react-select__option--is-selected) {
        align-items: center;
        background-color: transparent;
        color: $c-default-gray-100;
        display: flex;
        gap: 8px;
        justify-content: space-between;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    :global(.react-select__indicator-separator) {
        display: none;
    }

    :global(.react-select__value-container) {
        padding: 2px 4px 2px 0;
    }

    :global(.react-select__single-value) {
        @include f-p;

        color: $c-default-gray-100;
        margin: 0;
    }

    :global(.react-select__dropdown-indicator) {
        color: $c-default-gray-100;
        padding: 0;
        pointer-events: none;
        transition: transform $animation;

        svg {
            height: 16px;
            width: 16px;
        }
    }
}
