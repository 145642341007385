@import 'src/styles/mixins';

.root {
    background-color: $c-background-accent-component;
    border-radius: var(--p-md);
    padding: var(--p-md) var(--p-sm);
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--p-md);
}

.options {
    border-bottom: 1px solid $c-primary-20;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    row-gap: 8px;
}

.optionsItem {
    display: flex;
    justify-content: space-between;
}

.bottom {
    display: flex;
    flex-direction: column;
    gap: var(--p-sm);
}

.agreeInfo {
    color: $c-default-gray-60;

    a {
        color: $c-primary-70;
    }
}
