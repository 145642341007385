@import 'src/styles/mixins';

.filterTooltipModalOverlay {
    display: none;

    @media #{$screen-mobile} {
        display: block;
        z-index: 12;
    }
}

.filterTooltipModal {
    border-radius: 4px 4px 0 0;
    bottom: 0;
    display: block;
    height: auto;
    padding: 40px 24px 36px;
    position: absolute;
    top: unset;
    z-index: 12;

    button[data-modal-action='close'] {
        &:first-child {
            color: $c-default-black-100;
            top: 12px;
        }
    }
}

.filterTooltipModalText {
    margin-bottom: 16px;
}
