@import 'src/styles/mixins';

.filterListContainer {
    @include padding-container-mobile;

    align-self: flex-start;
    background-color: $c-default-gray-10;
    border-radius: var(--p-sm);
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 32px 4px 32px 24px;
    width: 100%;

    @media #{$screen-mobile} {
        height: 100%;
        left: 0;
        max-width: unset;
        opacity: 1;
        position: fixed;
        top: 0;
        transition: opacity $animation-in-out-1;
        width: 100%;
        z-index: 11;
    }
}

.filterListScrollContainer {
    @include mobile-negative-margin-with-padding;

    align-content: start;
    color: $c-default-gray-100;
    flex: 1 1 auto;
    grid-template-rows: min-content;
    row-gap: 32px;

    @media #{$screen-desktop} {
        padding-right: 20px;
        row-gap: 40px;
    }
}

.filterListHidden {
    margin-right: 0;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;

    @media #{$screen-mobile} {
        transition: opacity $animation-in-out-1;

        > * {
            opacity: 0;
        }
    }
}

.filterListTitle {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @include show-mobile-hide-desktop(flex);
}

.filterListTitleClose {
    color: $c-default-gray-100;
    height: 24px;
    width: 24px;

    svg {
        color: $c-default-gray-60;
    }
}

.filterListTooltipIcon {
    border: 0;
    color: $c-default-gray-60;
    cursor: pointer;
    height: 20px;
    margin-left: 4px;
    padding: 0;
    transform: none;
    transition: color $animation;
    width: 20px;

    &:hover,
    &:focus {
        color: $c-primary-50;
    }
}

.filterListItemTitle {
    align-items: center;
    display: flex;
}

.filterListTooltipContainer {
    position: absolute;
    z-index: 3;
}

.filterListTooltip {
    background-color: $c-default-white-100;
    border-radius: 4px;
    bottom: 46px;
    box-shadow: 0 2px 25px $c-additional-shadow-blue-a3;
    display: none;
    left: -10px;
    max-width: 349px;
    padding: 24px;
    position: absolute;
    text-align: start;
    width: 349px;

    &.displayed {
        display: block;

        @media #{$screen-mobile} {
            display: none;
        }
    }

    &::after {
        background-color: $c-default-white-100;
        bottom: -6px;
        content: '';
        height: 12px;
        left: 16px;
        position: absolute;
        transform: rotate(45deg);
        width: 12px;
    }
}

.filterListItemCollapse {
    overflow: hidden;
}

.filterListButton {
    @include mobile-negative-margin-with-padding;
    @include show-mobile-hide-desktop(flex);

    background-color: $c-default-gray-10;
    box-shadow: 0 -1px 4px $c-default-gray-34;
    height: 72px;
    margin-top: auto;
    padding-bottom: 16px;
    padding-top: 16px;

    @media #{$screen-mobile} {
        bottom: 0;
        position: fixed;
        width: 100%;
    }
}
