@import 'src/styles/mixins';
@import 'src/styles/font';

.totalPriceBlock {
    align-items: center;
    column-gap: 16px;
    display: flex;
    justify-content: space-between;
}

.totalPriceAndSale {
    align-items: center;
    column-gap: var(--p-xs);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    &.cartResume {
        flex-direction: row-reverse;
    }
}

.hideTotalTitle {
    .totalPriceAndSale {
        justify-content: flex-start;
    }
}

.totalPriceCurrency {
    margin-left: 2px;
}

.oldPrice {
    color: $c-additional-price-old;
    position: relative;

    &::before {
        background-color: $c-additional-price-old;
        content: '';
        height: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}

.cartResume {
    align-items: flex-end;
}
