@import 'src/styles/mixins';

.root {
    display: grid;
    gap: var(--p-xs);
    grid-template-columns: 170px auto;

    @media #{$screen-mobile} {
        grid-template-columns: 1fr;
    }
}

.info {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-16);
}

.item {
    aspect-ratio: 1;
    border: 1px solid $c-secondary-15;
    border-radius: var(--r-md);
    overflow: hidden;

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}
