@import 'src/styles/mixins';

.slider {
    overflow: hidden;

    &:global(.swiper-horizontal) {
        touch-action: pan-y;
    }

    &:global(.swiper-vertical) {
        touch-action: pan-x;
    }

    :global(.swiper-wrapper) {
        display: flex;
    }

    :global(.swiper-slide) {
        display: grid;
        flex-shrink: 0;
    }

    :global(.swiper-pagination) {
        display: none;
        overflow: hidden;

        @media #{$screen-mobile} {
            left: 50%;
            max-width: 112px;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    :global(.swiper-pagination-lock) {
        display: none;
    }

    :global(.swiper-pagination-bullet) {
        background-color: $c-default-gray-30;
        border-radius: 1px;
        flex-shrink: 0;
        height: 2px;
        position: relative;
        transition: left $animation;
        width: 16px;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    :global(.swiper-pagination-clickable) {
        cursor: pointer;
    }

    :global(.swiper-pagination-bullet-active) {
        background-color: $c-primary-100;
    }

    :global(.swiper-button-lock) {
        display: none;
    }
}

.wrapper {
    height: inherit;
    position: relative;
    width: 100%;
}

.navigation {
    background-color: $c-default-white-100;
    border-radius: 50%;
    box-shadow: 0 2px 12px 0 $c-shadow-slider-buttons;
    box-shadow: 0 2px 12px $c-shadow;
    cursor: pointer;
    height: 48px;
    position: absolute;
    top: calc(50% - 24px);
    transition: opacity $animation, visibility $animation, color $animation;
    width: 48px;
    z-index: 1;

    .hideNavigation & {
        display: none;
    }

    @media #{$screen-mobile} {
        display: none;
    }

    svg {
        height: 24px;
        width: 24px;
    }

    &:hover {
        color: $c-secondary-100;
        transition: color $animation;
    }

    &:active {
        color: $c-secondary-70;
        transition: color $animation;
    }

    &:disabled {
        background-color: $c-default-white-a70;
        color: $c-default-gray-50;
        cursor: auto;
    }

    &.prev {
        left: -24px;
        rotate: -180deg;

        &.vertical {
            left: calc(50% - 24px);
            top: -24px;
            transform: rotate(90deg);
        }
    }

    &.next {
        right: -24px;

        &.vertical {
            bottom: -24px;
            left: calc(50% - 24px);
            top: unset;
            transform: rotate(90deg);
        }
    }

    &:global(.swiper-button-disabled) {
        background-color: $c-default-white-a70;
        cursor: auto;

        &::before {
            background-color: $c-default-gray-60;
        }

        &:hover {
            background-color: $c-default-white-a70;
        }

        .hideDisableButton & {
            display: none;
        }
    }
}
