@import 'src/styles/mixins';

.root {
    display: grid;
    grid-template-areas:
        'image content quantity  total'
        'image content quantity total';
    grid-template-columns: [image] max-content [content] 400px [total] 1fr;

    @media #{$screen-mobile} {
        grid-template-areas:
            'image content content content'
            'image content content content'
            'image content content content';
        grid-template-columns: [image] min-content [content] fit-content(100%) [total] 1fr;
    }

    .image {
        border-radius: var(--r-sm);
        grid-area: image;
        object-fit: cover;

        @include fluid-prop-sm(margin-right, 16px, 24px);
        @include fluid-prop-square-sizes-sm(65px, 128px);
        @include fluid-prop-sm(min-width, 65px, 128px);

        @media #{$screen-mobile} {
            margin-bottom: 24px;
        }
    }
}

.content {
    display: flex;
    gap: 16px;
}

.contentText {
    display: flex;
    flex-direction: column;
    grid-area: content;
    row-gap: 8px;
}

.title {
    @include ellipsis(2);
    @include fluid-prop-sm(height, 40px, 48px);
    @include fluid-prop-sm(margin-bottom, 8px, 0);
}

.actionsDesktop {
    @include hide-mobile-show-desktop(flex);

    gap: 16px;
}

.pricePerOneDesktop {
    @include hide-mobile-show-desktop;

    grid-area: total;
    margin: auto 0;
    text-align: end;
}

.actionsMobile {
    @include show-mobile-hide-desktop(flex);

    align-items: flex-end;
    gap: 8px;
    grid-area: total;
    justify-content: flex-end;

    button,
    a {
        max-height: 32px;
    }

    button {
        width: 32px;
    }
}

.pricePerOneMobile {
    @include show-mobile-hide-desktop;

    grid-area: total;
    grid-column: unset;
}
