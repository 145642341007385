@import 'src/styles/mixins';

.root {
    background-color: $c-default-gray-10;
    display: flex;
    gap: 16px;

    @media #{$screen-mobile} {
        flex-direction: column;
    }
}

.button {
    max-width: 200px;

    @media #{$screen-mobile} {
        max-width: unset;
    }
}
