@import 'src/styles/mixins';

.root {
    border-radius: var(--r-lg);
    overflow: hidden;
    position: relative;

    .img {
        position: relative;

        &::before {
            background-image: $c-gradient-gray-vertical-top;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }

        img {
            object-fit: cover;
        }
    }

    .content {
        bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        left: 24px;
        position: absolute;
        z-index: 2;

        @media #{$screen-mobile} {
            bottom: 16px;
            left: 16px;
        }
    }

    &:nth-child(4n+1) {
        grid-column: span 2;
    }

    &:nth-child(4n+4) {
        grid-column: span 2;
    }
}
