@import 'src/styles/mixins';

.radioButton {
    display: flex;
    position: relative;
}

.radio {
    display: none;

    &:checked {
        & + .label {
            &::before {
                background-color: $c-secondary-100;
                border-color: $c-secondary-100;
            }

            &::after {
                opacity: 1;
                transition: opacity $animation;
            }

            &:hover {
                &::after {
                    background-color: $c-default-gray-30;
                    transition: background-color $animation;
                }
            }
        }
    }

    &:disabled {
        cursor: auto;

        & + .label {
            cursor: auto;

            .text {
                color: $c-default-gray-50;
            }

            &::before {
                background-color: $c-default-gray-10;
                border-color: $c-default-gray-20;
                cursor: auto;
            }

            &::after {
                background-color: $c-default-gray-10;
            }

            &:hover {
                &::before {
                    border: 1px solid $c-default-gray-20;
                }
            }
        }

        &:checked {
            & + .label {
                &::before {
                    background-color: $c-default-gray-30;
                    border-color: $c-default-gray-30;
                }

                &::after {
                    background-color: $c-default-gray-20;
                }

                &:hover,
                &:focus {
                    &::before {
                        background-color: $c-default-gray-30;
                        border-color: $c-default-gray-30;
                    }
                }
            }
        }
    }

    & + .isError {
        &::before {
            border-color: $c-status-error-50;
        }
    }
}

.label {
    border-radius: var(--r-xxs);
    display: flex;
    gap: 8px;
    outline: 1px solid transparent;
    position: relative;

    &::after {
        background-color: $c-default-white-100;
        border-radius: 50%;
        content: '';
        display: block;
        height: 10px;
        left: 7px;
        opacity: 0;
        position: absolute;
        top: 7px;
        transition: opacity $animation, background-color $animation;
        width: 10px;
    }

    &::before {
        background-color: $c-default-white-100;
        border: 1px solid $c-secondary-70;
        border-radius: 50%;
        content: '';
        cursor: pointer;
        display: block;
        flex-shrink: 0;
        height: 24px;
        position: relative;
        transition: border $animation;
        width: 24px;
    }

    &:focus {
        outline-color: $c-default-gray-100;
    }

    &:hover {
        &::before {
            border: 2px solid $c-secondary-70;
            transition: border $animation;
        }
    }
}

.text {
    @include fluid-prop-sm(margin-top, 2px, 0);

    color: $c-default-gray-100;
}
