@import 'src/styles/mixins';

.productPage {
    display: flex;
    flex-direction: column;
    gap: var(--p-lg);
    margin-top: 32px;

    @media #{$screen-mobile} {
        margin-top: 0;
    }

    .visualCardMainImage {
        display: none;
    }

    &.visual {
        .visualCardMainImage {
            display: block;

            @media #{$screen-mobile} {
                display: none;
            }
        }
    }

    .productCardAdaptiveSidebar {
        display: none;

        @media #{$screen-mobile} {
            display: flex;
        }
    }
}

.backButtonContainer {
    @include container;
    @include show-mobile-hide-desktop;

    padding-bottom: 12px;
    padding-top: 8px;
}

.header {
    left: 0;
    overflow: hidden;
    position: fixed;
    top: -40px;
    transition: top $animation;
    z-index: 5;

    @media #{$screen-mobile} {
        overflow-x: scroll;
        padding-bottom: 8px;
        padding-top: 8px;
        top: -120px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    &.visible {
        top: var(--header-height);

        @media #{$screen-mobile} {
            overflow-x: auto;
            top: var(--header-height-adaptive);
        }
    }
}

.mainContainer {
    row-gap: var(--p-sm);
}

.secondContainer {
    display: flex;
    flex-direction: column;
    row-gap: var(--p-md);
}

.productSet {
    @include fluid-prop-sm(margin-top, 32px, 56px);
}

.productImagesShowMore {
    margin-bottom: 24px;

    @include hide-mobile-show-desktop(flex);
}

.more {
    display: flex;
    flex-direction: column;
    gap: var(--p-xl);
}
