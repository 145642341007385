@import 'src/styles/mixins';

.collectionCard {
    max-width: 282px;
    width: 100%;
}

.collectionCardContent {
    display: flex;
    flex-direction: column;
}

.collectionQuantity {
    margin-top: 12px;
}

.collectionTitle {
    margin: 4px 0;

    &.isPreview {
        @include ellipsis(2);

        min-height: 56px;

        @media #{$screen-mobile} {
            max-width: 156px;
        }
    }
}

.imageContainer {
    position: relative;
    width: 100%;

    &.isPreview {
        @media #{$screen-mobile} {
            height: 156px;
            width: 156px;
        }
    }

    &[data-skeleton-item] {
        min-height: 282px;

        @media #{$screen-mobile} {
            min-height: 156px;
        }
    }
}

.link {
    display: block;
    width: 100%;
}
