@import 'src/styles/mixins';

.root {
    gap: 0;
}

.listItem {
    position: relative;

    &::after {
        background-color: $c-default-gray-20;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        width: 100%;
    }

    &:last-child {
        &::after {
            content: none;
        }
    }

    @media #{$screen-mobile} {
        &::after {
            content: none;
        }
    }
}
