@import 'src/styles/mixins';

.galleryBlock {
    .visualCardGallery {
        display: none;
    }

    &.technical {
        display: grid;
        gap: 24px;
        grid-template-columns: 1fr 1fr;

        @media #{$screen-mobile} {
            display: flex;
            justify-content: center;

            .sliderContainer {
                width: 100%;
            }
        }
    }

    &.visual {
        .gallerySlider {
            display: none;

            @media #{$screen-mobile} {
                display: flex;
            }
        }

        .visualCardGallery {
            display: grid;

            @media #{$screen-mobile} {
                display: none;
            }
        }
    }
}

.sliderContainer {
    @media #{$screen-mobile} {
        border-radius: 0;
        margin-left: -16px;
        margin-right: -16px;
    }
}

.noImage {
    border-radius: var(--r-xl);

    &:global(.no-image-component) {
        aspect-ratio: auto;
        height: 588px;
    }
}
