@import 'src/styles/mixins';
@import 'src/styles/mixins/color';
@import 'src/styles/font';

.searchPanelProduct {
    background-color: $c-default-gray-10;
    border-radius: var(--r-md);
    cursor: pointer;
    display: flex;
    gap: var(--p-xs);
    padding: var(--p-xs);
    transition: background-color $animation;

    &:focus {
        outline: 1px solid $c-default-gray-10 $c-secondary-100;
    }

    &:hover {
        background-color: $c-default-gray-20;
    }

    &:active {
        background-color: $c-default-gray-30;
    }

    &Image {
        border-radius: var(--r-sm);
        flex-shrink: 0;
        height: 64px;
        overflow: hidden;
        width: 64px;
    }

    &Description {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: var(--p-xs);
    }
}

.title {
    @include ellipsis(2);
}

.bottom {
    display: flex;
    justify-content: space-between;
}
