@import 'src/styles/mixins';

.contentContainer {
    position: relative;
}

.headerContainer {
    align-items: baseline;
    column-gap: 12px;
    display: flex;

    @include fluid-prop-sm(margin-bottom, 12px, 24px);
}

.title {
    @include ellipsis(2);

    @media #{$screen-mobile} {
        @include ellipsis(3);
    }
}
