@import 'src/styles/mixins';

.root {
    display: grid;
    gap: var(--p-sm);
    grid-auto-rows: 455px;
    grid-template-columns: repeat(3, 1fr);

    @media #{$screen-mobile} {
        grid-auto-rows: 264px;
        grid-template-columns: repeat(2, 1fr);
    }
}
