@import 'src/styles/mixins';

.desktopHeaderBanner {
    position: relative;

    img {
        aspect-ratio: 2.9;
        object-fit: cover;
        object-position: center;
    }

    @media #{$screen-mobile} {
        img {
            aspect-ratio: 1.7;
        }
    }
}

.content {
    @include fluid-prop-sm(left, 12px, 20px);
    @include fluid-prop-sm(right, 12px, 20px);
    @include fluid-prop-sm(bottom, 12px, 20px);

    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    z-index: 2;
}
