@import 'src/styles/mixins';

.cartPopupProductItem {
    border-bottom: 1px solid $c-default-gray-30;
    display: flex;
    gap: var(--p-xs);
    padding: var(--p-xs) var(--p-xxs) var(--p-sm) var(--p-xxs);

    .infoContainer {
        display: flex;
        flex-direction: column;
        gap: var(--p-sm);
    }

    .mainInfoWrap {
        display: flex;
        flex-direction: column;
        gap: var(--p-xxs);
    }

    .mainInfo {
        column-gap: var(--p-sm);
        display: flex;
        justify-content: space-between;
    }

    .headerPopupPrice {
        align-items: center;
        color: $c-default-gray-40;
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
    }

    .counterAndPrice {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .counterContainer {
            max-width: 100px;
        }
    }

    .subscriptionButton {
        max-width: 140px;
    }

    .finalOffer {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        margin-left: 16px;
        max-width: 152px;
        min-width: 96px;

        @media #{$screen-mobile} {
            max-width: unset;
        }
    }

    .headerPopupFinalPrice {
        display: block;
    }

    .iconWrap {
        svg {
            height: 32px;
            width: 32px;
        }
    }

    .userActions {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        margin-top: 8px;

        @media #{$screen-mobile} {
            align-items: flex-start;
            margin-left: 0;
            margin-top: 12px;
            min-width: auto;
        }
    }

    .totalNumber {
        color: $c-default-gray-50;
        display: block;
        margin-top: 4px;

        &.outOfStock {
            color: $c-status-error-50;
        }
    }

    .price {
        color: $c-default-gray-70;
        margin-top: 2px;

        @media #{$screen-mobile} {
            margin-top: 4px;
        }
    }

    .headerPopupPriceCurrency {
        margin-left: 2px;
    }
}

.iconWrap {
    svg {
        height: 20px;
        width: 20px;
    }
}
