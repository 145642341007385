@import 'src/styles/mixins';
@import 'src/styles/font';

.productCardFilesContainer {
    display: flex;
    flex-direction: column;
}

.productCardFiles {
    display: grid;
    gap: 12px 12px;
    grid-auto-flow: row;
    grid-template: 1fr / 1fr 1fr;

    @media #{$screen-mobile} {
        grid-template: 1fr / 1fr;
    }
}

.productCardFilesHeaderTitle {
    margin-bottom: 16px;
}

.productCardFile {
    align-items: center;
    display: flex;

    @include fluid-prop-sm(grid-column-gap, 8px, 16px);

    svg {
        width: 32px;
    }
}

.productCardFileInfo {
    display: flex;
    flex-direction: column;
    width: 268px;
}

.productCardFileTitle {
    @include f_p('xs');
}

.productCardFileSize {
    color: $c-default-gray-60;

    @include f_p('xs');
}

.productCardFileHeaderTitle {
    color: $c-default-gray-100;
}

.productCardFilesButton {
    margin-top: 16px;

    svg {
        transform: rotate(270deg);
    }

    &.filesHidden {
        svg {
            transform: rotate(90deg);
        }
    }
}
