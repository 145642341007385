@import 'src/styles/mixins';

.content {
    display: grid;
    gap: var(--p-sm);
    grid-template-columns: 1fr 1fr;

    @media #{$screen-mobile} {
        grid-template-columns: 1fr;
    }

    &.withoutImage {
        grid-template-columns: 1fr;
    }
}

.leftSide {
    border-radius: var(--r-lg);
    overflow: hidden;

    @media #{$screen-mobile} {
        height: 292px;
    }

    img {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}

.rightSide {
    border: 1px solid $c-secondary-30;
    border-radius: var(--r-lg);
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: var(--p-md);

    @media #{$screen-mobile} {
        gap: 24px;
    }
}

.rightSideTop {
    display: flex;
    flex-direction: column;
    gap: var(--p-sm-24);
}

.advantagesList {
    display: grid;
    gap: var(--p-sm);
    grid-template-columns: 1fr 1fr;

    @media #{$screen-mobile} {
        grid-template-columns: 1fr;
    }
}

.advantagesItem {
    align-items: center;
    display: flex;
    gap: 16px;
    grid-column: span 1;
}

.advantagesIcon {
    align-items: center;
    background-color: $c-secondary-15;
    border-radius: 50%;
    color: $c-secondary-100;
    display: flex;
    height: 48px;
    justify-content: center;
    min-width: 48px;

    @media #{$screen-mobile} {
        height: 32px;
        min-width: 32px;
    }

    svg {
        height: 24px;
        width: 24px;

        @media #{$screen-mobile} {
            height: 20px;
            min-width: 20px;
        }
    }
}

.rightSideBottom {
    display: flex;
    flex-direction: column;
    gap: var(--p-sm);
}

.rightSideBottomInner {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rightSideBottomBtn {
    margin-top: auto;
    max-width: 210px;

    @media #{$screen-mobile} {
        min-width: 194px;
    }
}
