@import 'src/styles/mixins';

.container {
    row-gap: var(--p-xs-16);

    @media #{$screen-mobile} {
        padding-bottom: 24px;
    }
}

.title {
    @include ellipsis(2);

    @media #{$screen-mobile} {
        @include ellipsis(3);
    }
}
