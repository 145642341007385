@import 'src/styles/mixins';

.breadCrumbs {
    padding-bottom: 16px;

    @include container;

    @media #{$screen-mobile} {
        display: none;
    }

    &.notDisplay {
        display: none;
    }
}

.breadCrumbsList {
    align-items: center;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.breadCrumbsItem {
    color: $c-default-gray-50;
    position: relative;

    & + & {
        margin-left: 24px;
    }

    &::before {
        background-color: $c-default-gray-50;
        content: '';
        height: 1px;
        left: -14px;
        position: absolute;
        top: 50%;
        width: 8px;
    }

    &:first-child {
        &::before {
            display: none;
        }
    }

    a {
        color: $c-default-gray-50;
        transition: color $animation;

        &:hover,
        &:focus {
            color: $c-primary-60;
            outline: none;
        }
    }
}

.breadCrumbsItemLinkDisabled {
    background-color: $c-default-gray-50;
    pointer-events: none;
}
