@import 'src/styles/mixins';

.inner {
    padding-bottom: var(--p-xl);
    padding-top: var(--p-xl);
}

.content {
    height: 460px;

    @media #{$screen-mobile} {
        height: 350px;
        margin-right: -16px;
    }
}

.subtitle {
    @include hide-mobile-show-desktop;
}

.slider {
    height: 460px;
    overflow: hidden;

    @media #{$screen-mobile} {
        height: 350px;
    }

    .slideContent {
        border: none;

        :global {
            .simple-product-flex-wrapper,
            .product-image-switcher-inner {
                background-color: $c-default-white-100;
            }
        }
    }

    :global {
        .swiper-slide {
            height: 100%;
            max-width: 282px;
            overflow: hidden;

            @media #{$screen-mobile} {
                max-width: 200px;
            }

            &:first-child {
                max-width: 588px;

                @media #{$screen-mobile} {
                    max-width: 282px;
                }
            }
        }

        .swiper-wrapper {
            height: 100%;
        }
    }
}

.banner {
    border-radius: var(--r-md);
    overflow: hidden;
}

.image {
    img {
        object-fit: cover;
    }
}
