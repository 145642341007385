@import 'src/styles/mixins';

.root {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 92px;
    justify-content: center;
    outline-offset: -1px;
    overflow: hidden;
    padding: 12px 32px;
    position: relative;
    width: 180px;
}

.image {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.hoverLayout {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color $animation;
    width: 100%;

    &:hover {
        background-color: $c-default-white-a10;
    }
}
