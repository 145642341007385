@import 'src/styles/mixins';

.regularModal {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}

.regularModalIsHidden {
    display: none;
}

.regularModalIsVisible {
    display: flex;
}

.regularModalIsHiddenAnimated {
    visibility: hidden;
}

.regularModalIsVisibleAnimated {
    visibility: visible;
}

.regularModalContent {
    background-color: $c-default-white-100;
    border-radius: var(--r-xl);
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 72px * 2);
    max-width: 524px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 2;
}

.regularModalAdaptive.regularModalContent {
    @media #{$screen-mobile} {
        border-radius: 0;
        height: 100%;
        left: 0;
        max-height: none;
        max-width: unset;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.regularModalClose {
    @include fluid-prop-sm(top, 12px, 16px);

    color: $c-default-gray-100;
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 16px;
    transition: color $animation;
    width: 24px;
    z-index: 2;

    &:hover {
        color: $c-default-gray-100;
    }

    svg {
        height: 24px;
        width: 24px;
    }
}
