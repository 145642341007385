@import 'src/styles/mixins';

.actions {
    align-items: center;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, minmax(144px, 1fr));
    margin-top: 20px;

    @media #{$screen-mobile} {
        gap: 16px;
        grid-template-columns: 1fr;
        margin-top: 24px;
    }

    @supports (-webkit-touch-callout: none) {
        @media not all and (max-width: 1280px) {
            gap: 16px;
            grid-template-columns: 1fr;
            margin-top: 24px;
        }
    }
}

.container {
    background-color: $c-default-white-100;
    border-radius: 4px;
    box-shadow: 0 2px 25px $c-additional-shadow-blue-a2;
    margin-top: 14px;
    min-width: 250px;
    padding: 16px 12px 12px;
    position: absolute;
    z-index: 11;

    @supports (-webkit-touch-callout: none) {
        @media not all and (max-width: 1280px) {
            display: none;
        }
    }
}

.modal {
    bottom: 0;
    height: auto;
    position: absolute;
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    row-gap: 8px;
}

.close {
    color: $c-default-gray-60;
    cursor: pointer;
    display: flex;

    svg {
        height: 24px;
        width: 24px;
    }
}
