.root {
    flex-shrink: 0;
    height: 100% !important;
    width: 100% !important;

    :global(.react-transform-component) {
        height: 100%;
        width: 100%;
    }

    img {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }
}
