@import 'src/styles/mixins';
@import 'src/styles/font';

.root {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.checkMarkIcon {
    color: $c-primary-60;
    height: 20px;
    opacity: 0;
    transition: opacity $animation;
    width: 20px;
}

.item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    padding-right: 12px;
    padding-top: 12px;
}

.input {
    display: none;

    &:checked + .label {
        color: $c-primary-60;
    }

    &:checked ~ svg {
        opacity: 1;
    }
}

.label {
    color: $c-default-gray-80;
    cursor: pointer;
    transition: color $animation;
    width: 100%;
}

.name {
    @include f_p('lg');
}

.list {
    @include scrollbar;

    height: 100%;
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0;
    position: relative;
}

.infoItem {
    padding-bottom: 12px;
    padding-right: 12px;
    padding-top: 4px;
}

.infoText {
    color: $c-default-gray-60;
}

.searchInputContainer {
    padding-bottom: 8px;
    padding-top: 4px;
}
