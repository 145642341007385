@import 'src/styles/mixins';

.servicePageContainer {
    @include container;

    @media #{$screen-mobile} {
        margin-top: 12px;
    }
}

.servicePageTitle {
    @media #{$screen-mobile} {
        margin-top: 8px;
    }
}

.servicePageList {
    @include fluid-prop-sm(gap, 12px, 24px);
    @include fluid-prop-sm(margin-top, 28px, 32px);

    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

.servicePageCard {
    @include fluid-prop-sm(width, 156px, 282px);
    @include fluid-prop-sm(padding, 12px, 32px);

    height: 100%;
    min-height: 218px;

    @media #{$screen-mobile} {
        min-height: 148px;
    }
}
