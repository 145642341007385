@import 'styles/mixins';

.favourite {
    line-height: 0;

    label {
        color: $c-default-gray-100;
        cursor: pointer;
        display: inline-block;
        transition: color $animation;
    }

    &:hover {
        label {
            color: $c-primary-30;
        }
    }

    input {
        display: none;

        &:checked ~ label {
            color: $c-additional-shields-sale;
        }
    }

    svg {
        display: block;
        height: 24px;
        width: 24px;
    }
}
