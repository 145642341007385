@import 'src/styles/mixins';

.paymentList {
    @include fluid-prop-sm(row-gap, 8px, 24px);
}

.paymentItem {
    padding: 16px;
}

.paymentIcon {
    margin-left: auto;
}
