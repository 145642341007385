@import 'src/styles/mixins';

.card {
    height: 100%;
    width: 100%;
}

.emptyProductList {
    margin-top: var(--p-xs);

    @media #{$screen-mobile} {
        margin-top: var(--p-xl);
    }
}

.grid {
    @include fluid-prop-sm('grid-row-gap', 16px, 24px);

    grid-auto-flow: row dense;

    &.tile {
        @media #{$screen-mobile} {
            .withSeparator {
                position: relative;

                &::after {
                    background-color: $c-additional-gray-blue-20;
                    bottom: 0;
                    content: '';
                    display: block;
                    height: 1px;
                    left: -$padding-on-border;
                    position: absolute;
                    width: 100vw;
                }
            }
        }
    }
}

.productListItem {
    height: 100%;
    width: 100%;
}
