@import 'src/styles/mixins';

.root {
    column-gap: 24px;
    row-gap: 24px;
}

.desktopCount {
    @include hide-mobile-show-desktop;
}

.listLayoutPaginationTotal {
    margin-left: 4px;
}

.bottomContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media #{$screen-mobile} {
        justify-content: center;
    }
}

.listLayoutPaginationContainer {
    display: flex;
    justify-content: center;
}

.listLayoutShowMoreContainer {
    align-items: center;
    display: flex;
    justify-content: center;
}

.listLayoutPaginationStatusDesktop {
    @include hide-mobile-show-desktop;
}

.listLayoutPaginationProgressContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.listLayoutPaginationProgress {
    margin-bottom: 16px;
    text-align: center;
    width: 100%;

    @include show-mobile-hide-desktop;
}

.listLayoutPaginationProgressBar {
    background-color: $c-primary-10;
    border-radius: 4px;
    height: 4px;
    margin-top: 8px;
    width: 260px;
}

.listLayoutPaginationProgressBarFilled {
    background-color: $c-primary-70;
}
