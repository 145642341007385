@import 'src/styles/mixins';

.menu {
    background-color: $c-default-white-100;
    height: calc(100vh - var(--header-height));
    overflow: auto;
    padding: 8px 0;
    position: fixed;
    top: var(--header-height);
    width: 100%;
    z-index: 6;

    @media #{$screen-mobile} {
        height: calc(100% - var(--bottom-bar-height));
        top: 0;
    }

    .menuTop {
        padding: 0 16px;
    }

    .menuBack {
        color: $c-default-gray-50;
        margin-bottom: 16px;
    }

    .title {
        margin-bottom: 16px;
    }

    .menuList {
        display: flex;
        flex-direction: column;

        .menuItem {
            border-bottom: 1px solid $c-additional-gray-blue-20;
        }

        .linkContent {
            align-items: center;
            display: flex;
            gap: 8px;
            padding: var(--p-xs-16) var(--p-sm);
        }
    }
}

.menuLink {
    img,
    svg {
        height: 24px;
        width: 24px;
    }
}
