@import 'src/styles/mixins';
@import 'src/styles/font';

.datePickerCalendar {
    :global(.react-datepicker-wrapper) {
        display: block;
        width: 100%;
    }

    :global(.react-datepicker-popper) {
        z-index: 100;
    }

    :global(.react-datepicker) {
        background-color: $c-default-gray-10;
        border: 0;
        border-radius: var(--r-lg);
        box-shadow: 0 2px 2px 0 $c-shadow-tooltip;
        padding: var(--p-xs-16);
        z-index: 100;
    }

    :global(.react-datepicker__triangle) {
        display: none;
        visibility: hidden;
    }

    :global(.react-datepicker__header) {
        background-color: transparent;
        border-bottom: 0;
        padding: 0;
    }

    :global(.react-datepicker__day-names) {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
    }

    :global(.react-datepicker__day-name) {
        @include f_p;

        align-items: center;
        color: $c-default-gray-100;
        display: flex;
        height: 40px;
        justify-content: center;
        margin: 0;
        text-transform: uppercase;
        width: 40px;
    }

    :global(.react-datepicker__month) {
        margin: 0;
        text-align: unset;
    }

    :global(.react-datepicker__month--selecting-range) {
        background-color: transparent;

        :global(.react-datepicker__day--in-range) {
            background-color: transparent;

            &:not(:global(.react-datepicker__day--weekend)) {
                color: $c-default-gray-80;
            }

            &:global(.react-datepicker__day--weekend) {
                color: $c-default-gray-60;
            }

            &:global(.react-datepicker__day--selected) {
                color: $c-default-white-100;
            }
        }
    }

    :global(.react-datepicker__day) {
        @include f_p;

        align-items: center;
        box-sizing: content-box;
        color: $c-default-gray-100;
        display: inline-flex;
        height: 40px;
        justify-content: center;
        line-height: 28px;
        margin: 0;
        width: 40px;

        &:hover {
            background-color: $c-secondary-70;
            border-radius: 50%;
            color: $c-default-white-100;
        }
    }

    :global(.react-datepicker__day--today) {
        @include f_p;
    }

    :global(.react-datepicker__day--weekend) {
        color: $c-status-error-50;
    }

    :global(.react-datepicker__day--disabled) {
        color: $c-default-gray-40;
        pointer-events: none;
    }

    :global(.react-datepicker__day--outside-month) {
        visibility: hidden;
    }

    :global(.react-datepicker__day--selected) {
        background-color: $c-secondary-100;
        border-radius: 50%;
        color: $c-default-white-100;
        position: relative;
        z-index: 1;

        &:hover {
            background-color: $c-secondary-100;
        }
    }

    :global(.react-datepicker__day--keyboard-selected) {
        background-color: transparent;
    }

    :global(.react-datepicker__day--in-range) {
        background-color: transparent;

        &:hover {
            background-color: transparent;
        }
    }

    :global(.react-datepicker__day--in-selecting-range) {
        background-color: transparent;
    }
}

.arrowIcon {
    bottom: 0;
    cursor: pointer;
    height: 20px;
    margin: auto 0;
    position: absolute;
    top: 0;

    svg {
        color: $c-default-gray-100;
        height: 20px;
        width: 20px;
    }

    &.left {
        left: 0;

        svg {
            transform: rotate(90deg);
        }
    }

    &.right {
        right: 0;

        svg {
            transform: rotate(-90deg);
        }
    }

    &:disabled {
        svg {
            color: $c-additional-gray-blue-50;
        }
    }
}

.headerContainer {
    align-items: baseline;
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    position: relative;
}

.monthName {
    margin-right: 6px;
    text-transform: capitalize;
}

.calendarIcon {
    svg {
        width: 24px;
    }
}

.calendarInput {
    width: 100%;
}

.calendarInputIcon {
    color: $c-default-gray-100;
}
