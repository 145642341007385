@import 'src/styles/mixins';
@import 'src/styles/font';

.searchPanelElement {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-16);
    width: 100%;
}

.searchPanelList {
    display: flex;
    flex-direction: column;
    gap: var(--p-sm-24);
}

.searchPanelLink {
    &:global(.anchor-component) {
        color: $c-secondary-100;
        cursor: pointer;
        gap: var(--p-xxxs);
        transition: color $animation;

        &:visited {
            color: $c-secondary-80;
        }

        &:active {
            color: $c-secondary-100;
        }

        &:hover {
            color: $c-secondary-70;
        }
    }
}
