@import 'src/styles/mixins';
@import 'src/styles/font';

.container {
    position: relative;

    @media #{$screen-mobile} {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.content {
    left: 32px;
    max-width: 426px;
    position: absolute;
    top: 32px;
    z-index: 1;
}

.title {
    margin-bottom: 32px;
}

.image {
    background-color: $c-additional-gray-blue-20;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}
