@import 'src/styles/mixins';

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &.withTopPadding {
        padding-top: calc(var(--header-height) + 48px);
    }

    @media #{$screen-mobile} {
        &.withTopPadding {
            padding-top: calc(var(--header-height-adaptive));
        }

        &.withAdaptiveAdditionalPadding {
            padding-top: calc(var(--header-height-adaptive) + 32px);
        }

        &.withoutTopPaddingOnMobile {
            padding-top: 0;
        }
    }
}

.layoutContent {
    flex-grow: 1;
    height: 100%;
    position: relative;

    @include fluid-prop-sm(margin-bottom, 56px, 80px);

    @media #{$screen-mobile} {
        padding-top: 0;
    }
}

.toastContainer {
    right: 16px;
    top: 178px;

    &:global(:has(.cart-notification)) {
        top: 85px;

        @media #{$screen-mobile} {
            top: 155px;
        }
    }

    @media #{$screen-mobile} {
        bottom: 0;
        top: auto;

        &.isHigher {
            bottom: 50px;
        }
    }
}
