@import 'src/styles/mixins';

.root {
    row-gap: 16px;
}

.content {
    align-items: flex-start;
    display: flex;
    gap: 32px;

    @media #{$screen-mobile} {
        flex-direction: column;
        gap: 16px;
    }
}

.titleWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    width: 100%;
}

.title {
    align-items: center;
    display: flex;

    @include fluid-prop-sm(gap, 8px, 16px);
}

.backButton {
    display: inline-flex;
}

.titleText {
    word-break: break-all;
}
