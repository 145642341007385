.svgIcon {
    height: auto;
    line-height: 0;
    pointer-events: none;
    vertical-align: middle;
}

.fillContainer {
    height: 100%;
    width: 100%;
}
