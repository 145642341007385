@import 'src/styles/mixins';
@import 'src/styles/font';

.textArea {
    background-color: $c-default-white-100;
    border-radius: var(--r-md);
    cursor: text;
    height: 122px;
    position: relative;
    width: 100%;

    &:hover {
        & .textAreaLabel {
            color: $c-default-gray-100;
            transition: color $animation, font-size $animation;
        }
    }
}

.textAreaLabel {
    left: 24px;
    position: absolute;
    top: 12px;
    transition: color $animation, font-size $animation;
    width: calc(100% - 20px);

    @include ellipsis(1);
}

.textAreaOriginal {
    border: none;
    color: $c-default-gray-100;
    height: calc(100% - 45px);
    margin: 32px var(--p-sm) 12px var(--p-sm);
    position: relative;
    resize: none;
    width: calc(100% - 50px);
    z-index: 2;

    @include f_p;

    &:focus {
        outline: none;

        & ~ .textAreaFrame {
            border: 1px solid $c-background-accent-block;
        }

        & ~ .textAreaLabel {
            color: $c-default-gray-60;

            @include f_p-medium;
        }
    }

    &:disabled {
        background-color: $c-default-gray-10;
        color: $c-default-gray-40;

        & ~ .textAreaFrame {
            background-color: $c-default-gray-10;
            pointer-events: none;
        }

        & ~ .textAreaLabel {
            color: $c-default-gray-40;
        }
    }
}

.textAreaOriginalNonLabel {
    background: transparent;
    height: calc(100% - 4px);
    margin-top: 1px;
}

.textAreaOriginalFilled {
    & ~ .textAreaFrame {
        border: 1px solid $c-primary-30;
    }

    & ~ .textAreaFrameError {
        border: 1px solid $c-status-error-50;
    }

    & ~ .textAreaFrameDisabled {
        border-color: transparent;
    }

    & ~ .textAreaLabel {
        color: $c-default-gray-60;

        @include f_p-medium;
    }

    &:focus {
        & ~ .textAreaFrame {
            border: 1px solid $c-primary-30;
        }
    }
}

.textAreaFrame {
    border: 1px solid $c-default-gray-30;
    border-radius: var(--r-md);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all $animation;
    width: 100%;
    z-index: 1;
}

.textAreaFrameError {
    border: 1px solid $c-status-error-50;
}

.textAreaFrameDisabled {
    background-color: $c-default-gray-10;
    border-color: transparent;
}
