@import 'src/styles/mixins';

.collectionSwitcherContainer {
    margin-bottom: 24px;
    max-width: 540px;

    @media #{$screen-mobile} {
        height: 40px;
        margin-bottom: 0;
        max-width: unset;
    }
}
