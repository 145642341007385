@import 'src/styles/mixins';

.content {
    left: 32px;
    position: absolute;
    top: 32px;
}

.title {
    margin-bottom: 32px;
}

.image {
    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}
