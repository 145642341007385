@import 'src/styles/mixins';

.noImage {
    align-items: center;
    background-color: $c-default-black-a10;
    border-radius: 2px;
    display: flex;
    height: 588px;
    justify-content: center;
    width: 100%;

    @media #{$screen-mobile} {
        border-radius: 0;
        height: 360px;
    }

    svg {
        color: $c-default-gray-40;
        height: 120px;
        width: 120px;

        @media #{$screen-mobile} {
            height: 64px;
            width: 64px;
        }
    }

    &.small {
        aspect-ratio: 1 / 1;
        height: 100%;
        width: 100%;

        svg {
            height: 32px;
            width: 32px;
        }

        &.list {
            height: 100px;
            width: 100px;

            svg {
                height: 32px;
                width: 32px;
            }
        }

        &.preview,
        &.best-selling,
        &.previewVisual {
            height: 180px;
            width: 180px;

            @media #{$screen-mobile} {
                height: 156px;
                width: 156px;
            }
        }
    }

    &.auto,
    &.preview,
    &.favourites {
        aspect-ratio: 1 / 1;
        height: 100%;
    }

    &.technical {
        @media #{$screen-mobile} {
            width: 100%;
        }

        svg {
            height: 64px;
            width: 64px;
        }
    }

    &.stretched {
        height: 100%;
        width: 100%;

        svg {
            height: 64px;
            width: 64px;
        }

        &.list {
            svg {
                height: 48px;
                width: 48px;
            }
        }
    }

    &.default {
        height: 100%;
        width: 100%;

        svg {
            @include fluid-prop-sm(height, 48px, 64px);
            @include fluid-prop-sm(width, 48px, 64px);
        }
    }

    &.heightAuto {
        height: auto;
    }
}
