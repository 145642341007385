@import 'src/styles/mixins';
@import 'src/styles/font';

.inputCaption {
    color: $c-default-gray-60;
    display: block;
    margin-top: 4px;
    text-align: left;
    width: 100%;
}

.inputCaptionDisabled {
    color: $c-default-gray-60;
}

.inputCaptionError {
    color: $c-status-error-50;
}

.inputCaptionSuccess {
    color: $c-status-success-50;
}
