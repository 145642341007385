@import 'src/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
