@import 'src/styles/mixins';

.bankCardList {
    align-items: center;
    display: flex;
    gap: var(--p-xxs);
    list-style: none;
    margin: 0;
    padding: 0;
}

.bankCardItem {
    height: 32px;
    width: 48px;

    svg {
        height: 32px;
        width: 48px;
    }
}
