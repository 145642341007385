@import 'src/styles/mixins';

.tabContainer {
    align-items: center;
    display: flex;
    width: max-content;
}

.tab {
    &:checked {
        & + .tabLabel {
            color: $c-default-gray-100;

            & > .tabDropDown {
                transform: rotate(180deg);
            }
        }
    }

    &:disabled {
        & + .tabLabel {
            color: $c-default-gray-100;
            pointer-events: none;

            & > .tabDropDown {
                color: $c-default-gray-50;
                pointer-events: none;
                transform: rotate(0deg);
            }
        }
    }

    &:focus + .tabLabel {
        box-shadow: inset 0 0 0 2px $c-default-gray-100;
    }
}

.tabLabel {
    border-radius: 4px;
    color: $c-default-gray-60;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    gap: 4px;
    overflow: hidden;
    position: relative;
    text-align: center;
    transition: color $animation, background-color $animation;
    width: 100%;
}

.tabDropDown {
    align-items: center;
    color: $c-default-gray-80;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: transform $animation;

    svg {
        height: 20px;
        width: 20px;
    }
}

.tabDropDownLabel {
    padding-left: 16px;
}
