@import 'src/styles/mixins';
@import 'src/styles/font';

.catalogMenu {
    background-color: $c-default-white-100;
    height: calc(100vh - var(--header-height));
    position: fixed;
    top: var(--header-height);
    width: 100%;
    z-index: 6;

    @media #{$screen-mobile} {
        height: calc(100% - var(--bottom-bar-height));
        top: 0;
    }
}

.catalogMenuSearchFieldContainer {
    @include container;

    display: none;

    @media #{$screen-mobile} {
        display: block;
        padding-bottom: 8px;
        padding-top: 8px;
    }
}

.catalogMenuSearchField {
    display: flex;

    @media #{$screen-mobile} {
        padding-bottom: 0;
    }
}

.catalogMenuContent {
    @include container;

    height: 100%;
    padding-top: 32px;
    position: relative;

    @media #{$screen-mobile} {
        height: calc(100% - var(--header-search-mobile-height));
        overflow-y: auto;
        padding-top: 0;
    }
}
