@import 'src/styles/mixins';

.tooltip {
    &:global(.rc-tooltip) {
        opacity: 1;
    }

    & :global(.rc-tooltip-content) {
        background-color: $c-default-white-100;
        box-shadow: 0 2px 25px 0 $c-shadow-large-over;
    }

    &:global(.rc-tooltip .rc-tooltip-inner) {
        background-color: inherit;
        box-shadow: none;
        color: $c-default-gray-100;
        padding: 24px;
    }

    &:global(.rc-tooltip-placement-left .rc-tooltip-arrow) {
        border-left-color: $c-default-white-100;
    }

    &:global(.rc-tooltip-placement-right .rc-tooltip-arrow) {
        border-right-color: $c-default-white-100;
        left: 5px;
    }

    &:global(.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow) {
        border-bottom-color: $c-default-white-100;
        top: 5px;
    }

    &:global(.rc-tooltip-placement-bottomRight .rc-tooltip-arrow) {
        border-bottom-color: $c-default-white-100;
    }
}
