@import 'src/styles/mixins';

.root {
    @include fluid-prop-sm(row-gap, 16px, 24px);

    @media #{$screen-mobile} {
        display: flex;
        flex-direction: column;
    }
}

.header {
    grid-row-gap: 16px;
}

.removeAccount {
    grid-column: 12;

    @media #{$screen-mobile} {
        margin-left: auto;
    }
}
