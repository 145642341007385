@import 'src/styles/mixins';
@import 'src/styles/font';

.root {
    @include fluid-prop-sm(row-gap, 32px, 40px);
}

.mainFormBlock {
    @include fluid-prop-sm(grid-row-gap, 32px, 48px);

    position: relative;
}

.mainFormBlockBtn {
    width: max-content;

    @media #{$screen-mobile} {
        width: 100%;
    }
}
