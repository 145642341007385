@import 'src/styles/mixins';

.list {
    display: grid;
    gap: var(--p-sm);
    grid-template-columns: 1fr 1fr 1fr;

    @media #{$screen-mobile} {
        grid-template-columns: 1fr;
    }
}

.listItem {
    border-radius: var(--r-lg);
    height: auto;
    overflow: hidden;
}
