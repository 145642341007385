@import 'src/styles/mixins';

.select {
    width: 146px;

    :global(.custom-select__control) {
        background-color: inherit;
        border: none;
        border-radius: 0;
        gap: var(--p-xxs);
        min-height: 24px;
        outline: none !important;
    }

    :global(.custom-select__control):hover {
        background-color: inherit;
        border: none;
        border-radius: 0;
        gap: var(--p-xxs);
        outline: none !important;

        :global(.custom-select__single-value) {
            color: $c-secondary-100;
        }

        .sortingSelectIndicator {
            color: $c-secondary-100;
        }
    }

    :global(.custom-select__control):focus {
        background-color: inherit;
        border: none;
        border-radius: 0;
        gap: var(--p-xxs);
        outline: none !important;

        .sortingSelectIndicator {
            color: $c-secondary-100;
        }
    }

    :global(.custom-select__value-container) {
        padding: 0;
    }

    :global(.custom-select__dropdown-indicator) {
        padding: 0;
    }

    :global(.custom-select__control--menu-is-open) {
        :global(.custom-select__single-value) {
            color: $c-secondary-100;
        }

        .sortingSelectIndicator {
            color: $c-secondary-100;
        }
    }

    :global(.custom-select__menu) {
        border-radius: var(--r-xxs);
        box-shadow: 0 2px 25px 0 $c-shadow-large-tooltip;
        padding: var(--p-xxs) 0;
    }

    :global(.custom-select__option) {
        align-items: center;
        gap: var(--p-xxs);
        height: 36px;
        justify-content: flex-start;
        padding: 0 var(--p-xs);

        svg {
            height: 20px;
            width: 20px;
        }

        &:hover {
            background-color: $c-additional-custom-hovers-on-component;
        }
    }
}

.sortingSelectIndicator {
    color: $c-default-gray-100;

    svg {
        height: 24px;
        width: 24px;
    }
}
