@import 'src/styles/mixins';

.input {
    display: none;
}

.chevronIcon {
    height: 24px;
    transform: rotate(0deg);
    transition: transform $animation-in-out;
    width: 24px;
}

.chevronIconCollapsed {
    transform: rotate(180deg);
}

.content {
    transition-duration: $animation-duration;
    transition-property: margin-top, max-height;
    transition-timing-function: ease-in-out;
}

.contentCollapsed {
    margin-top: 16px;
}

.label {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
