@import 'src/styles/mixins';

.cartPage {
    position: relative;
}

.container {
    @include container;
}

.titleUnit {
    @include hide-mobile-show-desktop;
}

.main {
    @include container;
    @include fluid-prop-sm(gap, 32px, 48px);

    display: flex;
    flex-direction: column;
    min-height: 300px;
    position: relative;
}

.titleContainer {
    @include container;
    @include fluid-prop-sm(margin-bottom, 32px, 48px);

    align-items: center;
    display: flex;
    justify-content: space-between;
}

.titleBlock {
    align-items: baseline;
    column-gap: 16px;
    display: flex;
}

.titleCount {
    display: flex;
    flex-shrink: 0;
    gap: 4px;
}

.withoutProducts {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include fluid-props-sm-p-sm(gap);

    svg {
        color: $c-additional-graphics-elements-stubs;
        height: 120px;
        width: 120px;
    }
}

.withoutProductsContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--p-xxs);
    max-width: 348px;
    text-align: center;
}

.cartRelatedProducts,
.cartLookedProducts {
    @include fluid-prop-sm(margin-top, 24px, 56px);
}

.backButton {
    padding: 12px 0  8px;
}

.sliderBlock {
    margin-top: var(--p-xl);
}
