@import 'src/styles/mixins';

.root {
    row-gap: var(--p-sm);
}

.fluidGap {
    @include fluid-props-sm-p-sm(row-gap);
}

.header {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.rightSideComponent {
    flex-shrink: 0;
}
