@import 'src/styles/mixins';

.variantFilterContainer {
    position: relative;
}

.variantFilterOpen {
    padding-bottom: 40px;
}

.variantFilterList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    margin: 0;
    padding: 0;

    @include scrollbar;

    &.isQuantity {
        flex-flow: row wrap;
        gap: 8px;
    }
}

.variantFilterListButtons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.variantFilterItem {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
}

.variantCheckbox {
    width: 100%;
}

.variantFilterItemButtonList {
    justify-content: flex-start;
    width: auto;

    & + & {
        margin-top: 0;
    }
}

.variantFilterItemAmount {
    color: $c-default-gray-50;
    margin-left: 8px;

    @media #{$screen-mobile} {
        min-width: 24px;
        text-align: center;
    }
}

.variantFilterItemAmountDisabled {
    color: $c-default-gray-30;
}

.searchInput {
    margin-bottom: 16px;
}
