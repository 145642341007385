@import 'src/styles/mixins';

.orderPage {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.orderList {
    @include fluid-prop-sm(grid-row-gap, 16px, 24px);
}

.page {
    @include fluid-prop-sm(grid-row-gap, 32px, 48px);
}
