@import 'src/styles/mixins';

.root {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--p-sm);
    max-width: 348px;
    text-align: center;
    width: 100%;
}

.emptyIcon {
    color: $c-additional-graphics-elements-stubs;
    height: 120px;
    width: 120px;
}

.advice {
    @include fluid-prop-sm(margin-bottom, 20px, 24px);
}

.resetButton {
    display: inline-flex;
}

.listTitle {
    margin-bottom: 8px;
}
