@import 'src/styles/mixins';

.suggestionItem {
    height: max-content;
    min-height: 35px;
    position: relative;

    &:hover {
        background-color: $c-additional-custom-hovers-on-component;
    }
}

.suggestionItem button {
    bottom: 0;
    cursor: pointer;
    height: max-content;
    height: 100%;
    left: 0;
    overflow: hidden;
    padding: var(--p-xxs);
    position: absolute;
    right: 0;
    text-align: start;
    text-overflow: ellipsis;
    top: 0;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
}
