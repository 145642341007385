@import 'src/styles/mixins';

.cartHeaderPopupWrapper {
    display: flex;
    width: 100%;
}

.cartHeaderPopupContainer {
    width: 100%;

    @include container;
}

.cartHeaderPopup {
    margin-left: auto;
    margin-right: 0;
    padding-top: 8px;
    position: absolute;
    right: 0;
    width: 440px;
}

.cartHeaderPopupContent {
    background-color: $c-default-white-100;
    box-shadow: 0 2px 25px $c-shadow-large-tooltip;
}

.header {
    align-items: center;
    column-gap: var(--p-md);
    display: flex;
    justify-content: space-between;
    padding: var(--p-sm);
}

.headerShadow {
    box-shadow: 0 2px 4px 0 $c-shadow-down;
}

.productsListContainer {
    margin: 0 6px 0 24px;
    max-height: 548px;
    overflow: auto;
    padding-right: 18px;
    padding-top: 2px;

    @include scrollbar;
}

.cartHeaderPopupTotal {
    box-shadow: 0 -2px 4px $c-primary-30-a20;
    display: none;
    justify-content: space-between;
    padding: 24px 24px 26px;
    position: relative;
    z-index: 1;
}

.footer {
    display: flex;
    flex-direction: column;
}

.footerShadow {
    box-shadow: 0 -2px 4px 0  $c-shadow-down;
}

.toCartAction {
    padding: var(--p-sm);
}

.totalPriceBlock {
    min-height: 54px;
    padding-top: var(--p-sm);
}

.toCartActionButton {
    width: 100%;
}

.clear {
    &:global(.button-component svg) {
        height: 16px;
        width: 16px;
    }
}
