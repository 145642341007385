@import 'src/styles/mixins';

.root {
    display: grid;
    gap: var(--p-xs);
    grid-template-columns: 170px auto;

    @media #{$screen-mobile} {
        grid-template-columns: 1fr;
    }
}
