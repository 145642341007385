@import 'src/styles/mixins';

.itemContainer {
    align-items: center;
    display: flex;
    transition: color $animation;
}

.itemAvailability {
    cursor: pointer;

    &:hover {
        color: $c-default-gray-80;
    }
}

.locationIcon {
    height: 20px;
    margin-right: 4px;
    width: 20px;
}
