@import 'src/styles/mixins';
@import 'src/styles/font';

.main {
    align-items: center;
    display: flex;
    gap: 12px;

    &.vertical {
        align-items: flex-start;
        flex-direction: column;
        gap: 0;

        @media #{$screen-mobile} {
            flex-direction: column;
        }
    }

    &.mainOffer {
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 4px;
    }
}

.cartPopup {
    &.vertical {
        align-items: flex-end;
    }
}

.cartResume {
    &.main {
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 4px;
    }

    &.vertical {
        flex-direction: row;
        justify-content: flex-end;

        @media #{$screen-mobile} {
            justify-content: flex-start;
        }
    }
}

.container {
    align-items: baseline;
    display: flex;
}

.bottomContainer {
    align-items: baseline;
    display: flex;
    position: relative;
    width: fit-content;

    @media #{$screen-mobile} {
        margin-top: 0;
    }

    &::before {
        background-color: $c-additional-price-old;
        content: '';
        height: 1px;
        left: 8px;
        position: absolute;
        right: 0;
        top: 47%;
    }

    .productSet {
        margin-top: 4px;
    }
}

.mainPrice {
    @include f_price_preview-s;

    color: $c-additional-price-basic;

    // &.isNotAvailable {
    //     color: $c-additional-price-old;
    // }

    &.mainOffer {
        @include f_price_l;
    }

    &.techPreview,
    &.mainHeader {
        @include f_price-preview-s;
    }

    &.techListPreview {
        color: $c-additional-price-basic;
    }

    &.techListPreview,
    &.cartPopup {
        @include f_price_preview-cart;
    }

    &.techListPreview,
    &.cartResume,
    &.searchPanel {
        @include f_price_preview-m;
    }

    &.visualPreview {
        @media #{$screen-mobile} {
            @include f_price_preview-s;
        }
    }
}

.basePrice {
    @include f_old-price-preview-s;

    color: $c-additional-price-old;

    &.searchPanel,
    &.techListPreview,
    &.productSet,
    &.cartResume {
        @include f_old_price-preview-m;
    }

    &.techPreview,
    &.mainHeader {
        @include f_old_price-preview-s;
    }

    &.visualPreview {
        @include f_old_price-preview-m;

        @media #{$screen-mobile} {
            @include f_old_price-preview-s;
        }
    }

    &.mainOffer {
        @include f_old-price-preview-l;
        @include fluid-prop-sm(margin-left, 4px, 8px);

        padding-bottom: 2px;
    }
}

.unitContainer {
    color: $c-additional-price-basic;
    margin-left: 2px;

    // &.isNotAvailable {
    //     color: $c-additional-price-old;
    // }
}

.currency {
    @include f_p-medium-strong;

    &.techListPreview,
    &.searchPanel {
        @include f_elements_p;

        color: $c-additional-price-basic;
    }

    &.techPreview {
        @include f_label;
    }

    &.cartResume {
        @include f_p_strong;
    }

    &.mainOffer,
    &.mainHeader {
        @include f_elements_p;
    }

    &.visualPreview {
        @media #{$screen-mobile} {
            @include f_p-medium-strong;
        }
    }
}

.currencyBottom {
    @include f_p-medium;

    color: $c-additional-price-old;
    margin-left: 2px;

    &.techPreview,
    &.searchPanel {
        @include f_p-medium-strong;
    }

    &.mainOffer {
        @include f_elements_p;
    }

    &.mainHeader {
        @include f_p-bold-strong;
    }

    &.techListPreview,
    &.cartResume {
        @include f_p_strong;
    }

    &.visualPreview {
        @include f_p-medium;

        @media #{$screen-mobile} {
            @include f_p-medium-strong;
        }
    }
}

.unit {
    @include f_p_strong;

    &.techPreview,
    &.searchPanel {
        @include f_label;
    }

    &.mainOffer,
    &.mainHeader {
        @include f_elements_p;
    }

    &.cartResume,
    &.cartPopup {
        display: none;
    }
}
