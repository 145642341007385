@import 'src/styles/mixins';

.filterTagList {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    list-style: none;
    padding: 0;

    @media #{$screen-mobile} {
        @include hide-scrollbars;

        flex-grow: 0;
        flex-wrap: nowrap;
        gap: 12px;
        margin: 0 -16px;
        overflow-x: auto;
        padding: 0 16px;

        & > li {
            flex-shrink: 0;
        }

        .filterTagItem {
            margin: 0 8px 8px 0;
        }
    }

    &.isInFiltersList {
        flex-wrap: wrap;
        gap: 8px;
        margin-bottom: 24px;
        overflow-x: initial;

        @include show-mobile-hide-desktop(flex);
    }
}

.filterTagListReset {
    @include fluid-prop-padding-left-right-sm(12px, 10px);

    align-items: center;
    background-color: $c-primary-10;
    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;
    height: 32px;
    transition: background-color $animation;

    &:hover {
        background-color: $c-primary-5;
    }

    &:focus {
        box-shadow: inset 0 0 0 2px $c-primary-50;
    }

    &:active {
        background-color: $c-primary-20;
    }
}

.resetAllDesktop {
    @include hide-mobile-show-desktop;
}

.resetAllAdaptive {
    @include show-mobile-hide-desktop;
}
