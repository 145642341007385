$font-name-jost-medium: 'jost medium';
$font-name-graphik-medium: 'graphik medium';
$font-name-graphik-regular: 'graphik regular';
$font-name-graphik-semi-bold: 'graphik semi bold';
$font-name-graphik-bold: 'graphik bold';
$font-name-evolventa-bold: 'evolventa bold';
$font-name-evolventa-regular: 'evolventa regular';

// KARACHA fonts
$font-name-geologica-light: 'geologica light';
$font-name-geologica-regular: 'geologica regular';
$font-name-manrope-regular: 'manrope regular';
$font-name-manrope-bold: 'manrope bold';
