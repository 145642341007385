@import 'src/styles/mixins';

.root {
    background-color: $c-default-white-100;
    transition: all $animation;

    @media #{$screen-mobile} {
        box-shadow: 0 0 0 0 $c-shadow-header;
        left: 0;
        padding-bottom: 0;
        top: var(--header-height-adaptive);

        // transform: translateY(calc(var(--content-margin-top) * -1px));
        width: 100%;
        z-index: 5;
    }

    &.isNotTop {
        @media #{$screen-mobile} {
            box-shadow: 0 2px 4px 0 $c-shadow-header;
        }
    }
}
