@import 'src/styles/font';
@import 'src/styles/mixins';

.previewCardsSlider {
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 0;

    &LeftSpacer {
        background: $c-default-white-100;
        flex-shrink: 0;
        width: calc((100vw - $max-container-width-lg) / 2);
        z-index: 2;

        @media #{$screen-mobile} {
            width: 16px;
        }

        @media #{$screen-xl} {
            width: calc((100vw - $max-container-width-xl) / 2);
        }
    }

    &RightSpacer {
        background: $c-default-white-100;
        flex-shrink: 0;
        width: calc((100vw - $max-container-width-lg) / 2);
        z-index: 2;

        @media #{$screen-mobile} {
            width: 16px;
        }

        @media #{$screen-xl} {
            width: calc((100vw - $max-container-width-xl) / 2);
        }
    }

    .slider {
        padding-bottom: 2px;
        position: relative;

        &.fullPageWidth {
            max-width: $max-container-width-lg;
            overflow: initial;
            width: 100%;

            @media #{$screen-mobile} {
                max-width: calc(100vw - 32px);
            }

            @media #{$screen-xl} {
                max-width: $max-container-width-xl;
            }

            :global(.swiper-button-prev),
            :global(.swiper-button-next) {
                z-index: 3;
            }

            :global(.swiper-button-prev) {
                left: -24px;
            }

            :global(.swiper-button-next) {
                right: -24px;
            }
        }

        :global(.swiper-button-disabled) {
            display: none;
        }
    }
}

.previewCardsSliderPreloader {
    z-index: 4;

    @media #{$screen-mobile} {
        height: 100%;
        top: 0;
    }
}
