@import 'src/styles/mixins';
@import 'src/styles/font';

.iconElement {
    align-items: center;
    color: inherit;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40px;
}

.count {
    align-items: center;
    background-color: $c-secondary-100;
    border-radius: 20px;
    color: $c-default-white-100;
    display: flex;
    height: 16px;
    justify-content: center;
    min-width: 16px;
    padding: 0 3.5px;
    position: absolute;
    right: 1px;
    top: -1px;
    z-index: 1;

    @include f_p-small;
}
