@import 'src/styles/mixins';

.agreement {
    align-items: flex-start;
    display: flex;
    width: 100%;

    a {
        display: inline-block;
    }
}

.submitBtn {
    @include fluid-prop-sm(margin-top, 16px, 24px);
}
