@import 'src/styles/mixins';

.bannersList {
    display: flex;

    @media #{$screen-mobile} {
        flex-direction: column;
    }
}

.bannersItem {
    border-radius: var(--r-lg);
    height: 220px;
    overflow: hidden;
    position: relative;

    &::before {
        background: transparent;
        bottom: 0;
        content: '';
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &:hover::before {
        background: $c-gradient-gray-vertical-top;
    }

    &:first-child {
        width: 70%;

        @media #{$screen-mobile} {
            width: 100%;
        }
    }

    img {
        object-fit: cover;
    }
}

.content {
    @include fluid-prop-sm(left, 12px, 16px);
    @include fluid-prop-sm(right, 12px, 16px);

    bottom: var(--p-xs);
    display: flex;
    flex-direction: column;
    gap: var(--p-xxs);
    position: absolute;
    z-index: 2;
}
