@import 'src/styles/mixins';

.form {
    height: 100%;
}

.fields {
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    gap: 16px;
    height: 100%;

    @media #{$screen-mobile} {
        background-color: $c-default-white-100;
        border-top-left-radius: var(--r-md);
        border-top-right-radius: var(--r-md);
        bottom: calc(-60% - 5px);
        left: 0;
        padding: 0 16px 16px;
        position: absolute;
        right: 0;
        transition: bottom $animation;
        z-index: 2;
    }

    &.isRolledUp {
        border-radius: 0;
        bottom: 0;
        height: 80%;
        overflow-y: auto;
        padding-bottom: 75px;
        transition: bottom $animation;
    }

    &.isRolledUp .fieldsInner {
        margin-bottom: 8px;
    }

    :global(.textarea-frame) {
        border-radius: var(--r-md);
    }
}

.fieldsInner {
    display: none;
    flex-direction: column;
    gap: 16px;
    padding-top: 8px;

    @media #{$screen-mobile} {
        display: flex;
    }
}

.bottomSheet {
    background-color: $c-default-gray-20;
    border-radius: 4px;
    height: 4px;
    margin: 0 auto;
    margin-bottom: 8px;
    width: 80px;
}

.button {
    flex-shrink: 0;
}

.groupField {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include fluid-prop-sm(gap, 8px, 16px);
}

.input {
    width: 100%;
}

.house {
    width: max-content;
}

.mapBlock {
    border-radius: var(--r-lg);
    flex: 1 1 78%;
    order: 1;
    overflow: hidden;
    position: relative;

    @media #{$screen-mobile} {
        border-radius: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }
}

.submitButton {
    flex: 0 0 auto;

    &.desktop {
        margin-top: auto;
    }
}

.mainContainer {
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;
    height: 100%;

    @media #{$screen-mobile} {
        display: block;
        overflow: auto;
        padding-bottom: 16px;
    }
}

.mobile {
    @media #{$screen-desktop} {
        display: none;
    }
}

.desktop {
    @media #{$screen-mobile} {
        display: none;
    }
}
