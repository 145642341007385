@import 'src/styles/mixins';

.root {
    row-gap: 32px;

    @media #{$screen-mobile} {
        row-gap: 24px;
    }
}

.picture {
    width: 100%;

    img {
        aspect-ratio: 3;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    @media #{$screen-mobile} {
        img {
            aspect-ratio: 9 / 7;
            width: 100%;
        }
    }
}

.subtitle {
    margin-bottom: 15px;
}
