@import 'src/styles/mixins';
@import 'src/styles/font';

.rangeFilterInputs {
    align-items: center;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.rangeFilterInput {
    width: 100%;

    & :global(.input-original) {
        @include f_elements_p;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.isEmpty {
        & :global(.input-original) {
            color: $c-default-gray-60;
        }
    }
}

.rangeFilter {
    align-items: center;
    border: 0.1px solid transparent; // fix обрезания элемента при масштабе 80% и 90%
    display: flex;
    position: relative;
    touch-action: none;
    user-select: none;
    width: 100%;
}

.rangeFilter[data-orientation='horizontal'] {
    height: 24px;
    width: 100%;
}

.rangeFilterTrackContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 24px;
    position: relative;
    width: 100%;

    &::before {
        background-color: $c-secondary-30;
        border-radius: 9999px;
        content: '';
        height: 4px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
    }
}

.rangeFilterTrack {
    background-color: $c-secondary-30;
    border-radius: 9999px;
    flex-grow: 1;
    height: 4px;
    position: relative;
}

.rangeFilterRange {
    background-color: $c-secondary-100;
    border-radius: 9999px;
    height: 100%;
    position: absolute;
}

.rangeFilterThumb {
    background-color: $c-secondary-100;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 16px;
    width: 16px;
}
