@import 'src/styles/mixins';

.slider {
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;

    @media #{$screen-mobile} {
        :global {
            .swiper-pagination {
                display: none;
            }
        }
    }

    :global {
        .swiper-slide {
            overflow: hidden;
        }

        .swiper-button-prev {
            left: 24px;
        }

        .swiper-button-next {
            right: 24px;
        }
    }
}

.logoLoader {
    z-index: 4;
}

.homePageBannerWrapper {
    @include container;
}

.homePageBannerTopLeft {
    position: relative;

    @media #{$screen-mobile} {
        aspect-ratio: 1.16;
    }
}

.navigationActive {
    opacity: 0;
    visibility: hidden;
}

.homePageBannerTopLeft:hover .navigationActive {
    opacity: 1;
    transition: opacity $animation, visibility $animation;
    visibility: visible;
}

.homePageBannerSlider {
    border-radius: var(--r-md);
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.homePageBannerTopRight {
    aspect-ratio: 0.96;
    border-radius: var(--r-md);
    overflow: hidden;
    position: relative;
    width: 100%;

    img {
        object-fit: cover;
    }

    @media #{$screen-mobile} {
        aspect-ratio: 1.16;
    }
}

.nav {
    bottom: 12px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 1;
}
