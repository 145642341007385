@import 'src/styles/mixins';
@import 'src/styles/font';

.filterButton {
    align-items: center;
    background-color: $c-default-gray-10;
    border-radius: var(--r-xs);
    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    position: relative;
    transition: color $animation, background-color $animation;
    width: 48px;

    &:hover,
    &:active {
        background-color: $c-additional-custom-hovers-on-component;
        color: $c-default-gray-80;
    }

    @media #{$screen-mobile} {
        height: 40px;
        width: 40px;
    }

    & .filterCaptionSvg {
        height: 24px;
        width: 24px;
    }

    &.filtersHidden {
        background-color: $c-background-accent-component;
        color: $c-default-gray-100;

        &:active {
            background-color: $c-background-accent-component;
        }
    }

    &.hidden {
        display: none;

        @media #{$screen-mobile} {
            display: flex;
        }
    }
}

.iconWrapper {
    display: inline-flex;

    &[data-filters-count]:not([data-filters-count='0']) {
        &::after {
            @include f_p-small;

            align-items: center;
            background-color: $c-secondary-100;
            border-radius: 20px;
            color: $c-default-white-100;
            content: attr(data-filters-count);
            display: flex;
            height: 20px;
            justify-content: center;
            position: absolute;
            right: -10px;
            top: -8px;
            width: 20px;
        }
    }
}

.tooltip {
    &:global(.rc-tooltip .rc-tooltip-inner) {
        align-items: center;
        display: flex;
        height: 34px;
        padding: 0 12px;
    }

    &:global(.rc-tooltip) {
        @media #{$screen-mobile} {
            display: none;
        }
    }

    :global(.rc-tooltip-content) {
        border-radius: 4px;
    }
}
