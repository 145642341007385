@import 'src/styles/mixins';

.root {
    @include container;
    @include fluid-prop-sm(padding-top, 40px, 80px);
    @include fluid-prop-sm(row-gap, 24px, 38px);

    display: flex;
    flex-direction: column;
}

.content {
    position: relative;

    @include fluid-prop-sm(height, 240px, 434px);
}

.modal {
    @include fluid-prop-sm(max-width, 360px, 1200px);

    background: transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    @media #{$screen-mobile} {
        padding: 0;
    }
}

.modalContent {
    @include fluid-prop-sm(height, 204px, 680px);
}

.card {
    @include fluid-prop-sm(max-width, 300px, 588px);

    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    row-gap: 10px;

    @media #{$screen-mobile} {
        row-gap: 8px;
    }
}

.img {
    width: 100%;

    @include fluid-prop-sm(height, 195px, 360px);
}
