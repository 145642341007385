@import 'src/styles/mixins';

.productImageSwitcher {
    height: 100%;
    width: 100%;
}

.productImageSwitcherImageContainer {
    display: flex;
    gap: 16px;
    height: 100%;
    overflow-x: auto;
    position: relative;

    @include hide-scrollbars;

    @media (pointer: coarse) {
        scroll-snap-type: x mandatory;
    }

    &.isOneImage {
        overflow-x: initial;
    }
}

.productImageSwitcherImageSlide {
    flex-shrink: 0;
    height: 100%;
    overflow: hidden;
    width: 100%;

    @media (pointer: coarse) {
        scroll-snap-align: start;
    }
}

.productImageSwitcherImageSlideActive {
    order: -1;
}

.productImageSwitcherImageSlideHidden {
    opacity: 0;
}

.productImageSwitcherImageSlideVisible {
    opacity: 1;
}

.productImageSwitcherInner {
    background-color: $c-default-white-100;
    bottom: -1px;
    left: 0;
    padding: 12px 0 1px;
    position: absolute;
    right: 0;
}

.productImageSwitcherNavItems {
    bottom: 2px;
    cursor: pointer;
    display: flex;
    grid-column-gap: 4px;
    grid-template-columns: repeat(auto-fit, 4px);
    justify-content: center;
    list-style: none;
    margin: 0;
    position: absolute;
    width: 100%;

    @media (pointer: fine) {
        display: grid;
    }
}

.productImageSwitcherNavItem {
    background-color: $c-primary-30;
    border-radius: 2px;
    height: 4px;
    transition: background-color $animation;
    width: 4px;
}

.productImageSwitcherNavItemActive {
    background-color: $c-secondary-100;
}

.productImageSwitcherPaginationContainer {
    bottom: 10px;
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;

    @media (pointer: fine) {
        display: flex;
    }
}

.productImageSwitcherPagination {
    height: 100%;
    width: 100%;
}
