@import 'src/styles/mixins';

.areaContent {
    margin-top: 25px;
}

.container {
    @include container;
}

.contentBlock {
    grid-template-rows: 750px;

    @media #{$screen-mobile} {
        grid-template-rows: 50vh;
        margin-top: 16px;
    }
}

.controls {
    @media #{$screen-desktop} {
        display: none;
    }
}

.mapImage {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.mapBlock {
    @media #{$screen-mobile} {
        display: none;

        .mapView & {
            display: block;
        }
    }
}

.shopListBlock {
    @media #{$screen-mobile} {
        .mapView & {
            display: none;
        }
    }
}
