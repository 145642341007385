@import 'src/styles/mixins';

%frame-mixin {
    border-radius: var(--r-lg);
    padding-bottom: 8px;
    padding-top: 8px;
    width: 100%;
}

.segmentControlForm {
    align-items: center;
    background-color: $c-background-accent-component;
    border-radius: var(--r-lg);
    display: flex;
    height: 46px;
    position: relative;

    @media #{$screen-mobile} {
        height: 42px;

        &.isNotAdaptive {
            height: 46px;
        }
    }
}

.segmentControlLabel {
    @extend %frame-mixin;

    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: color $animation;
    z-index: 1;
}

.segmentControlFrame {
    @extend %frame-mixin;

    background-color: $c-secondary-100;
    bottom: 0;
    position: absolute;
    top: 0;
    transition: all $animation;
}

.segmentControlRadioInput {
    &:checked + .segmentControlLabel {
        color: $c-default-white-100;
        position: relative;
    }
}
