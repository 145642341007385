@import 'src/styles/mixins';

.container {
    height: 100%;
    opacity: 1;
    overflow: hidden;
    transition: all $animation;
    width: 100%;

    &.noOverflowHiddenIfShow {
        overflow: visible;
    }

    &.hidden {
        opacity: 0;
        overflow: hidden;
    }
}

.innerContainer {
    overflow: hidden;

    .noOverflowHiddenIfShow & {
        overflow: visible;
    }

    .hidden & {
        overflow: hidden;
    }
}
