@import 'src/styles/mixins';
@import 'src/modules/checkout/layouts/CheckoutPageLayout/style.module';

.container {
    @include fluid-props-sm-p-lg(row-gap);
}

.title {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cartLabel {
    display: none;

    @media #{$screen-mobile} {
        display: flex;
    }
}
