@import 'src/styles/mixins';

.root {
    margin: 0 -8px;
    padding: 8px;
    position: relative;

    @media #{$screen-mobile} {
        margin: 0 -16px;
        padding: 0;
    }

    &.withHidingInactiveNavBtn {
        :global(.prev-disabled),
        :global(.next-disabled) {
            display: none;
        }
    }

    &:global(:has(.prev-disabled)) .categoryChildListSlider::before,
    &:global(:has(.next-disabled)) .categoryChildListSlider::after {
        content: none;
    }
}

.categoryChildListSlider {
    overflow: hidden;
    position: relative;

    @media #{$screen-mobile} {
        padding: 0 16px;
    }

    & :global(.swiper-button-prev) {
        left: 0;
    }

    & :global(.swiper-button-disabled) {
        display: none;
    }

    & :global(.swiper-button-next) {
        right: 0;
    }

    &.withSidesGradient {
        &::before,
        &::after {
            content: '';
            height: 100%;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 100px;
            z-index: 1;
        }

        &::before {
            background: linear-gradient(-90deg, rgba($c-default-white-100, 0) 32%, $c-default-white-100 55.5%);
            left: 0;
        }

        &::after {
            background: linear-gradient(90deg, rgba($c-default-white-100, 0) 32%, $c-default-white-100 55.5%);
            right: 0;
        }
    }
}

.navigationActive {
    opacity: 1;
    visibility: visible;

    &:global(.prev),
    &:global(.next) {
        z-index: 2;
    }

    &:global(.prev) {
        left: 8px;
    }

    &:global(.next) {
        right: 8px;
    }
}
