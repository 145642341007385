@import 'src/styles/mixins';
@import 'src/styles/font';

.container {
    background-color: $c-default-white-100;
    border-radius: var(--r-md);
    box-shadow: 0 2px 25px $c-additional-shadow-blue-a2;
    display: flex;
    flex-direction: column;
    height: 416px;
    margin-top: 14px;
    overflow: hidden;
    position: absolute;
    width: 324px;
    z-index: 10;
}

.cityListRoot {
    overflow: auto;

    .list {
        border-top: 1px solid $c-additional-gray-blue-30;
        margin-top: 16px;
    }

    .cityItem {
        padding-left: 12px;
        transition: background-color $animation;

        &:hover {
            background-color: $c-additional-gray-blue-20;
        }
    }

    .cityItemName {
        @include f_p-medium('lg');
    }

    .searchContainer {
        padding: 12px 12px 0;
    }

    .infoBlock {
        padding: 12px;
    }
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 16px 12px 0;
    row-gap: 8px;
}

.close {
    color: $c-default-gray-60;
    cursor: pointer;
    display: flex;

    svg {
        height: 24px;
        width: 24px;
    }
}
