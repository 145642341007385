@import 'src/styles/mixins';
@import 'src/styles/font';

.content :global(.container) {
    @include container;
    @include f_p;

    display: flex;
    flex-direction: column;

    h1 {
        @include f_h1;

        margin-bottom: 20px;
    }

    h2 {
        @include f_h2;

        margin-bottom: 20px;
    }

    h3 {
        @include f_h3;

        margin-bottom: 20px;
    }

    p {
        @include f_p;

        margin-bottom: 10px;
    }

    ul {
        @include f_p-u;

        list-style: disc;
        margin-bottom: 10px;
        padding-left: 24px;
    }

    ol {
        @include f_p-u;

        margin-bottom: 10px;
        padding-left: 24px;

        li {
            list-style-type: decimal;
        }
    }

    li {
        position: relative;
    }

    li + li {
        margin-top: 5px;
    }

    b,
    strong {
        font-weight: bold;
    }

    a {
        @include f_link;

        text-decoration: underline;
    }

    table {
        margin-bottom: 20px;
        margin-left: 24px;

        @media #{$screen-mobile} {
            margin-left: 0;
        }
    }

    td {
        padding: 5px;

        @media #{$screen-phone-sm} {
            padding: 0;
        }

        & > p {
            @media #{$screen-phone-xl} {
                @include f_p-regular;
            }

            @media #{$screen-phone-lg} {
                @include f_p-small;
            }
        }
    }
}
