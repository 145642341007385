@import 'mixins';

@mixin f_h1() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 110%;

    @include fluid-prop-sm(font-size, 28px, 48px);
}

@mixin f_h2() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;

    @include font-fluid-sm-int(24px, 110%, 40px, 120%);
}

@mixin f_h3() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 120%;

    @include fluid-prop-sm(font-size, 24px, 32px);
}

@mixin f_h4() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 120%;

    @include fluid-prop-sm(font-size, 22px, 28px);
}

@mixin f_h5() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;

    @include font-fluid-sm-int(20px, 120%, 24px, 130%);
}

@mixin f_h6() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 120%;

    @include fluid-prop-sm(font-size, 18px, 20px);
}

@mixin f_h7() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;

    @include font-fluid-sm-int(16px, 120%, 16px, 130%);
}

@mixin f_p($size: 'auto') {
    font-family: $font-name-manrope-regular;
    font-weight: 400;

    @if $size == 'auto' {
        @include font-fluid-sm-int(16px, 140%, 16px, 150%);
    }

    @if $size == 'lg' {
        font-size: 16px;
        line-height: 150%;
    }

    @if $size == 'xs' {
        font-size: 16px;
        line-height: 140%;
    }
}

@mixin f_p_strong() {
    font-family: $font-name-manrope-bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

@mixin f_elements-strong() {
    font-family: $font-name-manrope-bold;
    font-weight: 700;

    @include font-fluid-sm-int(14px, 130%, 16px, 130%);
}

@mixin f_elements_p() {
    font-family: $font-name-manrope-regular;
    font-weight: 400;
    line-height: 130%;

    @include fluid-prop-sm(font-size, 14px, 16px);
}

@mixin f_p-u() {
    font-family: $font-name-manrope-regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

@mixin f_p-regular() {
    font-family: $font-name-manrope-regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
}

@mixin f_p-bold-strong() {
    font-family: $font-name-manrope-bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
}

@mixin f_p-small() {
    font-family: $font-name-manrope-regular;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
}

@mixin f_p-small-strong() {
    font-family: $font-name-manrope-bold;
    font-size: 12px;
    font-weight: 700;
    line-height: 130%;
}

@mixin f_link() {
    font-family: $font-name-geologica-light;
    font-weight: 300;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 16px, 18px);
}

@mixin f_button-l() {
    font-family: $font-name-manrope-bold;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
}

@mixin f_button-m() {
    font-family: $font-name-manrope-bold;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
}

@mixin f_button-s() {
    font-family: $font-name-manrope-bold;
    font-size: 12px;
    font-weight: 700;
    line-height: 100%;
}

@mixin f_price-l() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 22px, 36px);
}

@mixin f_price-preview-l() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 18px, 30px);
}

@mixin f_price-preview-m() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 16px, 24px);
}

@mixin f_price-preview-s() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 14px, 18px);
}

@mixin f_old-price-preview-l() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 16px, 24px);
}

@mixin f_old-price-preview-m() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 14px, 18px);
}

@mixin f_old-price-preview-s() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 12px, 14px);
}

@mixin f_price-preview-cart() {
    font-family: $font-name-geologica-regular;
    font-weight: 400;
    line-height: 100%;

    @include fluid-prop-sm(font-size, 14px, 16px);
}

@mixin f_old-price-cart() {
    font-family: $font-name-geologica-regular;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
}

// unused fonts

@mixin f_h1_bold() {
    font-family: $font-name-evolventa-bold;
    font-weight: 700;

    @include font-fluid-sm(34px, 40px, 60px, 80px);
}

@mixin f_h2_bold() {
    font-family: $font-name-evolventa-bold;
    font-weight: 700;

    @include font-fluid-sm(28px, 36px, 48px, 64px);
}

@mixin f_h3_bold() {
    font-family: $font-name-evolventa-bold;
    font-weight: 700;

    @include font-fluid-sm(20px, 28px, 30px, 44px);
}

@mixin f_h4_bold() {
    font-family: $font-name-evolventa-bold;
    font-weight: 700;

    @include font-fluid-sm(18px, 24px, 26px, 38px);
}

@mixin f_h5_bold() {
    font-family: $font-name-evolventa-bold;
    font-weight: 700;

    @include font-fluid-sm(14px, 18px, 22px, 32px);
}

@mixin f_h6_bold() {
    font-family: $font-name-evolventa-bold;
    font-weight: 700;

    @include font-fluid-sm(14px, 20px, 18px, 24px);
}

@mixin f_h7_bold() {
    font-family: $font-name-evolventa-bold;
    font-weight: 700;

    @include font-fluid-sm(14px, 18px, 16px, 20px);
}

@mixin f_p-medium($size: 'auto') {
    font-family: $font-name-graphik-regular;

    @if $size == 'auto' {
        @include font-fluid-sm(12px, 16px, 14px, 20px);
    }

    @if $size == 'xs' {
        font-size: 12px;
        line-height: 16px;
    }

    @if $size == 'lg' {
        font-size: 14px;
        line-height: 20px;
    }
}

@mixin f_p-medium-strong($size: 'auto') {
    font-family: $font-name-graphik-semi-bold;
    font-weight: 600;

    @if $size == 'auto' {
        @include font-fluid-sm(12px, 16px, 14px, 20px);
    }

    @if $size == 'xs' {
        font-size: 12px;
        line-height: 16px;
    }

    @if $size == 'lg' {
        font-size: 14px;
        line-height: 20px;
    }
}

@mixin f_label() {
    font-family: $font-name-graphik-medium;
    font-weight: 500;

    @include font-fluid-sm(12px, 16px, 14px, 20px);
}

@mixin f_badge() {
    font-family: $font-name-graphik-regular;

    @include font-fluid-sm(12px, 14px, 14px, 20px);
}

@include font-face-single($font-name-jost-medium, 'jost-medium.ttf', 500, normal, 'truetype');
@include font-face-single($font-name-graphik-regular, 'graphik-regular-web.woff2', 400, normal, 'woff2');
@include font-face-single($font-name-graphik-medium, 'graphik-medium-web.woff2', 500, normal, 'woff2');
@include font-face-single($font-name-graphik-semi-bold, 'graphik-semibold-web.woff2', 600, normal, 'woff2');
@include font-face-single($font-name-evolventa-bold, 'evolventa-bold.woff2', 700, normal, 'woff2');
@include font-face-single($font-name-evolventa-regular, 'evolventa-regular.woff2', 400, normal, 'woff2');
@include font-face-single($font-name-geologica-light, 'geologica-light.woff2', 300, normal, 'woff2');
@include font-face-single($font-name-geologica-regular, 'geologica-regular.woff2', 400, normal, 'woff2');
@include font-face-single($font-name-manrope-bold, 'manrope-bold.woff2', 700, normal, 'woff2');
@include font-face-single($font-name-manrope-regular, 'manrope-regular.woff2', 400, normal, 'woff2');
