@import 'src/styles/mixins';

.category {
    background-color: $c-default-gray-10;
    border-radius: var(--r-xl);
    overflow: hidden;
    padding: var(--p-xs-16) var(--p-sm);
    transition: all $animation;

    .container {
        align-items: center;
        display: flex;
        gap: var(--p-xxs);
    }

    & ~ button {
        height: 20px;
        right: 16px;
        top: 22px;
        width: 20px;

        @media #{$screen-mobile} {
            right: 12px;
            top: 15px;
        }

        svg {
            height: 20px;
            width: 20px;
        }
    }

    &.active {
        background-color: $c-primary-10;
        box-shadow: 0 0 0 1px $c-primary-30 inset;
        padding: 12px 48px 12px 24px;

        @media #{$screen-mobile} {
            padding: 8px 44px 8px 15px;
        }

        &:hover {
            outline-color: $c-primary-50;
        }

        &.alter {
            padding: 14px 36px 14px 16px;

            @media #{$screen-mobile} {
                padding-bottom: 8px;
                padding-top: 8px;
            }

            & ~ button {
                height: 16px;
                right: 12px;
                top: calc(50% - 8px);
                width: 16px;

                svg {
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }

    &:hover {
        background-color: $c-primary-10;
    }

    .description {
        color: $c-secondary-50;
    }

    .name {
        color: $c-default-gray-100;
        white-space: nowrap;
    }
}
