@import 'src/styles/mixins';

.layoutContainer {
    @include fluid-prop-sm(row-gap, 32px, 48px);

    @media #{$screen-mobile} {
        padding-top: 32px;
    }
}

.layoutTitle {
    @media #{$screen-mobile} {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}

.content {
    @media #{$screen-mobile} {
        padding-top: calc(var(--content-margin-top) + 12px);
        transition: padding-top $animation;
    }
}

.sidebar {
    flex-shrink: 0;
    height: min-content;
    position: sticky;
    top: 88px;
    z-index: 2;

    @media #{$screen-mobile} {
        display: none;
    }
}

.pageContent {
    position: relative;

    &::before {
        background-color: $c-default-gray-30;
        content: '';
        height: 100%;
        left: -24px;
        position: absolute;
        top: 0;
        width: 1px;
    }
}
