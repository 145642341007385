@import 'src/styles/mixins';

.keyCharacteristics {
    display: flex;
    flex-direction: column;

    @media #{$screen-mobile} {
        display: none;
    }
}

.title {
    margin-bottom: 24px;
}

.anchor {
    align-items: center;
    color: $c-primary-60;
    display: flex;
    margin-top: 24px;
    transition: color $animation;

    svg {
        height: 16px;
        margin-left: 4px;
        transform: rotate(-90deg);
        width: 16px;
    }

    &:hover {
        color: $c-primary-50;
    }
}

.item {
    align-items: baseline;
    display: grid;
    grid-column-gap: 2px;
    grid-template-columns: auto 1fr auto;
    margin-bottom: 0;
}

.stretcher {
    align-items: flex-end;
    display: flex;

    div {
        border-top: 1px dashed $c-additional-gray-blue-30;
        height: 1px;
        margin-bottom: 3px;
        width: 100%;
    }
}

.list {
    display: grid;
    grid-auto-rows: max-content;
    grid-row-gap: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.itemValue {
    @include ellipsis(1);
}
