@import 'src/styles/mixins';

.root {
    display: block;
    height: 100%;
}

.imageBlock {
    position: relative;
}

.ads {
    bottom: 8px;
    position: absolute;
    right: 8px;
}

.date {
    display: block;
    margin-bottom: 8px;
}

.image {
    aspect-ratio: 1.6;
    display: block;
    object-fit: cover;
    width: 100%;

    @media #{$screen-mobile} {
        aspect-ratio: 1.36;
    }

    img {
        object-fit: cover;
    }
}

.title {
    -webkit-box-orient: vertical;
    display: box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-width: 350px;
    overflow: hidden;
    transition: color $animation;

    .root:hover & {
        color: $c-primary-60;
    }
}

.inner {
    background-color: $c-default-gray-10;
    height: 100%;
    padding: var(--p-xs-16);
}
