@import 'src/styles/font';
@import 'src/styles/mixins';

.previewCardsSlider {
    display: flex;
    position: relative;
    width: 100%;

    .slider {
        padding-bottom: 2px;
        position: relative;
        width: 100%;

        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
            z-index: 3;
        }

        :global(.swiper-button-prev) {
            left: -24px;
        }

        :global(.swiper-button-next) {
            right: -24px;
        }

        :global(.swiper-button-disabled) {
            display: none;
        }
    }

    .paddingOnBorder {
        @include padding-container-mobile;
    }
}

.previewCardsSliderPreloader {
    z-index: 4;

    @media #{$screen-mobile} {
        height: 100%;
        top: 0;
    }
}
