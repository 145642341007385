@import 'src/styles/mixins';

.root {
    background-color: $c-default-gray-10;
    border-radius: var(--r-lg);
    display: flex;
    flex-direction: column;
    gap: var(--p-md);
    padding: var(--p-sm);
}

.actions {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs);
}

.button {
    align-self: flex-start;
}
