@import 'src/styles/mixins';

.cartProducts {
    .cartProductsListContainer {
        display: flex;
        flex-direction: column;
    }

    .cartProductsListMissingText {
        background-color: $c-status-error-10;
        border-radius: 4px;
        padding: 20px 16px;

        @include fluid-prop-sm(margin-bottom, 16px, 24px);

        @media #{$screen-mobile} {
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    .cartProductsList {
        flex-grow: 1;
    }

    .orderMakingWrap {
        height: max-content;
        margin-left: auto;
        position: sticky;
        top: 16px;

        @media #{$screen-mobile} {
            @include container;
        }
    }
}

.ofStockHeader {
    align-items: flex-end;
    column-gap: 12px;
    display: flex;

    @include container;
}

.ofStock,
.isNotEnough,
.isGift {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.isNotEnough {
    @media #{$screen-mobile} {
        scroll-margin-top: var(--header-height-adaptive);
    }
}

.titleCount {
    display: flex;
    flex-shrink: 0;
    gap: 4px;
}

.titleUnit {
    @include hide-mobile-show-desktop;
}
