@import 'src/styles/mixins';

.root {
    row-gap: 40px;

    @media #{$screen-mobile} {
        row-gap: 24px;
    }
}

.brandCard {
    border: 1px solid $c-primary-10;
    height: 100%;
}
