@import 'src/styles/mixins';

.root {
    background-color: $c-default-gray-10;
    border-radius: var(--r-md);
    display: flex;
    padding: var(--p-sm);
    width: 100%;
}

.actions {
    display: flex;
    flex-shrink: 0;

    svg {
        color: $c-default-gray-100;
    }

    @include fluid-prop-sm(gap, 4px, 8px);
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    justify-content: center;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.mainAddress {
    color: $c-additional-accent-elements-text;
}
