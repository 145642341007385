@import 'src/styles/mixins';

.bottomBar {
    background-color: $c-default-white-100;
    border-top: 1px solid $c-additional-gray-blue-30;
    bottom: 0;
    display: none;
    margin-bottom: env(safe-area-inset-bottom);
    padding: 10px 16px 12px;
    position: fixed;
    width: 100%;
    z-index: 10;

    @media #{$screen-mobile} {
        display: block;
    }

    &.disabled {
        opacity: 0.3;
    }

    &.isNeedShadow {
        box-shadow: 0 -2px 10px 0 $c-shadow-tabbar;
    }
}

.bottomBarList {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
}

.bottomBarItem {
    align-items: flex-end;
    display: flex;
    justify-content: center;

    & > div {
        height: 100%;
    }

    button {
        align-items: center;
        color: $c-default-gray-60;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        transition: color $animation;

        &:disabled {
            cursor: auto;
        }
    }

    svg {
        height: 28px;
        width: 28px;
    }

    &.active {
        button {
            color: $c-secondary-100;
        }
    }
}

.svgIconContainer {
    margin-bottom: 4px;
}
