@import 'src/styles/mixins';

.priceItem {
    align-items: flex-end;
    display: flex;
    gap: 8px;

    @media #{$screen-mobile} {
        flex-direction: row-reverse;
    }
}

.oldPrice {
    color: $c-additional-price-old;
    text-decoration: line-through;
}

.totalPrice {
    color: $c-additional-price-basic;
}
