@import 'src/styles/mixins';
@import 'src/styles/font';

.content {
    display: flex;
    flex-direction: column;
    min-width: 408px;

    @media #{$screen-mobile} {
        min-width: auto;
    }

    &::after {
        border: 10px solid transparent;
        border-bottom: 10px solid $c-default-white-100;
        content: '';
        left: 20px;
        position: absolute;
        top: -10px;

        @media #{$screen-mobile} {
            content: none;
        }
    }
}

.title {
    color: $c-primary-80;
    margin-bottom: 12px;

    @media #{$screen-mobile} {
        @include f_h5;
    }
}

.description {
    margin-bottom: 14px;
}

.contentButtonDesktop {
    color: $c-primary-80;
    transition: color $animation;

    &:hover {
        color: $c-primary-50;
    }
}

.contentButtonAdaptive {
    @media #{$screen-mobile} {
        align-items: center;
        background-color: $c-primary-80;
        border-radius: 4px;
        color: $c-default-white-100;
        display: flex;
        justify-content: center;
        padding: 10px 12px;

        svg {
            height: 20px;
            width: 20px;
        }
    }

    &:hover {
        color: $c-primary-50;

        @media #{$screen-mobile} {
            background-color: $c-primary-50;
            color: $c-default-white-100;
        }
    }
}
