@import 'src/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    padding: var(--p-sm) 0 var(--p-md) 0;

    &.withHorizontalPadding {
        padding: var(--p-sm) var(--p-md) var(--p-md) var(--p-md);
    }

    button:not(:first-of-type) {
        @include fluid-props-sm-p-xs(margin-top);
    }

    @media #{$screen-mobile} {
        margin-top: auto;
        padding: var(--p-sm) 0;

        &.withHorizontalPaddings {
            padding: var(--p-sm);
        }
    }
}
