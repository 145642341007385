.tab {
    & + :global(.tab-label-component) {
        border-radius: 0;
        white-space: nowrap;
    }
}

.tabGroupActive {
    overflow-x: auto;
}
