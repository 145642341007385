@import 'src/styles/mixins';

.root {
    @include container;
}

.container {
    display: flex;
    flex-direction: column;
    gap: var(--p-xxs);
}
