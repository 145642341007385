@import 'src/styles/mixins';

.productCardDescription {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-16);
}

.productCardDescriptionTitle {
    color: $c-default-gray-100;
}

.productCardDescriptionText {
    color: $c-default-gray-80;
    overflow: hidden;
    width: 100%;
}

.productCardDescriptionButton {
    svg {
        transform: rotate(180deg);
    }

    &.textHidden {
        svg {
            transform: rotate(0);
        }
    }
}

.test {
    @include ellipsis(4);
}
