@import 'src/styles/mixins';
@import 'src/styles/font';

.root {
    border-bottom: 1px solid $c-secondary-30;
    padding-bottom: var(--p-md);
    padding-top: var(--p-lg);

    @media #{$screen-mobile} {
        border-bottom: none;
        padding: var(--p-sm-24) 0;
    }
}

.content {
    @media #{$screen-mobile} {
        gap: var(--p-xs);
        position: relative;

        &::after {
            background-color: $c-secondary-30;
            bottom: -24px;
            content: '';
            height: 1px;
            left: 16px;
            position: absolute;
            right: 16px;
        }
    }
}

.titleBlock {
    @include fluid-prop-sm(gap, 8px, 16px);

    display: flex;
    flex-direction: column;

    @media #{$screen-mobile} {
        margin-bottom: 16px;
    }
}

.formBlock {
    & form {
        display: grid;
        gap: var(--p-sm);
        grid-template-columns: auto 198px;
        margin-bottom: var(--p-xs-16);

        @media #{$screen-mobile} {
            display: flex;
            flex-direction: column;
        }
    }

    .submitBtn {
        @include fluid-prop-sm(height,  48px, 60px);
    }

    :global(.input-label) {
        @include f_elements_p;
    }
}

.agreementBlock {
    .agreementText {
        color: $c-default-gray-a50;
    }
}
