@import 'src/styles/mixins';

.root {
    input {
        display: none;
    }

    .label {
        align-items: center;
        background-color: $c-default-white-100;
        border-radius: var(--r-xl);
        display: flex;
        height: 32px;
        justify-content: center;
        padding: 0 var(--p-sm);
        transition: background-color $animation;
    }

    input:checked + .label {
        background-color: $c-secondary-100;
        color: $c-default-white-100;
    }

    input:disabled & .label {
        color: $c-default-gray-40;
    }
}
