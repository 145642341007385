@import 'src/styles/mixins';

.root {
    align-items: flex-start;
    display: flex;
    gap: 16px;

    @media #{$screen-mobile} {
        flex-direction: column;
        gap: 8px;
    }
}

.leftSide {
    flex-shrink: 0;
    max-width: 170px;
    width: 100%;

    @media #{$screen-mobile} {
        max-width: unset;
    }
}

.rightSide {
    width: 100%;
    word-break: break-word;
}
