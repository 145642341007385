@import 'src/styles/mixins';
@import 'src/styles/font';

.addToCartPrice {
    display: none;

    @media #{$screen-mobile} {
        display: inline;
    }
}

.addToCartPriceCurrency {
    margin-left: 2px;
}

.addToCartButton {
    align-items: center;
    justify-content: center;
    width: 100%;

    &.list {
        @media #{$screen-mobile} {
            height: 40px;
        }
    }

    &.auto,
    &.tile {
        .text {
            @include show-mobile-hide-desktop;
        }

        .svg {
            @include hide-mobile-show-desktop;
        }
    }

    &.preview,
    &.searchPanel {
        &:global(.button-component) {
            height: 40px;
            width: 40px;

            @media #{$screen-mobile} {
                height: 36px;
                width: 36px;
            }
        }
    }
}

.addToCartCounter {
    display: none;
    flex-shrink: 0;

    &.isDisplay {
        display: flex;
    }

    &.tile {
        width: 144px;

        @media #{$screen-mobile} {
            width: auto;
        }
    }

    &.preview {
        width: 115px;

        @media #{$screen-mobile} {
            outline: 1px solid $c-default-gray-30;
            width: 100px;
        }
    }
}
