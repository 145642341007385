@import 'src/styles/mixins';

.slider {
    overflow: hidden;

    &:global(.swiper-horizontal) {
        touch-action: pan-y;
    }

    &:global(.swiper-vertical) {
        touch-action: pan-x;
    }

    :global(.swiper-wrapper) {
        display: flex;
    }

    :global(.swiper-slide) {
        display: grid;
        flex-shrink: 0;
    }

    :global(.swiper-pagination) {
        display: none;
        overflow: hidden;

        @media #{$screen-mobile} {
            gap: var(--p-xxxs);
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
        }
    }

    :global(.swiper-pagination-lock) {
        display: none;
    }

    :global(.swiper-pagination-bullet) {
        background-color: $c-primary-30;
        border-radius: var(--r-xs);
        flex-shrink: 0;
        height: 4px;
        position: relative;
        transition: left $animation;
        width: 4px;
    }

    :global(.swiper-pagination-clickable) {
        cursor: pointer;
    }

    :global(.swiper-pagination-bullet-active) {
        background-color: $c-secondary-100;
    }

    :global(.swiper-button-lock) {
        display: none;
    }
}

.sliderButtonsDefault {
    :global(.swiper-button-prev),
    :global(.swiper-button-next) {
        background-color: $c-default-white-100;
        border-radius: 50%;
        box-shadow: 0 2px 12px $c-shadow-slider-buttons;
        cursor: pointer;
        height: 48px;
        width: 48px;
        z-index: 2;

        @media #{$screen-mobile} {
            display: none;
        }

        &::before {
            background-color: $c-default-gray-100;
            content: '';
            height: 24px;
            left: 12px;
            mask-image: url('/icons/arrow.svg');
            position: absolute;
            top: 12px;
            width: 24px;
        }

        &:hover {
            &::before {
                background-color: $c-secondary-100;
                content: '';
                height: 24px;
                left: 12px;
                mask-image: url('/icons/arrow.svg');
                position: absolute;
                top: 12px;
                width: 24px;
            }
        }

        &:focus {
            &::before {
                background-color: $c-secondary-100;
                content: '';
                height: 24px;
                left: 12px;
                mask-image: url('/icons/arrow.svg');
                position: absolute;
                top: 12px;
                width: 24px;
            }
        }
    }

    :global(.swiper-button-prev) {
        &::before {
            rotate: -180deg;
        }
    }

    :global(.swiper-button-disabled) {
        background-color: $c-default-white-a70;
        cursor: auto;

        &::before {
            background-color: $c-default-gray-50;
        }

        &:hover,
        &:focus {
            background-color: $c-default-white-a70;

            &::before {
                background-color: $c-default-gray-50;
            }
        }
    }
}
