@import 'src/styles/mixins';

.headerFixed {
    background-color: inherit;
    box-shadow: 0 0 0 0 $c-shadow-header;
    gap: 0;
    left: 0;
    position: fixed;
    top: 0;
    transition: background-color $animation, box-shadow $animation;
    width: 100%;
    z-index: 6;

    &.isDefault {
        background-color: $c-background-page;
    }

    &.isNeedShadow.isNotAtTop {
        box-shadow: 0 2px 4px 0 $c-shadow-a2;
    }

    &.isInContext {
        margin-bottom: 48px;
        position: static;
    }

    &.dark {
        // --header-color: #fff;
        // --header-hover-color: #f0f3f5;
        // --header-search-color: #f0f3f5;
        // --header-search-border-color: rgb(255 255 255 / 10%);
        // --header-search-filled-border-color: #ced1d9;
        // --header-search-close-button-color: #f0f3f5;
        // --header-search-text-color: #fff;
        // --header-count-background: #f3d45d;
        // --header-count-color: #1c1c1c;
        // --header-border-color: rgb(255 255 255 / 10%);

        &.isDefault {
            // --header-color: #1c1c1c;
            // --header-hover-color: #6f6f6f;
            // --header-search-color: #6f6f6f;
            // --header-search-border-color: rgb(28 28 28 / 10%);
            // --header-search-filled-border-color: #3c4568;
            // --header-search-close-button-color: #8f8f8f;
            // --header-search-text-color: #1c1c1c;
            // --header-count-background: #0b1742;
            // --header-count-color: #fff;
            // --header-border-color: rgb(28 28 28 / 10%);

            background-color: $c-default-white-100;
        }

        & .logo {
            svg {
                color: var(--header-color);
            }
        }
    }

    .menuButton:global(.button-component) {
        color: var(--header-color);

        &:hover {
            color: var(--header-hover-color);
        }
    }

    & :global(.icon-element-count) {
        background-color: var(--header-count-background);
        color: var(--header-count-color);
    }

    @media #{$screen-mobile} {
        display: none;
    }
}

.headerTopContainer {
    background-color: $c-background-header;
}

.headerTop {
    @include container;

    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 40px;
}

.headerTopSide {
    align-items: center;
    display: flex;
    gap: 32px;
}

.headerBottomContainer {
    background-color: $c-background-page;
}

.headerBottom {
    @include container;

    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    min-height: 44px;
    padding-bottom: var(--p-xs);
}

.container {
    @include container;

    align-items: center;
    display: grid;
    gap: 24px;
    grid-template-columns: auto min-content;
    padding-bottom: 16px;
    padding-top: 16px;
}

.leftBlock,
.rightBlock {
    align-items: center;
    display: flex;
}

.rightBlock {
    justify-content: flex-end;
}

.catalogButtonContainer {
    border-radius: var(--r-xl);
    overflow-x: hidden;
    transition: all $animation;
    width: 0;

    &.isDisplayed {
        width: 167px;
    }
}

.catalogButton {
    margin-right: 24px;

    &:global(.button-component) {
        height: 44px;
        padding: 0 24px;
    }
}

.logo {
    margin-right: 24px;

    svg {
        display: block;
        height: 30px;
        width: 159px;
    }
}

.headerLinkList {
    align-items: center;
    display: flex;
    gap: 24px;
    list-style: none;
    margin: 0 0 10px;
    padding: 0;
}

.search {
    flex-grow: 1;

    :global {
        .search-button {
            color: $c-default-gray-100;
        }

        .search-input {
            background-color: inherit;
            color: var(--header-search-text-color);
        }
    }

    &:hover {
        :global {
            .search-label {
                border-bottom-color: var(--header-search-hover-border-color);
            }
        }
    }

    &.active {
        :global {
            .search-label {
                @include container;

                border-bottom: none;
                z-index: 3;
            }

            .search-input {
                background-color: $c-default-white-100;
            }
        }
    }
}
