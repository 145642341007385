@import 'src/styles/mixins';

.root {
    @include container;

    display: flex;
    flex-direction: column;
    padding-top: 128px;
    row-gap: var(--p-lg);

    @media #{$screen-mobile} {
        padding-top: 80px;
    }

    &.withoutPaddingTop {
        padding-top: 0;
    }
}
