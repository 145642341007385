@import 'src/styles/mixins';

.quickFilterTag {
    align-items: center;
    box-shadow: 0 0 0 1px $c-primary-20 inset;
    gap: 8px;
    padding: 14px 16px;
    transition: all $animation;

    @media #{$screen-mobile} {
        gap: 4px;
        padding: 12px 16px;
    }

    &:hover {
        box-shadow: 0 0 0 1px $c-primary-10 inset;
    }

    &.active {
        box-shadow: 0 0 0 1px $c-primary-70 inset;

        &:hover {
            box-shadow: 0 0 0 1px $c-primary-10 inset;
        }
    }

    .container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }

    .name {
        color: $c-default-gray-100;
        white-space: nowrap;
    }
}
