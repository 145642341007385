@import 'src/styles/mixins';

.root {
    display: none;

    @media #{$screen-mobile} {
        @include container;

        align-items: center;
        background-color: $c-default-white-100;
        bottom: var(--bottom-bar-height);
        box-shadow: 0 -2px 10px 0 $c-shadow-tabbar;
        display: flex;
        justify-content: space-between;
        left: 0;
        max-width: none;
        padding-bottom: 12px;
        padding-top: 12px;
        position: fixed;
        top: auto;
        transition: bottom $animation;
        width: 100%;
        z-index: 2;

        &.inView {
            bottom: -50px;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-items: center;
    row-gap: 4px;
}
