$animation-duration-1: 100ms;
$animation-duration: 300ms;
$animation-duration-x2: 600ms;
$animation-duration-x3: 900ms;
$animation-duration-x4: 1200ms;
$animation-duration-progress-carousel: 5s;

$animation: $animation-duration ease;
$animation-in: $animation-duration ease-in;
$animation-out: $animation-duration ease-out;
$animation-in-out: $animation-duration ease-in-out;
$animation-in-out-1: $animation-duration-1 ease-in-out;

$animation-1: $animation-duration-1 ease;

$animation-x2: $animation-duration-x2 ease;
$animation-in-x2: $animation-duration-x2 ease-in;
$animation-out-x2: $animation-duration-x2 ease-out;
$animation-in-out-x2: $animation-duration-x2 ease-in-out;

$animation-x3: $animation-duration-x3 ease;
$animation-in-x3: $animation-duration-x3 ease-in;
$animation-out-x3: $animation-duration-x3 ease-out;

$animation-loader: spin $animation-duration-x3 linear infinite;
$animation-toast-right: toast-in-right $animation-duration-x2 ease-in-out;
$animation-toast-left: toast-in-left $animation-duration-x2 ease-in;
$animation-toast-top-center: toast-in-top-center $animation-duration-x2 ease-in;
$animation-loader-back-rotate: spin-back-rotate $animation-duration-x3 linear infinite;
$animation-skeleton: skeleton-blinking $animation-duration-x4 infinite;

$animation-progress-carousel: $animation-duration-progress-carousel ease-in 1 alternate forwards progress-carousel;

@keyframes spin {
    0% {
        --webkit-transform: rotate(360deg);

        transform: rotate(0deg);
    }

    100% {
        --webkit-transform: rotate(360deg);

        transform: rotate(360deg);
    }
}

@keyframes spin-back-rotate {
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-top-center {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes progress-carousel {
    0% {
        width: 0;
    }

    100% {
        width: 130px;
    }
}

@keyframes skeleton-blinking {
    100% {
        transform: translateX(100%);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
