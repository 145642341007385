@import 'src/styles/mixins';

.mainFormBlockGender {
    align-items: center;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    width: max-content;
}

.genderBlock {
    align-items: center;
    display: grid;
    gap: 24px;
    grid-template-columns: 105px 105px;
}

.fieldSet {
    border: none;
    margin: 0;
    outline: none;
    padding: 0;
    row-gap: var(--p-sm);

    @media #{$screen-mobile} {
        display: flex;
        flex-direction: column;
    }
}
