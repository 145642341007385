@import 'src/styles/mixins';

.root {
    align-items: center;
    display: flex;
    gap: 16px;
}

.content {
    align-items: center;
    display: flex;
    gap: 8px;

    svg {
        height: 20px;
        width: 20px;
    }
}

.paid {
    svg {
        color: $c-status-success-50;
    }
}

.notPaid {
    svg {
        color: $c-status-error-50;
    }
}
