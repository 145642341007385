@import 'src/styles/mixins';

.areaContent {
    margin-top: 25px;
}

.container {
    @include container;
}

.paginationContainer {
    margin-top: 48px;

    @media #{$screen-mobile} {
        margin-top: 32px;
    }
}
