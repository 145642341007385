@import 'src/styles/mixins';

.root {
    @include fluid-prop-padding-top-bottom-sm(16px, 24px);

    display: flex;

    @media #{$screen-mobile} {
        flex-direction: column;
        row-gap: 16px;
    }
}

.blockTitleBrand {
    @include fluid-prop-sm(gap, $grid-gap-sm, $grid-gap);

    columns: 1;
    flex: 3 3 75%;

    @media #{$screen-tablet} {
        columns: 2;
    }

    @media #{$screen-desktop} {
        columns: 3;
    }
}

.blockTitleCard {
    flex: 1 1 25%;
}

.item {
    margin-bottom: 8px;
}

.titleBrand {
    transition: color $animation;

    &:hover {
        color: $c-default-gray-80;
    }
}
