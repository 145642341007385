@import 'src/styles/mixins';

.root {
    @include container;

    bottom: 8px;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 100;
}

.content {
    align-items: center;
    background: $c-status-info-10;
    border-radius: var(--r-md);
    display: flex;
    gap: 24px;
    padding: var(--p-xs-16) var(--p-sm);
    width: max-content;

    @media #{$screen-mobile} {
        align-items: stretch;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .inner {
        align-items: flex-start;
        display: flex;
        gap: 8px;

        @media #{$screen-mobile} {
            flex-direction: column;
        }
    }

    .icon {
        color: $c-default-gray-100;
        height: 24px;
        width: 24px;

        @media #{$screen-mobile} {
            height: 20px;
            width: 20px;
        }
    }

    .text {
        max-width: 175px;

        @media #{$screen-mobile} {
            max-width: 100%;
        }
    }
}

.desktop {
    @media #{$screen-mobile} {
        display: none;
    }
}
