@import 'src/styles/mixins';

.comboFilterTagList {
    align-items: center;
    background-color: $c-default-white-100;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    list-style: none;
    margin: 0 0 40px;
    padding: 0;

    @media #{$screen-mobile} {
        flex-wrap: nowrap;
        margin: 0 0 -4px;
        overflow: auto;
        padding-bottom: 4px;
    }

    li[data-skeleton-item] {
        overflow: initial;
    }
}
