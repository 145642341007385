@import 'src/styles/mixins';

.franchisePage {
    @include container;
}

.description {
    color: $c-common-gray;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.content {
    @include fluid-prop-sm(margin-top, 32px, 48px);

    background-color: $c-default-gray-10;
    border-radius: var(--r-xl);
    display: grid;
    grid-template-columns: 1fr 524px;
    overflow: hidden;

    @media #{$screen-mobile} {
        display: flex;
        flex-direction: column-reverse;
    }

    &.onlyForm {
        grid-template-columns: 1fr;
    }
}

.leftSide {
    padding: var(--p-lg) var(--p-md-40-16);
}

.rightSide {
    border-radius: var(--r-xl);
    overflow: hidden;

    img {
        object-fit: cover;

        @media #{$screen-mobile} {
            min-height: 273px;
        }
    }
}
