@import 'src/styles/mixins';
@import 'src/styles/font';

@mixin large() {
    border-radius: var(--r-xl);
    height: 48px;
    width: 100%;

    @media #{$screen-mobile} {
        height: 36px;
    }

    &.static {
        width: 176px;

        @media #{$screen-mobile} {
            width: 105px;
        }
    }

    .input {
        @include f_p-regular;

        width: 100%;
    }

    .button {
        height: 48px;
        width: 48px;

        @media #{$screen-mobile} {
            height: 36px;
            width: 36px;
        }
    }
}

@mixin medium() {
    border-radius: var(--r-xl);
    height: 40px;
    width: 100%;

    @media #{$screen-mobile} {
        height: 36px;
    }

    &.static {
        width: 160px;

        @media #{$screen-mobile} {
            width: 105px;
        }
    }

    .input {
        @include f_p-regular;

        width: 100%;
    }

    .button {
        height: 40px;
        width: 40px;

        @media #{$screen-mobile} {
            height: 36px;
            width: 36px;
        }
    }
}

@mixin small() {
    border-radius: var(--r-xl);
    height: 32px;
    width: 100%;

    &.static {
        width: 160px;

        @media #{$screen-mobile} {
            width: 97px;
        }
    }

    .input {
        @include f_p-regular;

        width: 100%;
    }

    .button {
        height: 32px;
        width: 32px;
    }
}

.counter {
    background-color: $c-default-white-100;
    display: flex;
    justify-content: space-between;
    outline: 1.5px solid $c-default-gray-30;

    &.large {
        @include large;
    }

    &.medium {
        @include medium;
    }

    &.small {
        @include small;
    }

    &.large-medium {
        @include large;

        @media #{$screen-mobile} {
            @include medium;
        }
    }

    &.horizontal {
        &.large {
            max-width: 176px;

            @media #{$screen-mobile} {
                max-width: 140px;
            }

            &.static {
                width: 176px;
            }

            @media #{$screen-mobile} {
                width: 140px;
            }
        }

        &.medium {
            max-width: 160px;

            @media #{$screen-mobile} {
                max-width: 140px;
            }

            &.static {
                width: 160px;

                @media #{$screen-mobile} {
                    width: 140px;
                }
            }
        }

        &.small {
            max-width: 160px;

            @media #{$screen-mobile} {
                max-width: 97px;
            }

            &.static {
                width: 160px;

                @media #{$screen-mobile} {
                    width: 97px;
                }
            }
        }

        &.large-medium {
            max-width: 176px;

            @media #{$screen-mobile} {
                max-width: 140px;
            }

            &.static {
                width: 176px;

                @media #{$screen-mobile} {
                    width: 140px;
                }
            }
        }

        &.large,
        &.medium {
            @media #{$screen-mobile} {
                max-width: 140px;

                &.static {
                    width: 140px;
                }
            }
        }

        &.large,
        &.medium,
        &.large-medium {
            .input {
                max-width: 80px;

                @media #{$screen-mobile} {
                    max-width: 60px;
                }
            }
        }
    }
}

.input {
    border: none;
    color: $c-default-gray-100;
    outline: none;
    padding: 0 4px;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;

    @include f_p-medium('lg');

    @media #{$screen-mobile} {
        @include f_p-medium('xs');
    }

    &:disabled {
        background-color: $c-default-white-100;
        color: $c-additional-gray-blue-30;
    }
}

.button {
    align-items: center;
    background-color: $c-primary-100;
    border-radius: 50%;
    color: $c-default-white-100;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    transition: all $animation;

    svg {
        height: 24px;
        width: 24px;
    }

    &:hover {
        background-color: $c-primary-80;
    }

    &:disabled {
        background-color: $c-primary-30;
        color: $c-default-white-a90;
        cursor: auto;
    }
}
