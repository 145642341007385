@import 'src/styles/mixins';
@import 'src/styles/font';

.productListLayoutControlBar {
    align-items: center;
    display: flex;

    @media #{$screen-mobile} {
        justify-content: flex-end;
    }
}

.productListLayoutControlBarCollections {
    @media #{$screen-mobile} {
        width: 100%;
    }
}

.productListLayoutControlBarLeft {
    @media #{$screen-mobile} {
        width: 100%;
    }
}

.productListLayoutProductCount {
    @media #{$screen-mobile} {
        width: max-content;
    }
}

.selectPerPage {
    width: 194px;

    @media #{$screen-mobile} {
        display: none;
    }
}

.productListLayoutFilterButton {
    margin-right: 24px;

    @media #{$screen-mobile} {
        margin-right: 12px;
    }
}

.productListLayoutControlBarMobileIndicator {
    color: $c-additional-gray-blue-80;
    height: 24px;
    width: 24px;

    @include show-mobile-hide-desktop;

    svg {
        height: 24px;
        width: 24px;
    }
}

.productListLayoutFilterSelect {
    width: max-content;

    & :global(.custom-select__single-value) {
        @include f_p-regular;

        padding: 0 2px;
        text-overflow: initial;
    }
}
