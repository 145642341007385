@import 'src/styles/mixins';

.rootContainer {
    align-items: center;
    border-radius: var(--r-md);
    column-gap: var(--p-sm);
    display: flex;
    padding: var(--p-xs-16) var(--p-sm);
    width: 100%;

    @media #{$screen-mobile} {
        align-items: unset;
        flex-direction: column;
        row-gap: var(--p-xs);
    }
}

.container {
    display: flex;
    flex-direction: column;
    row-gap: var(-p-xs-16);
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    row-gap: var(-p-xxs);
}

.onlyContentWithIcon {
    padding-top: 2px;
}

.contentContainer {
    align-items: start;
    column-gap: var(--p-xxs);
    display: flex;
    flex-basis: 100%;
}

.icon {
    @include fluid-prop-square-sizes-sm(20px, 24px);

    color: $c-default-gray-100;
    flex-shrink: 0;
}

.infoType {
    background-color: #{$c-status-info-10};
}

.warningType {
    background-color: #{$c-status-warning-20};
}

.errorType {
    background-color: #{$c-status-error-20};
}

.buttonWrapperRight {
    align-items: center;
    display: none;
    height: 100%;

    @media #{$screen-mobile} {
        display: flex;
        width: 100%;
    }

    &.positionRight {
        display: flex;
    }
}

.buttonWrapperBottom {
    @media #{$screen-mobile} {
        display: none;
    }
}

.button {
    @media #{$screen-mobile} {
        width: 100%;
    }
}
