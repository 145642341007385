@import 'src/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    gap: var(--p-sm-24);
}

.deliveryContent {
    display: flex;
    flex-direction: column;

    @include fluid-prop-sm(row-gap, 16px, 24px);
}

.deliveryItem {
    align-items: center;
    column-gap: 2px;
    display: flex;
}

.leftBlock {
    align-items: center;
    column-gap: 8px;
    display: flex;

    & > svg {
        height: 32px;
        width: 32px;
    }
}

.deliveryPrice {
    flex-shrink: 0;
}

.icon {
    height: 32px;
    object-fit: contain;
    object-position: center;
    width: 32px;

    img {
        height: 100%;
        width: 100%;
    }
}
