@import 'src/styles/mixins';
@import 'src/styles/font';

.shield {
    align-items: center;
    border-radius: var(--r-md);
    display: grid;
    grid-auto-flow: column;
    overflow: hidden;

    .text {
        @include ellipsis(1);

        align-items: center;
        display: grid;
        grid-auto-flow: column;
    }

    &.clickable {
        cursor: pointer;
    }

    &.big {
        padding: 4px 12px;
    }

    &.medium {
        column-gap: 4px;
        height: 28px;
        padding: 4px 8px;

        .text {
            column-gap: 4px;
        }

        .icon,
        svg,
        img {
            height: 20px;
            width: 20px;
        }

        @media #{$screen-mobile} {
            height: 25px;

            .icon,
            svg,
            img {
                height: 16px;
                width: 16px;
            }
        }
    }

    &.small {
        column-gap: 4px;
        padding: 4px 8px;

        .text {
            column-gap: 4px;
        }

        .icon,
        svg,
        img {
            height: 16px;
            width: 16px;
        }
    }

    &.ads {
        background-color: $c-default-gray-a50;
        color: $c-default-white-a90;
    }

    &.info {
        background-color: $c-additional-shields-info;
        color: $c-default-gray-100;
    }

    &.new {
        background-color: $c-additional-shields-new;
        color: $c-default-gray-100;
    }

    &.notAvailable {
        background-color: $c-additional-shields-not-available;
        color: $c-default-gray-100;
    }

    &.sale {
        background-color: $c-additional-shields-sale;
        color: $c-default-white-100;
    }
}

.shieldDesktop {
    display: grid;

    @media #{$screen-mobile} {
        display: none;
    }
}

.shieldMobile {
    display: none;

    @media #{$screen-mobile} {
        display: inline-grid;
    }
}

.shieldCompactMobile {
    align-items: center;
    height: 20px;
    justify-content: center;
    overflow: hidden;
    width: 20px;

    @include show-mobile-hide-desktop(flex);

    & > span {
        display: none;
    }

    .icon,
    svg,
    img {
        flex-shrink: 0;
        height: 16px;
        width: 16px;
    }

    &.medium {
        height: 24px;
        width: 24px;

        .icon,
        svg,
        img {
            height: 20px;
            width: 20px;
        }
    }
}

.shieldCompactDesktop {
    align-items: center;
    height: 24px;
    justify-content: center;
    overflow: hidden;
    width: 24px;

    @include hide-mobile-show-desktop(flex);

    & > span {
        display: none;
    }

    .icon,
    svg {
        flex-shrink: 0;
        height: 16px;
        width: 16px;
    }

    &.medium {
        height: 28px;
        width: 28px;

        .icon,
        svg {
            height: 20px;
            width: 20px;
        }
    }
}

.shieldTooltip {
    padding: 24px;
}

.shieldModal {
    bottom: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 36px 24px;
    position: absolute;
    top: auto;
}
