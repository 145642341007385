@import 'src/styles/mixins';

.root {
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
    list-style: none;
    margin: 0;
    min-width: 245px;
    overflow: hidden;
    padding: 0;
}

.item {
    align-items: center;
    color: $c-default-gray-80;
    cursor: pointer;
    display: flex;
    gap: 12px;
    padding: var(--p-xxs) var(--p-sm);
    transition: background-color $animation;

    &:hover {
        background-color: $c-additional-custom-hovers-on-component;
        transition: background-color $animation;
    }

    &.currentActive {
        background-color: $c-primary-10;
        color: $c-default-gray-80;
    }

    .linkContent {
        align-items: center;
        display: flex;
        gap: 8px;

        svg {
            color: $c-secondary-100;
        }
    }
}
