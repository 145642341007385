@import 'src/styles/mixins';

.headerWrapper {
    background-color: $c-default-white-100;
    filter: drop-shadow(0 2px 25px $c-shadow-large-tooltip);
    width: 100%;
}

.headerContainer {
    align-items: center;
    display: flex;
    padding-bottom: var(--p-xs-12-8);
    padding-top: var(--p-xs-12-8);
    width: 100%;

    @include container;

    @media #{$screen-mobile} {
        padding-bottom: 4px;
        padding-top: 4px;
    }
}

.image {
    border-radius: 4px;
    height: 56px;
    object-fit: cover;
    width: 56px;

    svg {
        height: 100%;
        width: 100%;
    }
}

.headerLeft {
    display: flex;
    flex-shrink: 0;

    .title {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
    }
}

.headerRight {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--p-sm);
    justify-content: end;
    margin-right: 0;
    width: 100%;

    @media #{$screen-mobile} {
        display: none;
    }
}

.imageAndPrice {
    align-items: center;
    column-gap: 16px;
    display: flex;
}

.actions {
    align-items: center;
    column-gap: var(--p-xs);
    display: flex;
}
