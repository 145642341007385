@import 'src/styles/mixins';

.root {
    display: flex;
    flex-direction: column;

    @include fluid-prop-sm(gap, 32px, 48px);
}

.marginTop {
    margin-top: 24px;
}

.header {
    display: flex;
    gap: 16px;

    @media #{$screen-mobile} {
        flex-direction: column;
        gap: 8px;
    }
}
