@import 'src/styles/mixins';

.content {
    display: grid;
    gap: var(--p-sm);
    grid-template-columns: 1fr 1fr;
    height: 460px;
    margin-right: -32px;
    padding-right: 32px;

    @media #{$screen-mobile} {
        grid-template-columns: auto;
        height: 350px;
        margin-right: -16px;
        padding-right: 0;
    }

    &.isOnlySlider {
        grid-template-columns: 1fr;
        margin-left: -32px;
        padding-left: 32px;
    }
}

.rightSide {
    max-width: 668px;

    @media #{$screen-mobile} {
        max-width: 100%;
        overflow: hidden;
    }

    &.isOnlySlider {
        margin-left: -32px;
        margin-right: -32px;
        max-width: none;
        overflow: hidden;
        padding-left: 32px;
        padding-right: 32px;

        @media #{$screen-mobile} {
            margin-right: 0;
            padding-right: 0;
        }
    }
}

.rightSideSlider {
    overflow: hidden;
}

.slider {
    display: flex;
    height: 460px;
    max-width: 100%;
    position: relative;

    @media #{$screen-mobile} {
        height: 350px;
    }

    :global {
        .swiper-slide {
            max-width: 282px;
            overflow: hidden;

            @media #{$screen-mobile} {
                max-width: 200px;
            }
        }
    }

    &.desktop {
        @include hide-mobile-show-desktop(flex);
    }

    &.mobile {
        @include show-mobile-hide-desktop(flex);
    }
}

.bannerDesktop,
.bannerMobile {
    border-radius: var(--r-lg);
    overflow: hidden;
    position: relative;
}

.bannerDesktop {
    @include hide-mobile-show-desktop;
}

.bannerMobile {
    @include show-mobile-hide-desktop;

    @media #{$screen-mobile} {
        height: 350px;
        width: 200px;
    }
}
