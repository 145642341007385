@import 'src/styles/mixins';

@mixin col-make($class, $query) {
    @include col-loop($grid-columns, $class, $query);
}

@mixin col-loop($index, $class, $query) {
    @if $index >= 0 {
        @include col-loop(($index - 1), $class, $query);

        &#{$class}#{$index} {
            @media #{$query} {
                grid-column: auto/span #{$index};
            }
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: repeat(var(--evo-cols, $grid-columns), 1fr);
    grid-template-rows: repeat(var(--evo-rows, 1), 1fr);
    row-gap: 16px;

    @include fluid-prop-sm(column-gap, $grid-gap-sm, $grid-gap);
}

.container {
    @include container;
}

.col {
    grid-column: 1 / -1;

    @include col-make(sm, $sm-min);
    @include col-make(md, $md-min);
    @include col-make(lg, $lg-min);
    @include col-make(xl, $xl-min);
}

.noRowGap {
    row-gap: 0;
}
