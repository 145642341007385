@import 'src/styles/mixins';
@import 'src/styles/font';

.container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &.cartResume {
        align-items: flex-end;
    }
}

.bonuses {
    margin-top: 4px;

    @media #{$screen-mobile} {
        margin-top: 0;
    }
}

.alternative {
    margin-top: 4px;
}

.title {
    margin-bottom: 4px;
}

.bottomTitle {
    @include f_p-medium;

    &.visualPreview {
        @include f_p;
    }
}
