@import 'src/styles/mixins';

.popup {
    right: 0;
}

.root {
    position: relative;
}

.list {
    position: relative;
    width: 226px;
    z-index: 2;
}
