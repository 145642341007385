@import 'src/styles/mixins';

.modalOverlay {
    background-color: $c-default-gray-a50;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 1;
}

.modalOverlayVisible {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
}
