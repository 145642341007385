@import 'src/styles/mixins';

.galleryButtonSlider {
    position: relative;

    @media #{$screen-mobile} {
        :global(.swiper-wrapper) {
            flex-direction: row;
        }
    }

    &::before,
    &::after {
        background-color: $c-default-white-100;
        content: '';
        position: absolute;
        z-index: 1;
    }

    :global(.swiper-button-prev-lock),
    :global(.swiper-button-next-lock) {
        display: none;
    }

    :global(.swiper-button-disabled) {
        display: none;
    }

    &.locked {
        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
            display: none;
        }

        &::before,
        &::after {
            content: none;
        }
    }

    &.basic,
    &.fullscreen {
        height: 100%;
        padding: 30px 0;
        width: auto;

        &.locked {
            padding: 30px 0;
        }

        &::before,
        &::after {
            height: 30px;
            width: 100%;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        :global(.swiper-wrapper) {
            flex-direction: column;
        }

        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
            position: absolute;
        }

        :global(.swiper-button-prev) {
            left: calc(50% - 24px);
            top: 6px;

            &::before {
                transform: rotate(90deg);
            }
        }

        :global(.swiper-button-next) {
            bottom: 6px;
            left: calc(50% - 24px);

            &::before {
                transform: rotate(90deg);
            }
        }
    }

    &.technical {
        height: 64px;
        padding: 0 45px;

        &.locked {
            padding: 0;
        }

        &::before,
        &::after {
            height: 48px;
            width: 48px;
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }

        :global(.swiper-button-prev),
        :global(.swiper-button-next) {
            height: 48px;
            position: absolute;
            width: 48px;
        }

        :global(.swiper-button-prev) {
            left: 0;
            top: 0;

            &::before {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(180deg);
            }
        }

        :global(.swiper-button-next) {
            right: 0;
            top: 0;

            &::before {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.fullscreen {
        @media #{$screen-mobile} {
            height: 78px;
            padding: 0;
            width: 100%;

            &::before,
            &::after {
                content: none;
            }

            :global(.swiper-wrapper) {
                flex-direction: row;
            }

            :global(.swiper-button-prev),
            :global(.swiper-button-next) {
                display: none;
            }
        }
    }
}
