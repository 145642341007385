@import 'src/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    gap: var(--p-xl);
}

.collectionItem {
    height: 455px;

    @media #{$screen-mobile} {
        height: 264px;
    }
}

.sliderBlock {
    margin-top: 16px;
}
