@import 'src/styles/mixins';
@import 'src/styles/font';

.inputSelect {
    cursor: pointer;
    position: relative;

    &::after {
        color: $c-default-gray-60;
        content: attr(data-label);
        left: 16px;
        position: absolute;
        top: 14px;
        transition: all $animation;

        @include f_p-medium;
    }

    &[data-label] {
        :global(.custom-select__placeholder) {
            display: none;
        }
    }

    :global(.custom-select) {
        height: 60px;

        @media #{$screen-mobile} {
            height: 48px;
        }
    }

    :global(.custom-select__multi-value) {
        background-color: $c-background-accent-component;
        border-radius: var(--r-md);
        flex-shrink: 0;
        margin: 0;
        padding: 0 8px;

        @media (pointer: coarse) {
            scroll-snap-align: center;
        }
    }

    :global(.custom-select__multi-value__label) {
        align-items: center;
        background-color: transparent;
        color: $c-default-gray-100 !important;
        display: flex;
        margin-right: 4px;
        padding: 0;

        @include f_p-small;
    }

    :global(.custom-select__multi-value__remove) {
        background-color: transparent;
        padding: 0;

        &:hover {
            background-color: transparent;
        }
    }

    :global(.custom-select__control) {
        background-color: $c-default-white-100;
        border: 1px solid $c-default-gray-30;
        border-radius: var(--r-xl);
        box-shadow: none;
        cursor: pointer;
        height: 100%;
        transition: all $animation;

        &:hover {
            border: 1px solid $c-secondary-100;
            box-shadow: none;
        }
    }

    :global(.custom-select__single-value) {
        color: $c-default-gray-100;
        margin: 0 0 0 4px;

        @include f_p;
    }

    :global(.custom-select__single-value--is-disabled) {
        background-color: $c-additional-gray-blue-20;
        color: $c-default-gray-50;
    }

    :global(.custom-select__input-container) {
        margin: 0;
        padding: 0;

        @include f_p;

        &::after {
            @include f_p;
        }
    }

    :global(.custom-select__value-container) {
        padding: 0 0 0 var(--p-sm);
        z-index: 1;
    }

    :global(.custom-select__value-container--is-multi) {
        column-gap: 4px;
        display: flex;
        flex-wrap: nowrap;
        margin-top: 20px;
        overflow-x: auto;
        padding: 0 0 0 14px;

        @media (pointer: coarse) {
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    :global(.custom-select__placeholder) {
        color: $c-default-gray-60;
        margin: 0;

        @include f_p;
        @include ellipsis(1);
    }

    :global(.custom-select__input) {
        margin-left: -2px;
    }

    :global(.custom-select__indicator-separator) {
        display: none;
    }

    :global(.custom-select__indicator) {
        color: unset;
        padding: 0 var(--p-xs) 0 0;
    }

    :global(.custom-select__menu) {
        background-color: $c-default-white-100;
        border-radius: var(--r-sm);
        box-shadow: 0 2px 14px 0 $c-shadow-large-tooltip;
        margin: 0;
        padding: 0;
        top: 68px;
        z-index: 5;
    }

    :global(.custom-select__menu-notice--no-options) {
        @include f_p-medium;
    }

    :global(.custom-select__menu-list) {
        box-sizing: border-box;
        margin: 0;
        max-height: 248px;
        padding: 0;

        @include scrollbar;
    }

    :global(.custom-select__option--is-selected) {
        background: transparent;
        color: $c-default-gray-100;

        svg {
            color: $c-primary-100;
        }
    }

    :global(.custom-select__option--is-focused) {
        background: transparent;
    }

    :global(.custom-select__option) {
        align-items: center;
        color: $c-default-gray-100;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 10px 8px;
        transition: background-color $animation;

        @include f_p-medium;

        svg {
            flex: 0 0 auto;
            height: 16px;
            width: 16px;
        }

        &:hover,
        &:focus-visible {
            background-color: $c-additional-gray-blue-20;
        }
    }

    :global(.custom-select__option--is-disabled) {
        color: $c-default-gray-50;
    }
}

.inputSelectValueSelected {
    &::after {
        top: 6px;

        @include f_p-medium;
    }

    :global(.custom-select__control) {
        border: 1px solid $c-primary-30;
    }
}

.inputSelectError {
    :global(.custom-select__control) {
        border: 1px solid $c-status-error-50;
    }
}

.inputSelectDisabled {
    pointer-events: none;

    :global(.custom-select__control) {
        background-color: $c-default-gray-10;
        border: none;
    }

    &::after {
        color: $c-default-gray-40;
    }
}

.inputFieldContainer {
    position: relative;
}

.inputSelectLabel {
    position: absolute;

    @include f_p;
}

.inputSelectFocused {
    :global(.custom-select__placeholder) {
        display: block !important;
    }

    :global(.custom-select__control) {
        border: 1px solid $c-secondary-100;
    }

    &::after {
        color: $c-default-gray-100;
    }
}

.inputSelectHasLabel {
    :global(.custom-select__placeholder) {
        margin-top: 18px;
    }

    :global(.custom-select__single-value) {
        margin-top: 18px;
    }

    :global(.custom-select__input-container) {
        margin-top: 18px;
    }
}

.inputSelectDropDown {
    align-items: center;
    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;

    &:disabled {
        color: $c-default-gray-40;
    }
}

.inputSelectDropDownIcon {
    color: $c-default-gray-60;
    display: flex;
    height: 100%;
    transition: all $animation;
    width: 100%;

    .commonIcon {
        @include fluid-prop-square-sizes-sm(20px, 20px);
    }
}

.inputSelectDropDownRotate {
    transform: rotate(180deg);
}

.inputSelectFilterCustom {
    :global(.custom-select) {
        height: auto;
    }

    :global(.custom-select__control) {
        background-color: $c-primary-10;
        border: none;
        color: $c-default-gray-100;
        outline: 1px solid transparent !important;

        @media #{$screen-mobile} {
            min-height: 40px;
        }

        &:hover {
            background-color: $c-primary-5;
            border: none;
            outline: 1px solid $c-additional-gray-blue-50 !important;
        }

        &:focus {
            border: none;
        }
    }

    :global(.custom-select__control--is-disabled) {
        background-color: $c-additional-gray-blue-20;
    }

    :global(.custom-select__option) {
        &:hover,
        &:focus-visible {
            background-color: $c-primary-10;
        }

        svg {
            color: $c-default-gray-100;
            height: 16px;
            width: 16px;
        }
    }

    :global(.custom-select__option--is-selected) {
        color: $c-default-gray-100;
    }

    :global(.custom-select__value-container) {
        color: $c-default-gray-100;
        padding: 14px 0 14px 24px;

        @include f_p-medium;

        @media #{$screen-mobile} {
            padding: 0 0 0 19px;
        }
    }

    :global(.custom-select__single-value) {
        color: $c-default-gray-80;
        margin: 0 0 0 -2px;

        @include f_p-medium;
    }

    :global(.custom-select__input-container) {
        margin: 0;
        padding: 0;

        @include f_p('xs');

        &::after {
            @include f_p('xs');
        }
    }

    :global(.custom-select__menu) {
        box-shadow: 0 2px 19px 0 $c-shadow-small-over;
        right: 0;
        top: calc(100% + 8px);
        width: 214px;
    }

    :global(.custom-select__indicator) {
        padding: 0 16px 0 0;

        @media #{$screen-mobile} {
            padding: 0 10px 0 0;
        }
    }
}

.multiValueRemove {
    color: $c-default-gray-60;
    height: 16px;
    width: 16px;

    svg {
        display: block;
        height: 16px;
        width: 16px;
    }
}
