@import 'src/styles/mixins';

.root {
    background-color: $c-background-accent-component;
    border-radius: var(--r-md);
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-16);
    margin-bottom: var(--p-xs);
    padding: var(--p-sm-24) var(--p-sm);
}

.inner {
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-12-8);
}

.contentBlock {
    align-items: center;
    display: flex;
    gap: var(--p-xxs);
}

.icon {
    height: 32px;
    object-fit: contain;
    object-position: center;
    width: 32px;

    img {
        height: 100%;
        width: 100%;
    }
}

.description {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
