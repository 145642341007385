@import 'src/styles/mixins';

.image {
    img {
        object-fit: cover;
    }
}

.leftSideContent {
    bottom: 24px;
    left: 24px;
    position: absolute;

    @media #{$screen-mobile} {
        bottom: 16px;
        left: 16px;
    }
}

.link {
    color: $c-default-gray-100;

    &.light {
        color: $c-default-white-100;
    }
}
