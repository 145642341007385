@import 'src/styles/mixins';

.tabsGroup {
    align-items: center;
    display: flex;
    gap: var(--p-sm);
    overflow-y: auto;

    @media #{$screen-mobile} {
        gap: 8px;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}
