@import 'src/styles/mixins';

.root {
    @include fluid-prop-padding-left-right-sm(12px, 24px);
    @include fluid-prop-padding-top-bottom-sm(12px, 16px);

    display: block;
}

.imageBlock {
    @include fluid-prop-sm(margin-bottom, 8px, 10px);

    height: 160px;

    @media #{$screen-mobile} {
        aspect-ratio: 1.5;
        height: auto;
    }
}

.image {
    height: 100%;
    object-fit: contain;
    width: 100%;
}

.title {
    color: $c-default-gray-80;
}
