@import 'src/styles/mixins';

.image {
    border-radius: 4px;
    flex-shrink: 0;
    height: 100px;
    overflow: hidden;
    position: relative;
    width: 100px;

    @include fluid-prop-sm(width, 68px, 100px);
    @include fluid-prop-sm(height, 68px, 100px);

    &.isInPopup {
        border-radius: var(--r-sm);
        height: 64px;
        overflow: hidden;
        width: 64px;
    }

    &.noAvailability::before {
        background-color: $c-default-white-a50;
        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}
