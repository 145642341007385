@import 'src/styles/mixins';

.root {
    max-width: 996px;
    overflow-y: auto;
    padding: 0;

    @media #{$screen-mobile} {
        max-width: 100%;
        overflow-y: hidden;
    }

    :global(.regular-modal-close) {
        @media #{$screen-mobile} {
            background-color: $c-default-gray-10;
            border-radius: 50%;
            height: 40px;
            width: 40px;
        }
    }
}

.title {
    @media #{$screen-mobile} {
        display: none;
    }
}

.content {
    padding-bottom: 32px;
    padding-top: 0;
}
