@import 'src/styles/mixins';

.root {
    background-color: $c-default-gray-10;
    border-radius: var(--r-lg);
    display: flex;
    flex-direction: column;
    padding: var(--p-sm);

    @include fluid-prop-sm(gap, 24px, 16px);
}
