@import 'src/styles/mixins';
@import 'src/styles/font';

.productCardActions {
    column-gap: var(--p-xs);
    display: grid;
    grid-template-columns: 1fr auto;
    row-gap: var(--p-xs);

    &.preview {
        column-gap: 0;
    }

    &.searchPanel,
    &.auto {
        grid-template-columns: 1fr;
    }

    &.auto {
        &.productInCart {
            grid-template-columns: 1fr;
        }
    }

    &.main {
        .actionsSubBlock {
            display: grid;
            grid-column: 1 / 3;
        }

        .compare,
        .availableFavourite,
        .favourite {
            display: flex;
        }

        &.productInCart {
            grid-template-columns: auto auto min-content;

            .actionsSubBlock {
                grid-column: 1 / 4;
            }
        }
    }

    &.productPageHeader {
        .favourite,
        .availableFavourite {
            display: flex;
        }
    }

    &.mainBottom {
        @include show-mobile-hide-desktop(grid);
        @include container;

        background-color: $c-default-white-100;
        gap: 12px;
        grid-template-columns: auto min-content min-content;
        padding: 16px 0 20px;

        .availableCompare,
        .availableFavourite,
        .compare,
        .favourite {
            display: flex;
        }

        .subscription {
            grid-column: 1 / span3;
        }

        &.productInCart {
            .availableCompare {
                display: none;
            }
        }
    }
}

.subscription {
    display: flex;
    gap: 12px;
    justify-content: stretch;
    width: 100%;
}

.actionsSubBlock,
.compare,
.favourite,
.availableFavourite,
.availableCompare {
    display: none;
}
