@import 'src/styles/mixins';

.checkbox {
    display: flex;
    position: relative;

    &:has(.input:disabled) {
        pointer-events: none;
    }
}

.label {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    width: 100%;
}

.input {
    display: none;
}

.checkMarkContainer {
    align-items: center;
    border: 1px solid $c-secondary-70;
    border-radius: var(--r-xxs);
    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    position: relative;
    transition: all $animation;
    width: 24px;

    &.mixed::before {
        background-color: $c-default-gray-100;
        border-radius: 2px;
        content: '';
        display: block;
        height: 2px;
        width: 12px;
    }

    .checkbox:hover & {
        border: 2px solid $c-secondary-70;
    }

    input:disabled + .label & {
        background-color: $c-default-gray-10;
        border-color: $c-default-gray-10;
        color: $c-default-gray-50;
        cursor: auto;

        &.mixed::before {
            background-color: $c-default-gray-50;
        }
    }

    .checkbox:hover input:disabled:checked + .label &:not(.mixed) {
        background-color: $c-primary-30;
        border: 1px solid $c-default-gray-10;
    }
}

.checkMarkIcon {
    color: $c-default-gray-100;
    display: none;
    height: 16px;
    width: 16px;

    input:checked + .label & {
        display: block;
    }
}

.text {
    align-self: center;
    color: $c-default-gray-100;
    margin-left: 8px;
    transition: all $animation;

    input:disabled + .label & {
        color: $c-default-gray-50;
        cursor: auto;
    }
}
