@import 'styles/mixins';

.root {
    height: max-content;
    max-width: 180px;
    text-align: center;
}

.head {
    @include fluid-prop-sm('padding',  12px, 20px);

    color: $c-default-gray-80;
    z-index: 1;
}

.imgWrap {
    aspect-ratio: 1;
    border: 1px solid $c-secondary-15;
    border-radius: var(--r-md);
    margin-bottom: 16px;
    overflow: hidden;
    width: 180px;

    @media #{$screen-mobile} {
        margin: auto;
        margin-bottom: 8px;
        width: 142px;
    }

    img {
        height: inherit;
        object-fit: cover;
        width: inherit;
    }
}

.text {
    -webkit-box-orient: vertical;
    display: box;
    hyphens: auto;
    -webkit-line-clamp: 2;
    max-width: 180px;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;

    @include fluid-prop-sm(font-size, 14px, 16px);

    @media #{$screen-mobile} {
        -webkit-line-clamp: 3;
        max-width: 142px;
    }
}
