@import 'src/styles/mixins';

.goTopHidden {
    display: none;
}

.goTopContainer {
    bottom: 34px;
    position: fixed;
    right: 34px;
    z-index: 10;

    @media #{$screen-mobile} {
        display: none;
    }
}

.gotTopButton {
    background-color: $c-default-white-100;
    border: 1px solid $c-additional-gray-blue-50;
    color: $c-additional-gray-blue-50;

    svg {
        transform: rotate(90deg);
    }

    &:hover {
        background-color: $c-primary-10;
        border-color: $c-primary-20;
        color: $c-primary-60;
    }
}
