@import 'src/styles/mixins';

.productListLayout {
    @include container;

    margin-top: 48px;

    @media #{$screen-mobile} {
        margin-top: 0;
    }
}

.productListContainer {
    @include container;
}

.productListLayoutNoFilter {
    transition: all $animation;

    & .productListLayoutBestSalesContainer {
        left: 0;
        width: 100%;
    }

    & .productListLayoutListingContainer {
        position: static;
    }
}

.productListLayoutSorting {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media #{$screen-mobile} {
        margin-top: 24px;
    }
}

.filtersTagList {
    @include fluid-prop-sm(margin-top, 16px, 24px);
}

.productListLayoutListingContainer {
    grid-template-rows: min-content;
    position: relative;
    width: 100%;

    @media #{$screen-mobile} {
        position: static;
    }
}

.productListLayoutListing {
    width: 100%;
}

.productListLayoutPaginationContainer {
    width: 100%;

    @include fluid-prop-sm(margin-top, 32px, 48px);
}

.productListSearchCount {
    margin-bottom: 16px;
}

.productListPageLayoutLookedList {
    @include fluid-prop-sm(margin-top, 32px, 80px);
}

.listing {
    @include fluid-prop-sm(margin-top, 24px, 40px);
}
