@import 'adaptive';

@mixin hide-on($maxWidth: $size-sm-max) {
    @media screen and (max-width: $maxWidth) {
        display: none;
    }
}

@mixin show-on($maxWidth: $size-lg-min, $type: block) {
    display: none;

    @media screen and (max-width: $maxWidth) {
        display: $type;
    }
}

@mixin hide-mobile-show-desktop($type: block) {
    display: $type;

    @media #{$screen-mobile} {
        display: none;
    }
}

@mixin show-mobile-hide-desktop($type: block) {
    display: none;

    @media #{$screen-mobile} {
        display: $type;
    }
}
