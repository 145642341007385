@import 'src/styles/mixins';

.root {
    display: flex;
    flex-direction: column;

    @include fluid-prop-sm(gap, 12px, 16px);
}

.row {
    align-items: center;
    display: flex;
    gap: 12px;
}

.left {
    width: 100%;
}
