@import 'src/styles/mixins';

.root {
    @include container;

    row-gap: 16px;
}

.logoWrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: stretch;

    @media #{$screen-mobile} {
        aspect-ratio: 1.35;
        height: auto;
    }
}

.logo {
    height: 100%;
    object-fit: contain;

    @media #{$screen-mobile} {
        aspect-ratio: 1.35;
        max-width: none;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;

    @media #{$screen-mobile} {
        gap: 16px;
        padding: 0;
    }
}
