@import 'styles/mixins';

.root {
    @include container;
    @include fluid-prop-sm(margin-top, 32px, 48px);

    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--p-sm);
    justify-content: center;
    width: 100%;
}
