@import 'src/styles/mixins';

.productCardProperties {
    display: flex;
    flex-direction: column;
    position: relative;
}

.productCardPropertiesStatic {
    display: flex;
    flex-direction: column;
    margin-top: var(--p-xs-16);
}

.productCardPropertiesAnimated {
    width: 100%;
}

.button {
    margin-top: var(--p-xs-16);

    svg {
        transform: rotate(180deg);
    }

    &.propertiesHidden {
        svg {
            transform: rotate(0);
        }
    }
}

.productCardProperty {
    align-items: center;
    display: flex;
    grid-column-gap: var(--p-xxs);
    min-height: 56px;
}

.value {
    text-align: right;
}

.divider {
    border-bottom: 1px dashed $c-additional-graphics-elements-divider;
    flex-grow: 1;
    height: 24px;
}

.title {
    background-color: $c-default-white-100;
    color: $c-default-gray-100;
    position: relative;
    z-index: 1;
}
