@import 'src/styles/mixins';

.link {
    border-color: $c-default-gray-100;
    color: $c-default-gray-100;
    column-gap: 4px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: color $animation;

    &.onlyIcon {
        background-color: $c-primary-100;
        border-radius: 50%;
        color: $c-default-white-100;
        height: 24px;
        padding: 4px;
        width: 24px;

        &::before {
            content: none;
        }
    }

    svg {
        display: inline-block;
        height: 16px;
        width: 16px;
    }

    &.light {
        border-color: $c-default-white-100;
        color: $c-default-white-100;
    }

    &.withBorder::before {
        border: 1px solid transparent;
        border-radius: 2px;
        bottom: -1px;
        content: '';
        left: -1px;
        position: absolute;
        right: -1px;
        top: 0;
    }

    &:hover {
        border-color: $c-default-gray-70;
        color: $c-default-gray-70;

        &.light {
            border-color: $c-default-white-a70;
            color: $c-default-white-a70;
        }
    }

    &:focus {
        border-color: $c-default-gray-100;
        color: $c-default-gray-100;

        &.light {
            border-color: $c-default-white-100;
            color: $c-default-white-100;
        }

        &::before {
            border-color: $c-secondary-100;
        }

        &.light::before {
            border-color: $c-default-white-100;
        }
    }

    &:active {
        border-color: $c-default-gray-70;
        color: $c-default-gray-70;

        &.light {
            border-color: $c-default-white-a50;
            color: $c-default-white-a50;
        }

        &::before,
        &.light::before {
            border-color: transparent;
        }
    }

    &:visited {
        border-color: $c-secondary-100;
        color: $c-secondary-100;

        &.light {
            border-color: $c-default-white-a70;
            color: $c-default-white-a70;
        }
    }

    &.disabled {
        border-color: $c-default-gray-40;
        color: $c-default-gray-40;
        pointer-events: none;

        &.light {
            border-color: $c-default-white-a50;
            color: $c-default-white-a50;
        }
    }

    .iconLeft {
        transform: rotate(180deg);
    }
}

.inline {
    border-bottom: 1px solid $c-default-gray-100;

    svg {
        margin-top: -4px;
    }
}

.standalone {
    align-items: center;
    border-bottom: none;
    display: flex;
}

.isHideTextAdaptive {
    @media #{$screen-mobile} {
        display: none;
    }
}
