@import 'src/styles/mixins';
@import 'src/styles/font';

$c-menu-item-active: #1b2127;

.grid {
    grid-template-rows: 100%;
    height: 100%;
}

.menuColumn {
    @include container;

    background-color: $c-default-white-100;
    border-right: 1px solid $c-additional-custom-hovers-on-component;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    @media #{$screen-mobile} {
        border-right: none;
        display: flex;
        flex-direction: column;
        height: calc(100% - var(--bottom-bar-height) - var(--header-search-mobile-height));
        left: 0;
        overflow-y: auto;
        padding-top: 16px;
        position: fixed;
        width: 100%;

        & > * {
            flex-shrink: 0;
        }
    }
}

.contentColumn {
    overflow-y: auto;

    @include scrollbar;
}

.catalogMenuSection {
    @include margin-negative-container-mobile;
    @include scrollbar;

    flex-shrink: 0;
    list-style: none;
    margin: 0;
    margin-bottom: 24px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;

    @media #{$screen-mobile} {
        @include hide-scrollbars;

        margin-bottom: 0;
        margin-top: 24px;
    }
}

.catalogMenuSectionItems {
    align-items: center;
    border-radius: var(--r-xl) 0 0 var(--r-xl);
    color: $c-default-gray-80;
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: var(--p-xs) var(--p-sm);
    position: relative;
    transition: color $animation, background-color $animation;

    &:hover {
        transition: background-color $animation;
    }

    @media #{$screen-mobile} {
        border-bottom: 1px solid $c-primary-10;
        border-radius: 0;
        padding: var(--p-sm) var(--p-sm) 15px;
    }
}

.catalogMenuSectionItemsBadge {
    flex-shrink: 0;
}

.catalogMenuSectionItemsBadgeIcon {
    display: flex;
    flex-shrink: 0;
    height: 24px;
    object-fit: cover;
    width: 24px;
}

.hideDesktopIcon {
    @include show-mobile-hide-desktop(inline-block);
}

.catalogMenuSectionItemsTile {
    @media #{$screen-mobile} {
        color: $c-primary-80;
        display: flex;
        padding: 16px;
        width: 100%;

        .catalogMenuSectionItemsName {
            align-items: flex-end;
            display: flex;
            width: 100%;

            @include ellipsis(2);
        }
    }

    .catalogMenuCategoryText {
        @media #{$screen-mobile} {
            align-items: center;
            color: $c-primary-80;
            column-gap: 8px;
            display: flex;
        }
    }
}

.catalogLinkDisabledMobile {
    @media #{$screen-mobile} {
        pointer-events: none;
    }
}

.catalogMenuSectionItemsName {
    align-items: center;
    color: inherit;
    column-gap: 16px;
    display: flex;
    width: 100%;

    @media #{$screen-mobile} {
        column-gap: 12px;
        width: 100%;
    }
}

.catalogMenuSectionItemsNameContainer {
    align-items: center;
    display: flex;
    gap: var(--p-xxs);
}

.catalogMenuCategoryText {
    align-items: center;
    color: $c-default-gray-100;
    column-gap: 12px;
    display: flex;
    width: 100%;

    @media #{$screen-mobile} {
        @include f_p;

        align-items: center;
        column-gap: 8px;
        display: flex;
        width: 100%;
    }
}

.catalogMenuSectionItemsActive {
    background-color: $c-additional-custom-hovers-on-component;
    box-shadow: 1px 0 2px 0 $c-additional-gray-blue-30;
    color: $c-primary-100;

    @media #{$screen-mobile} {
        background-color: $c-default-white-100;
    }
}

.catalogMenuHeader {
    row-gap: 16px;

    @media #{$screen-desktop} {
        display: none;
    }
}

.catalogMenuHeaderBack a {
    @include f_p-medium('lg');

    color: $c-default-gray-50;
}

.catalogMenuSectionItemsText {
    @include f_h2_bold;

    color: $c-default-gray-100;
    width: 100%;
}

.categoryBanners {
    @media #{$screen-mobile} {
        margin: 24px 0;
    }
}

.hidden {
    display: none;
}

.desktop {
    @include hide-mobile-show-desktop;

    margin-bottom: 64px;
}

.mobile {
    @include show-mobile-hide-desktop;
}

.categoryBannersMobile {
    @include show-mobile-hide-desktop;
}

.categoryBannersDesktop {
    @include hide-mobile-show-desktop;
}
