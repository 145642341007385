@import 'src/styles/mixins';

.root {
    @include fluid-props-sm-p-sm(padding);

    border: 1px solid $c-default-gray-30;
    border-radius: var(--r-md);
    row-gap: var(--p-sm-24);
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--p-xxs);
}

.footer {
    align-items: center;
    display: flex;
    gap: var(--p-sm);
    justify-content: center;
    width: 100%;

    @media #{$screen-mobile} {
        flex-direction: column;
    }
}
