@import 'src/styles/mixins';

.onlineServicesList {
    border-radius: 4px;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
}

.onlineServicesItem {
    background-color: $c-additional-gray-blue-20;
    width: 100%;

    @include fluid-prop-padding-top-bottom-sm(8px, 12px);
    @include fluid-prop-padding-left-right-sm(16px, 12px);
}

.link {
    align-items: center;
    background-color: $c-additional-gray-blue-20;
    border-radius: 4px;
    display: flex;
    padding-left: 12px;
    transition: $animation-duration;
    width: 100%;

    @include fluid-prop-padding-top-bottom-sm(4px, 8px);

    svg {
        color: $c-primary-30;
        height: 24px;
        margin-left: auto;
        margin-right: 12px;
        opacity: 0;
        transition: opacity $animation-duration;
        width: 24px;
    }

    &:hover,
    &:focus {
        background-color: $c-primary-10;

        @media #{$screen-mobile} {
            background-color: $c-additional-gray-blue-20;
        }

        svg {
            opacity: 1;

            @media #{$screen-mobile} {
                opacity: 0;
            }
        }

        .text {
            color: $c-primary-50;
        }
    }
}

.image {
    height: 48px;
    width: 48px;

    @media #{$screen-mobile} {
        height: 32px;
        width: 32px;
    }
}

.description {
    display: inline-grid;
    margin-left: 8px;
}

.text {
    color: $c-primary-60;

    @media #{$screen-mobile} {
        color: $c-primary-60;
    }
}

.additional {
    align-items: center;
    display: flex;
    margin-top: 4px;
}

.additionalText {
    color: $c-default-gray-70;
    margin-left: 12px;
}
