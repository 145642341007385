@import 'src/styles/mixins';

.cardShieldList {
    display: grid;
    grid-row-gap: var(--p-xxxs);
    grid-template-columns: 1fr;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;

    &.list {
        display: grid;
        grid-column-gap: 8px;
        grid-template-columns: repeat(auto-fit, minmax(28px, max-content));
        justify-items: start;
        position: static;
    }
}

.cardShieldItemShield {
    max-width: 100%;
    width: max-content;
}

.cardShieldItem,
.cardShieldItemShield {
    @include ellipsis(1);

    &.list {
        // TODO: оно здесь точно нужно? max-width: 228px
        max-width: 228px;
        min-width: 28px;
        position: relative;
        width: 100%;
    }
}
