@import 'src/styles/mixins';

.galleryButtonSlide {
    border: 1px solid $c-default-gray-20;
    border-radius: var(--r-sm);
    cursor: pointer;
    overflow: hidden;
    padding: var(--p-xs-12-8);
    position: relative;
    transition: border-color $animation;

    &.active,
    &:hover,
    &:focus {
        border-color: $c-secondary-100;
    }

    img {
        aspect-ratio: 1 / 1;
        object-fit: cover;
        object-position: center;
    }

    &.basic,
    &.fullscreen {
        height: 78px;
        width: 78px;
    }

    &.technical {
        height: 64px;
        width: 64px;
    }

    &.fullscreen {
        &.video {
            @media #{$screen-mobile} {
                padding: 0;

                .galleryButtonSlideText {
                    display: none;
                }
            }
        }
    }

    &.fullWidthThumb {
        aspect-ratio: 1;
        height: auto;
        width: 100%;

        img {
            cursor: pointer;
        }
    }
}

.videoButtonOverlay {
    align-items: center;
    background-color: $c-default-gray-20;
    color: $c-default-white-100;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color $animation;
    width: 100%;

    .galleryButtonSlide.withPreview & {
        background-color: $c-default-gray-a50;
    }

    .galleryButtonSlide.withPreview.active & {
        background: none;
    }

    svg {
        height: 24px;
        width: 24px;
    }
}
