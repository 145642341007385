@import 'src/styles/mixins';

.container {
    @include hide-mobile-show-desktop;

    border-radius: var(--r-md);
    box-shadow: 0 2px 25px 0 $c-shadow-large-tooltip;
    overflow: hidden;
    position: absolute;
    top: 54px;
    width: 100%;
    z-index: 3;

    @media #{$screen-mobile} {
        box-shadow: none;
    }
}

.panel {
    @include scrollbar;

    background-color: $c-default-white-100;
    border-radius: var(--r-md);
    display: flex;
    flex-direction: column;
    gap: var(--p-md);
    max-height: calc(100vh - 136px);
    overflow: hidden;
    padding: var(--p-sm);
    transition: all $animation;
    width: 100%;
}

.content {
    @include scrollbar;

    display: flex;
    gap: var(--p-lg);
    margin-right: -10px;
    overflow: auto;
    padding-right: 10px;
}

.firstBlock {
    flex-shrink: 0;
    gap: var(--p-lg);
    max-width: 320px;
    width: 100%;
}

.button {
    flex-shrink: 0;
}

.notFoundContent {
    @include scrollbar;

    display: flex;
    flex-direction: column;
    gap: var(--p-lg);
    margin-right: -10px;
    overflow: auto;
    padding-right: 10px;
}
