@import 'src/styles/mixins';

.button {
    height: 44px;
    max-width: 248px;

    @media #{$screen-mobile} {
        max-width: 100%;
    }
}
