@import 'src/styles/mixins';

.link {
    align-items: center;
    color: inherit;
    column-gap: 16px;
    display: flex;
    width: 100%;

    @media #{$screen-mobile} {
        column-gap: 12px;
        width: 100%;
    }
}
