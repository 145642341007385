@import 'src/styles/mixins';

.toast {
    align-items: flex-start;
    color: $c-default-gray-100;
    display: flex;
    flex-direction: column;
    gap: var(--p-xs-16);

    @include fluid-props-sm-p-sm(padding);

    &Content {
        align-items: flex-start;
        align-self: stretch;
        display: flex;

        svg {
            flex-shrink: 0;
            height: 24px;
            width: 24px;
        }

        .icon {
            @include fluid-prop-sm(margin-right, 8px, 12px);
        }

        .closeButton {
            cursor: pointer;
            margin-left: auto;
        }
    }

    &.info {
        background-color: $c-status-info-10;
    }

    &.error {
        background-color: $c-status-error-10;
    }

    &.warning {
        background-color: $c-status-warning-10;
    }

    @media #{$screen-mobile} {
        gap: var(--p-sm);
    }
}
