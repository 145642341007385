@import 'src/styles/mixins';

.flyFilterButtonWrapper {
    position: absolute;
    right: -205px;
    top: 4px;
}

.flyFilterButton {
    align-items: center;

    // transition: all @animation; // TODO: подумать как сделать плавный переход для стрелки
    background-color: $c-primary-80;
    border-radius: 4px;
    box-shadow: 0 2px 19px 0 rgb(83 113 149 / 20%);
    color: $c-default-white-100;
    column-gap: 8px;
    cursor: pointer;
    display: flex;
    height: 54px;
    justify-content: center;
    position: relative;
    width: 176px;

    &.loaded {
        &.buttonText {
            display: none;
        }
    }

    &:hover {
        background-color: $c-primary-100;

        &::before {
            border-color: transparent $c-primary-100 transparent transparent;
        }
    }

    &:disabled,
    &.loaded {
        background-color: $c-primary-70;
    }

    &::before {
        border-color: transparent $c-primary-80 transparent transparent;
        border-style: solid;
        border-width: 13px 15px 13px 0;
        content: '';
        left: -15px;
        position: absolute;
    }
}
