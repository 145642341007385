@import 'src/styles/mixins';

.profileMenu {
    box-shadow: none;
    flex-shrink: 0;
    height: min-content;

    @media #{$screen-mobile} {
        display: none;
    }
}

.profileMenuItem {
    border-radius: 4px;

    svg {
        width: 24px;
    }
}

.item {
    align-items: center;
    border-radius: var(--r-xl) 0 0 var(--r-xl);
    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;
    gap: 8px;
    padding: var(--p-xs) var(--p-sm);
    transition: background-color $animation;

    svg {
        flex-shrink: 0;
        height: 24px;
        width: 24px;
    }

    &.currentActive {
        background-color: $c-secondary-15;
    }
}
