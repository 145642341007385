@import 'src/styles/mixins';
@import 'src/styles/font';

.root {
    @include fluid-prop-sm(row-gap, 8px, 32px);
    @include scrollbar;

    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.subcategoryList {
    column-count: 3;
    gap: var(--p-sm);
}

.subcategoryContainer {
    align-items: flex-start;
    break-inside: avoid;
    column-gap: 12px;
    display: flex;
}

.subcategorySection {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
}

.subChildren {
    display: flex;
    flex-direction: column;
}

.subChildrenLink {
    color: $c-default-gray-100;
    padding: var(--p-xxs);
    transition: all $animation;

    &:hover {
        color: $c-secondary-100;
    }
}

.subcategoryLink {
    @include f_h5_bold;
    @include fluid-prop-sm(font-size,  16px, 16px);

    align-items: flex-start;
    color: $c-default-gray-100;
    column-gap: 12px;
    display: flex;
    padding: var(--p-xs) var(--p-xxs);
    transition: all $animation;

    &:hover {
        color: $c-secondary-100;
    }
}

.parentHasIcon {
    margin-left: 36px;
}

.icon {
    flex-shrink: 0;
    height: 24px;
    object-fit: cover;
    width: 24px;
}
