@import 'src/styles/mixins';

.nextButton {
    svg {
        transform: rotate(180deg);
    }
}

.pageButton {
    align-items: center;
    background-color: $c-default-white-100;
    border-radius: 50%;
    color: $c-default-gray-100;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    outline: 2px solid transparent;
    outline-offset: -1px;
    padding: 0;
    transition: background-color $animation, outline-color $animation;
    width: 32px;

    & + & {
        margin-left: 8px;
    }

    &:hover {
        color: $c-default-gray-80;
    }

    &:focus-visible {
        color: $c-primary-80;
        outline-color: $c-default-gray-100;
    }

    &:disabled {
        color: $c-default-gray-30;
        cursor: default;
        pointer-events: none;
    }
}

.pageButtonActive {
    background-color: $c-secondary-100;
    color: $c-default-white-100;
    cursor: default;

    &:hover {
        background-color: $c-secondary-80;
        color: $c-default-white-100;
    }

    &:focus-visible {
        color: $c-default-white-100;
        outline-color: $c-default-gray-70;
    }
}

.pageButtonArrow {
    color: $c-default-gray-80;

    svg {
        width: 24px;
    }

    &:hover {
        color: $c-default-gray-70;
    }

    &:focus-visible {
        color: $c-default-gray-80;
    }

    &:disabled {
        color: $c-default-gray-50;
    }
}
