@import 'src/styles/mixins';

.nav {
    align-items: center;
    display: flex;

    &.light {
        --slider-navigation-color: #fff;
        --slider-active-color: #0b1742;
    }

    & :global(.button-component) {
        color: var(--slider-navigation-color);
    }
}

.pagination {
    display: flex;
    gap: 8px;
    margin: 0;
    padding: 0 24px;
}

.bullet {
    background-color: $c-default-white-a70;
    border-radius: 1px;
    height: 2px;
    width: 32px;

    &.active {
        background-color: $c-secondary-100;
    }

    &.light.active {
        background-color: $c-primary-100;
    }

    &.light {
        background-color: $c-default-gray-a50;
    }
}

.prev svg {
    transform: rotate(180deg);
}
