@import 'src/styles/mixins';

.root {
    border-radius: var(--r-md) 0 0 var(--r-md);
    grid-column: 1 / 4;
    padding: var(--p-xs) var(--p-sm);
    transition: background-color $animation;

    &:hover {
        background-color: $c-additional-custom-hovers-on-background;
        transition: background-color $animation;
    }

    @media #{$screen-mobile} {
        padding: 32px 0;
    }
}

.inner {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 235px;

    @media #{$screen-mobile} {
        gap: var(--p-xxs);
    }
}
