// CARACHA
// Priority Colors
$c-primary-100: #221f1f;
$c-primary-80: rgb($c-primary-100, 80%);
$c-primary-70: rgb($c-primary-100, 70%);
$c-primary-60: rgb($c-primary-100, 60%);
$c-primary-50: rgb($c-primary-100, 50%);
$c-primary-30: rgb($c-primary-100, 30%);
$c-primary-20: rgb($c-primary-100, 20%);
$c-primary-10: rgb($c-primary-100, 10%);
$c-primary-5: rgb($c-primary-100, 5%);

// DEFAULT
// Priority Colors
$c-primary-30-a20: rgba($c-primary-30, 20% / 100%);

// CARACHA
// Secondary Colors
$c-secondary-100: #a58e7d;
$c-secondary-80: rgb($c-secondary-100, 80%);
$c-secondary-70: rgb($c-secondary-100, 70%);
$c-secondary-50: rgb($c-secondary-100, 50%);
$c-secondary-30: rgb($c-secondary-100, 30%);
$c-secondary-15: rgb($c-secondary-100, 15%);

// DEFAULT
// Secondary Colors
$c-secondary-10: #fffdf9;

// CARACHA
// Default Colors
// Gray
$c-default-gray-100: #1c1c1c;
$c-default-gray-80: #404040;
$c-default-gray-70: #6f6f6f;
$c-default-gray-60: #8f8f8f;
$c-default-gray-a50: rgb(#0e121b, 50%);
$c-default-gray-50: #bbb;
$c-default-gray-40: #c8c8c8;
$c-default-gray-30: #e1e1e1;
$c-default-gray-20: #ebebeb;
$c-default-gray-a10: rgb($c-default-gray-100, 10%);
$c-default-gray-10: #f6f6f6;

// DEFAULT
// Default Colors
// Gray
$c-default-gray-90: rgb(14 18 27 / 50%);
$c-default-gray-a65: rgba($c-default-gray-100, 65% / 100%);
$c-default-gray-a30: rgb(104 104 104 / 30%);
$c-default-gray-34: rgb(178 199 224 / 34%);

// CARACHA
// Default Colors
// White
$c-default-white-100: #fff;
$c-default-white-a90: rgba($c-default-white-100, 90% / 100%);
$c-default-white-a70: rgba($c-default-white-100, 70% / 100%);
$c-default-white-a50: rgba($c-default-white-100, 50% / 100%);
$c-default-white-a10: rgba($c-default-white-100, 10% / 100%);

// DEFAULT
// Default Colors
// White
$c-default-white-a0: rgba($c-default-white-100, 0% / 100%);

// DEFAULT
// Default Colors
// Black
$c-default-black-100: #000;
$c-default-black-a50: rgba($c-default-black-100, 50% / 100%);
$c-default-black-a40: rgba($c-default-black-100, 40% / 100%);
$c-default-black-a25: rgba($c-default-black-100, 25% / 100%);
$c-default-black-a15: rgba($c-default-black-100, 15% / 100%);
$c-default-black-a10: rgba($c-default-black-100, 10% / 100%);

// DEFAULT
// Common Colors
// Black
$c-common-black: #141313;
$c-common-gray: #444;

// CARACHA
// Background Colors
$c-background-page: #fff;
$c-background-header: #e4ded8;
$c-background-footer: rgb(#a58e7d, 15%);
$c-background-elements-on-top: #fff;
$c-background-link-block: #dce0e5;
$c-background-accent-block: rgb(#a58e7d, 50%);
$c-background-accent-component: #f2eeec;

// CARACHA
// Additional Colors
// Shields
$c-additional-shields-info: #ffc72c;
$c-additional-shields-new: #b2d7f8;
$c-additional-shields-not-available: #cecece;
$c-additional-shields-sale: #e12626;

// DEFAULT
// Additional Colors
// Price
$c-additional-price-discount: #0b1742;

// CARACHA
// Additional Colors
// Price
$c-additional-price-basic: #221f1f;
$c-additional-price-old: #bfc3c7;

// CARACHA
// Additional Colors
// Custom hovers
$c-additional-custom-hovers-on-component: #e4ded8;
$c-additional-custom-hovers-on-background: #f0f3f5;

// CARACHA
// Additional Colors
// Graphic elements
$c-additional-graphics-elements-border: #d9d9d9;
$c-additional-graphics-elements-divider: #f0f3f5;
$c-additional-graphics-elements-stubs: #dee4eb;

// CARACHA
// Additional Colors
// Accent elements
$c-additional-accent-elements-rating: #ffc72c;
$c-additional-accent-elements-text: #3b7cc5;

// DEFAULT
// Status
$c-status-success-40: #72d072;

// CARACHA
// Status
$c-status-error-50: #e22222;
$c-status-error-20: #ffc0c0;
$c-status-error-10: #ffefef;

$c-status-warning-50: #ffc107;
$c-status-warning-20: #fff0c3;
$c-status-warning-10: #fff8e3;

$c-status-success-50: #3db064;
$c-status-success-20: #d3ecdc;
$c-status-success-10: #ecf8f0;

$c-status-info-50: #8fcaff;
$c-status-info-20: #e0eaf8;
$c-status-info-10: #f2f3f3;

// CARACHA
// Gradient
$c-gradient-gray-horizontal: linear-gradient(to right, #f5f5f5 0.05%, #dfdfdf 99.95%);
$c-gradient-gray-vertical: linear-gradient(to bottom, #382626 -2.41%, rgb(#382626, 0%) 98.7%);
$c-gradient-gray-vertical-top: linear-gradient(to top, #382626 -2.41%, rgb(#382626, 0%) 98.7%);
$c-gradient-white-horizontal: linear-gradient(to right, $c-default-white-a0 32%, $c-default-white-100 55.5%);

// CARACHA
// Shadow
$c-shadow-large-tooltip: rgb(#c1c7cf, 30%);
$c-shadow-tooltip: rgb(#c6cad7, 30%);
$c-shadow-tabbar: rgb(#cad0d7, 20%);

// DEFAULT
// Additional Colors
$c-additional-yellow-100: #ca9400;
$c-additional-yellow-80: #ffc72c;
$c-additional-yellow-30: #ffe59d;

$c-additional-yellow-green-80: #d0df00;

$c-additional-gray-blue-80: #667e99;
$c-additional-gray-blue-60: #8598ad;
$c-additional-gray-blue-50: #b3bfcc;
$c-additional-gray-blue-30: #e0e7eb;
$c-additional-gray-blue-20: #f0f3f5;

$c-additional-light-blue-90: #40bcfb;
$c-additional-light-blue-80: #71c5e8;

$c-additional-shadow-blue-a4: rgb(178 199 224 / 40%);
$c-additional-shadow-blue-a3: rgba(#537195, 30% / 100%);
$c-additional-shadow-blue-a2: rgb(83 113 149 / 20%);

$c-shadow-down: rgb(178 199 224 / 20%);
$c-shadow: rgb(38 47 56 / 20%);
$c-shadow-small-over: rgba(#537195, 20% / 100%);

// DEFAULT
// Banners Colors
$c-banners-sandy: #987216;
$c-banners-dark-gold: #ba900c;
$c-banners-ultramarine-blue: #23244e;
$c-banners-grey-blue: #324662;
$c-banners-papaya-escape: #fff3d2;
$c-banners-forest-wolf: #e3e3e2;
$c-banners-crimson: #d61039;
$c-banners-carrot: #ea5627;
$c-alice-blue-30: #e5f1ff;

// DEFAULT
// Shadow
$c-shadow-header: rgba(#b2c7e0, 80%);
$c-shadow-a4: rgba(#b2c7e0, 40%);
$c-shadow-a2: rgba(#b2c7e0, 20%);
$c-shadow-large-over: rgba(#537195, 30%);
$c-shadow-grey-blue-12: rgb(50 70 98 / 12%);
$c-shadow-slider-buttons: rgba(176 176 176 / 20%);
