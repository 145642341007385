@import 'src/styles/font';
@import 'src/styles/mixins';

.button {
    @include f_p-small;

    align-items: center;
    color: $c-default-gray-60;
    cursor: pointer;
    display: flex;
    margin-top: 16px;
    text-transform: uppercase;
    transition: color $animation;

    &:hover {
        color: $c-default-gray-80;
    }

    @media #{$screen-mobile} {
        align-items: flex-start;

        .root:not(.show) & {
            margin-top: 0;
        }
    }

    @media #{$screen-desktop} {
        .root:not(.showButtonDesktop) & {
            display: none;
        }
    }
}

.content {
    max-height: var(--max-height);
    overflow: hidden;
    position: relative;

    &::before {
        background: linear-gradient(to bottom, $c-default-white-a0 0%, $c-default-white-100 100%);
        bottom: 0;
        content: '';
        height: 48px;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;

        .root:not(.showButtonDesktop) & {
            display: none;
        }
    }

    .show & {
        max-height: none;
        overflow: visible;

        &::before {
            display: none;
        }
    }
}

.icon {
    height: 12px;
    margin: 0 0 0 4px;
    width: 12px;

    @media #{$screen-mobile} {
        height: 10px;
        width: 10px;
    }

    .show & {
        margin: 0 0 0 4px;
        order: 1;
        transform: rotate(180deg);
    }
}

.title {
    color: $c-default-gray-100;
}
