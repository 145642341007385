@import 'styles/mixins';

.advice {
    @include fluid-prop-sm(gap, 4px, 8px);
    @include fluid-prop-sm(margin-bottom, 20px, 24px);

    align-items: center;
    display: flex;
    justify-content: center;

    svg {
        @include fluid-prop-square-sizes-sm(20px, 32px);

        color: $c-secondary-100;
    }
}
