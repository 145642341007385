@import 'src/styles/mixins';
@import 'src/styles/font';

.simpleProduct {
    background-color: $c-default-white-100;
    border-radius: var(--r-md) var(--r-md) 0 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content;
    overflow: hidden;
    position: relative;
    transition: box-shadow $animation;
    width: 100%;
    z-index: 0;
}

.simpleProductFlexWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.imageContainer {
    aspect-ratio: 1 / 1;
    overflow: hidden;

    img {
        aspect-ratio: 1 / 1;
        overflow: hidden;
    }
}

.simpleProductImage {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    z-index: 0;

    img {
        display: block;
        height: 100%;
        object-fit: cover;
        position: relative;
        width: 100%;
        z-index: -1;
    }
}

.rightTopButtons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    opacity: 1;
    position: absolute;
    right: 8px;
    top: 8px;
    transition: all $animation;
    z-index: 5;

    &.preview {
        right: 8px;
        top: 8px;
    }

    @media #{$screen-mobile} {
        right: 8px;
        top: 8px;
    }

    &.hide {
        display: none;
    }
}

.shieldList {
    left: 8px;
    position: absolute;
    top: 8px;
}

.articleContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.simpleProductArticle {
    color: $c-default-gray-60;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.simpleProductTitle {
    color: $c-default-gray-100;
    transition: all $animation;

    @include fluid-prop-sm(height, 40px, 48px);
    @include ellipsis(2);

    &:hover {
        color: $c-default-gray-70;
    }
}

.simpleProductAvailabilityContainer {
    min-height: 36px;

    @media #{$screen-mobile} {
        min-height: auto;
    }
}

.simpleProductPriceLink {
    flex-shrink: 0;
}

.simpleProductAvailability {
    margin-top: auto;

    @include fluid-prop-sm(margin-bottom, 8px, 12px);
}

.simpleProductInfoContainer {
    align-self: flex-start;

    & .simpleProductAvailability {
        display: none;
    }
}

.simpleProductPriceContainer {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    margin-top: var(--p-xs-16);

    @media #{$screen-mobile} {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.simpleProductActionsContainer {
    align-items: center;
    display: grid;
    gap: 16px;
    grid-auto-flow: column;
    justify-content: flex-end;
    min-height: 40px;

    &.notAvailable {
        justify-content: stretch;
    }

    @media #{$screen-mobile} {
        align-self: stretch;
        justify-content: stretch;
    }

    .addToCartBtn {
        @media #{$screen-mobile} {
            height: 36px;
            width: 36px;
        }
    }
}

.listFavourite {
    display: none;

    &.displayed {
        display: block;
    }
}

.simpleProductListType {
    border-bottom: 1px solid $c-additional-gray-blue-30;
    display: flex;
    margin: 0;
    padding: 0 0 16px;
    width: 100%;

    & .simpleProductImage {
        flex-shrink: 0;
    }

    .simpleProductAvailabilityContainer {
        display: none;
    }

    & .simpleProductInfoContainer {
        width: 100%;

        @include fluid-prop-sm(margin-left, 12px, 20px);

        .simpleProductAvailability {
            display: flex;
            margin-top: 8px;

            @media #{$screen-mobile} {
                display: none;
            }
        }
    }

    & .simpleProductAvailability {
        display: none;
    }

    & .simpleProductPrice {
        margin-top: 0;
    }

    & .simpleProductArticle {
        margin-top: 0;
    }

    & .simpleProductPriceContainer {
        display: block;
        max-width: 211px;
        width: 100%;

        @media #{$screen-mobile} {
            margin-top: 4px;
            max-width: unset;

            @include fluid-prop-sm(margin-left, 12px, 20px);
        }
    }

    & .simpleProductFlexWrapper {
        column-gap: 20px;
        display: flex;
        flex-direction: row;
        width: 100%;

        @media #{$screen-mobile} {
            flex-wrap: wrap;
        }
    }
}

.simpleProductPreviewVisual {
    margin: 0;
    padding: 0;
    width: 282px;

    @media #{$screen-mobile} {
        width: 156px;
    }

    &:hover {
        box-shadow: none;
    }

    & .simpleProductImage {
        width: 282px;

        @media #{$screen-mobile} {
            width: 156px;
        }
    }
}

.simpleProductActions {
    margin-bottom: 1.5px;
}

.simpleProductBestSelling {
    width: 212px;

    @media #{$screen-mobile} {
        margin: 0;
        padding: 0;
        width: 156px;
    }

    & .simpleProductImage {
        height: 180px;
        width: 180px;

        @media #{$screen-mobile} {
            height: 156px;
            width: 156px;
        }
    }

    .simpleProductTitle {
        @media #{$screen-mobile} {
            width: 156px;
        }
    }

    .simpleProductAvailabilityContainer {
        display: none;
    }

    & .simpleProductAvailability {
        display: none;

        &:hover {
            color: $c-primary-60;
        }
    }

    & .simpleProductActions {
        button {
            height: 40px;
        }
    }
}

[data-skeleton-wrapper] {
    .simpleProductPreview {
        & .simpleProductImage {
            @media #{$screen-xl} {
                &[data-skeleton-item] {
                    width: 100%;
                }
            }
        }
    }
}

.simpleProductPreview {
    border: 1px solid $c-default-gray-10;
    border-radius: var(--r-md);
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 282px;

    @media #{$screen-desktop} {
        width: 282px;
    }

    @media #{$screen-xl} {
        width: 282px;
    }

    @media #{$screen-mobile} {
        width: 200px;
    }

    :global(.product-image-switcher-inner) {
        background-color: $c-default-gray-10;
    }

    & .simpleProductImage {
        @media #{$screen-xl} {
            height: 282px;
            margin: 0 auto;
            max-height: 282px;

            &[data-skeleton-item] {
                width: 100%;
            }
        }

        @media #{$screen-mobile} {
            height: 200px;
            width: 200px;
        }
    }

    & .simpleProductInfoContainer {
        margin: 0;

        .simpleProductAvailability {
            display: flex;
            margin-top: 8px;

            @media #{$screen-mobile} {
                display: none;
            }
        }
    }

    & .simpleProductPriceContainer {
        display: grid;
        grid-template-columns: 1fr min-content;
        margin-top: 0;
        max-width: 100%;

        @media #{$screen-mobile} {
            align-items: center;
            margin-left: 0;
        }
    }

    & .simpleProductFlexWrapper {
        background-color: $c-default-gray-10;
        flex-direction: column;
        height: 100%;
        padding: 8px var(--p-xs) var(--p-sm);
    }

    & .shieldList {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 4px;
        left: 8px;
        position: absolute;
        top: 8px;
    }
}
